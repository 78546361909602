import request, { AxiosResponse } from "axios";
import { apiBase } from "../../constants";

export const registerAccount = async ({
  idToken,
  email,
  timezone,
  source,
}: {
  idToken?: string;
  email?: string;
  timezone: string;
  source?: string;
}) => {
  try {
    const res: AxiosResponse<any> = await request({
      url: `${apiBase}/v1/accounts/`,
      method: "POST",
      data: {
        // send id token in case we are logging in with google
        idToken,
        email,
        source,
        timezone,
      },
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error. ";
    throw new Error(msg);
  }
};
export const setIntialPassword = async (password: string) => {
  try {
    const res: AxiosResponse<any> = await request({
      url: `${apiBase}/v1/accounts/initial-password`,
      method: "POST",
      data: {
        password,
      },
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error. ";
    throw new Error(msg);
  }
};
