import { ReactNode, useRef } from "react";
import * as React from "react";
import _ from "lodash";
import { observer } from "mobx-react";
import { Popover, PopoverProps } from "reactstrap";
import { ReactComponent as DropdownCaret } from "../../assets/img/nav/dropdown-caret.svg";
import classNames from "classnames";
import { Link } from "react-router-dom";

interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  options: {
    contents: ReactNode;
    to?: string;
    onClick?: (e?: React.MouseEvent<HTMLDivElement>) => void;
    href?: string;
  }[];
  showCaret?: boolean;
  className?: string;
  placement?: PopoverProps["placement"];
  dropdownRef?: HTMLDivElement;
}

/**
 * Dropdown used in app nav
 */
const AppNavDropdown = (props: Props) => {
  const targetRef = useRef<HTMLDivElement>(null);

  const dropdownItems = _.map(props.options, (option, index) => {
    if (option.onClick) {
      return (
        <div
          key={index}
          className="large-dropdown-item cursor-pointer"
          onClick={option.onClick}
        >
          {option.contents}
        </div>
      );
    }
    if (option.to) {
      return (
        <Link
          to={option.to}
          key={index}
          className="large-dropdown-item cursor-pointer"
        >
          {option.contents}
        </Link>
      );
    }
    if (option.href) {
      return (
        <a
          href={option.href}
          target="_blank"
          rel="noopener noreferrer"
          key={index}
          className="large-dropdown-item cursor-pointer"
        >
          {option.contents}
        </a>
      );
    }
    return (
      <div key={index} className="large-dropdown-item">
        {option.contents}
      </div>
    );
  });
  return (
    <div ref={targetRef}>
      <span className="cursor-pointer">
        {props.showCaret && (
          <div
            className="d-inline-block"
            onClick={() => props.setIsOpen(!props.isOpen)}
          >
            <DropdownCaret />
          </div>
        )}
        {(targetRef.current || props.dropdownRef) && (
          <Popover
            className={classNames("large-dropdown", props.className)}
            placement={props.placement || "bottom-end"}
            isOpen={props.isOpen}
            target={(props.dropdownRef || targetRef.current) as any}
            toggle={() => props.setIsOpen(false)}
            trigger="legacy"
            boundariesElement={"window"}
          >
            {dropdownItems}
          </Popover>
        )}
      </span>
    </div>
  );
};

export default observer(AppNavDropdown);
