import request, { AxiosResponse } from "axios";
import _ from "lodash";
import { apiBase } from "../../constants";

import { IFlow } from "../../components/Flows/FlowRoute";
import { ILink } from "../../components/FlowEditor/Link/Link";
import { unserializeSteps } from "../../domain/helpers/unserializeSteps";
import { IntegrationCreateUpdate } from "encharge-domain/lib/definitions/api/IntegrationCreateUpdate";
import { handleForbiddenRead } from "./handleForbidden";
import { ISegmentV2 } from "encharge-domain/definitions/ambient/segment";

export type FullFlow = Overwrite<
  IIntegration,
  { steps: (IIntegrationStep & { service: IService })[] }
> & {
  links: ILink[];
};
export const getFlows = async () => {
  try {
    const res: AxiosResponse<any> = await request({
      url: `${apiBase}/v1/integrations/`,
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    const integrations = res.data.integrations;
    // Unserialize all integrations' steps
    await Promise.all(
      _.map(integrations, async (flow) => {
        flow.steps = await unserializeSteps(flow.steps);
      })
    );
    return integrations;
  } catch (e) {
    if (handleForbiddenRead(e.response)) return [];
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve your flows. ";
    throw new Error(msg);
  }
};
export const getFlowsNames = async () => {
  try {
    const res: AxiosResponse<any> = await request({
      url: `${apiBase}/v1/integrations/names`,
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    const integrations = res.data.integrations;
    return integrations;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve your flows. ";
    throw new Error(msg);
  }
};

export const getFlow = async (flowId: IFlow["id"], readOnlyAuth?: string) => {
  // Load flow
  let res: AxiosResponse<any>;
  try {
    res = await request({
      url: `${apiBase}/v1/integrations/${flowId}`,
      method: "GET",
      params: {
        "read-only-auth": readOnlyAuth,
      },
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve your flow.";
    throw new Error(msg);
  }
  const jsonBody = res.data;
  const integration = jsonBody.integration as FullFlow;

  const steps = await unserializeSteps(integration.steps);

  const flow: IFlow = {
    id: integration.id,
    name: integration.name,
    status: integration.status,
    steps,
    links: integration.links,
    createdAt: integration.createdAt,
    updatedAt: integration.updatedAt,
    recipe: integration.recipe,
  };

  return [flow];
};

export const getFlowStepsPeopleCount = async (flowId: IFlow["id"]) => {
  // Load flow's steps' people's counts
  let res: AxiosResponse<{
    integration: {
      steps: {
        id: IIntegrationStep["id"];
        data: {
          peopleCounts: IIntegrationStep["data"]["peopleCounts"];
        };
      }[];
    };
  }>;
  try {
    res = await request({
      url: `${apiBase}/v1/integrations/${flowId}/people-counts`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve your step peole.";
    throw new Error(msg);
  }
  return res.data.integration;
};

export const createFlow = async (flow: IntegrationCreateUpdate) => {
  let res: AxiosResponse<{ integration: FullFlow }>;
  try {
    res = await request({
      url: `${apiBase}/v1/integrations/`,
      method: "POST",
      data: flow,
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't create your flow.";
    throw new Error(msg);
  }
  const newFlow = res.data.integration;
  const steps = await unserializeSteps(newFlow.steps);
  return _.assign(newFlow, { steps });
};

export const createFlowFromExisting = async ({
  existingFlowId,
  recipeId,
  readOnlyAuth,
  targetAccountId,
}: {
  existingFlowId: IIntegration["id"];
  recipeId?: IRecipe["id"];
  readOnlyAuth?: string;
  targetAccountId?: IAccount["id"];
}) => {
  let res: AxiosResponse<{ integration: FullFlow }>;
  try {
    const optionalParams = {} as any;
    if (readOnlyAuth) {
      optionalParams["read-only-auth"] = readOnlyAuth;
    }
    if (targetAccountId) {
      optionalParams["target-account"] = targetAccountId;
    }
    res = await request({
      url: `${apiBase}/v1/integrations`,
      params: {
        source: existingFlowId,
        recipe: recipeId,
        ...optionalParams,
      },
      method: "POST",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't create your flow.";
    throw new Error(msg);
  }
  const newFlow = res.data.integration;
  const steps = await unserializeSteps(newFlow.steps);
  return _.assign(newFlow, { steps });
};

export const updateFlow = async (flow: IFlow & { archived?: true }) => {
  let res: AxiosResponse<{ integration: IFlow }>;
  const integration: IntegrationCreateUpdate = {
    id: flow.id,
    name: flow.name,
    status: flow.status,
  };
  if (flow.archived) {
    integration.archived = flow.archived;
  }
  try {
    res = await request({
      url: `${apiBase}/v1/integrations/${integration.id}`,
      data: integration,
      method: "PATCH",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't update your flow.";
    throw new Error(msg);
  }
  return res.data.integration;
};

export const getShareToken = async (flowId: IFlow["id"]) => {
  try {
    const res: AxiosResponse<{ shareToken: string }> = await request({
      url: `${apiBase}/v1/integrations/${flowId}/share-token`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data?.shareToken;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "Couldn't prepare flow for sharing.";
    throw new Error(msg);
  }
};

export const getEmailsForReadOnlyFlow = async (
  flowId: IFlow["id"],
  readOnlyAuth: string
) => {
  try {
    const res: AxiosResponse<{ emails: IEmailContent[] }> = await request({
      url: `${apiBase}/v1/integrations/${flowId}/emails`,
      method: "GET",
      params: {
        "read-only-auth": readOnlyAuth,
      },
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.emails;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve emails. ";
    throw new Error(msg);
  }
};

export const getSegmentsForReadOnlyFlow = async (
  flowId: IFlow["id"],
  readOnlyAuth: string
) => {
  try {
    const res: AxiosResponse<{ segments: ISegmentV2[] }> = await request({
      url: `${apiBase}/v1/integrations/${flowId}/segments`,
      method: "GET",
      params: {
        "read-only-auth": readOnlyAuth,
      },
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.segments;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve segments. ";
    throw new Error(msg);
  }
};

export const getFlowAttachedResources = async (
  flowId: IFlow["id"],
  readOnlyAuth?: string
) => {
  try {
    const res: AxiosResponse<{
      email: number[];
      segment: number[];
      field: string[];
    }> = await request({
      url: `${apiBase}/v1/integrations/${flowId}/attached`,
      method: "GET",
      params: {
        "read-only-auth": readOnlyAuth,
      },
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve flow resources. ";
    throw new Error(msg);
  }
};
