import { observable, when } from "mobx";
import _ from "lodash";
import { DomainStore } from "./domainStore";
import { getQuery } from "domain/helpers/query";
import {
  TeamMemberScopes,
  TeamMemberScopeTypes,
} from "encharge-domain/definitions/TeamMember";

export class PermissionsStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;

    const query = getQuery();
    if (isSharedFlow()) {
      this.readOnlyAuthToken = query["flow-read-auth"];
      this.readOnlyFlow = true;
    }
  }

  @observable
  readOnlyFlow: boolean = false;

  @observable
  readOnlyAuthToken: undefined | string;

  @observable
  hasLoadedScopes() {
    return Boolean(this.rootStore.accountStore.account);
  }

  // Triggers a passed function when the scopes has loaded
  async onLoadedScopes(func: () => void) {
    await when(() => this.hasLoadedScopes());
    func();
  }

  @observable
  checkScope(scope: TeamMemberScopeTypes) {
    return this.rootStore.accountStore.checkScope(scope);
  }
}

export const isSharedFlow = () => {
  return Boolean(getQuery()?.["flow-read-auth"]);
};

type TeamMemberScopesDisplay = TeamMemberScopeDisplay[];
export type TeamMemberScopeDisplay = {
  id: TeamMemberScopeTypes;
  name: string;
  description?: string;
  required?: boolean;
  hidden?: boolean;
  dependsOn?: TeamMemberScopeTypes[];
  section?: string;
  order?: number;
};

export const teamMembersScopesDisplay: TeamMemberScopesDisplay = [
  {
    id: "account:write",
    name: "Change Account Data",
    description: "Team member can change account settings.",
    section: "Account",
    order: 10,
  },
  // {
  //   id: "apiKeys:read",
  //   name: "View API Keys",
  //   hidden: true,
  //   description:
  //     "Team member can view API keys that allow access to various Encharge APIs.",
  //   section: "Account",
  //   order: 20,
  // },
  {
    id: "apps:write",
    name: "Connect Apps",
    description:
      "Team member can connect new apps and change settings on already connected apps.",
    section: "Account",
    order: 30,
  },
  {
    id: "team:read",
    name: "View Team",
    description:
      "Team member can view other team members and their details (including permissions).",
    order: 40,
    section: "Account",
  },
  // {
  //   id: "team:write",
  //   enabled: false,
  //   name: "Manage Team",
  //   description:
  //     "Team member can create, modify and delete other team members.",
  //   hidden: true,
  //   dependsOn: ["team:read"],
  //   order: 50,
  //   section: "Account",
  // },
  {
    id: "billing:read",
    name: "View Billing",
    description: "Team member can see billing details.",
    section: "Billing",
    order: 10,
  },
  {
    id: "billing:write",
    name: "Change Billing",
    description: "Team member can upgrade, downgrade and cancel account.",
    dependsOn: ["billing:read"],
    section: "Billing",
    order: 20,
  },
  {
    id: "emails:read",
    name: "View Emails",
    description: "Team member can view existing emails.",
    order: 10,
    section: "Emails",
  },
  {
    id: "emails:write",
    name: "Change Emails",
    description: "Team member can create new emails and edit existing ones.",
    dependsOn: ["emails:read"],
    order: 20,
    section: "Emails",
  },
  {
    id: "broadcasts:read",
    name: "View Broadcasts",
    description: "Team member can view existing broadcasts.",
    dependsOn: ["emails:read"],
    order: 30,
    section: "Emails",
  },
  {
    id: "broadcasts:write",
    name: "Change Broadcasts",
    description:
      "Team member can send new broadcasts, and modify/cancel existing ones.",
    dependsOn: ["broadcasts:read", "emails:read"],
    order: 40,
    section: "Emails",
  },
  {
    id: "emailMetrics:read",
    name: "View Metrics",
    description: "Team member can view email engagement and other metrics.",
    order: 50,
    section: "Emails",
  },
  {
    id: "emailSettings:write",
    name: "Change Email Settings",
    description:
      "Team member can verify emails/domains for sending, and change other email settings.",
    order: 60,
    section: "Emails",
  },
  {
    id: "flows:read",
    name: "View Flows",
    description: "Team member can view existing flows.",
    order: 10,
    section: "Flows",
    dependsOn: ["emails:read"],
  },
  {
    id: "flows:write",
    name: "Change Flows",
    description: "Team member can create new flows, and modify existing ones.",
    dependsOn: ["flows:read", "emails:read"],
    order: 20,
    section: "Flows",
  },
  {
    id: "people:read",
    name: "View People",
    description: "Team member can see people in account.",
    order: 10,
    section: "People",
  },
  {
    id: "people:write",
    name: "Change People",
    description: "Team member can create, modify and delete people.",
    dependsOn: ["people:read"],
    order: 20,
    section: "People",
  },
  {
    id: "people:export",
    name: "Export People",
    description: "Team member can export a list of people in the account.",
    dependsOn: ["people:read"],
    order: 30,
    section: "People",
  },
  {
    id: "personFields:write",
    name: "Change Custom Fields",
    description: "Team member can create, modify and delete Custom Fields.",
    order: 40,
    section: "People",
  },
];

export const defaultScopes: TeamMemberScopes = [
  {
    id: "account:write",
    enabled: false,
  },
  // {
  //   id: "apiKeys:read",
  //   enabled: false,
  // },
  {
    id: "apps:write",
    enabled: true,
  },
  {
    id: "team:read",
    enabled: true,
  },
  // {
  //   id: "team:write",
  //   enabled: false,
  //   name: "Manage Team",
  //   description:
  //     "Team member can create, modify and delete other team members.",
  //   hidden: true,
  //   dependsOn: ["team:read"],
  //   order: 50,
  //   section: "Account",
  // },
  {
    id: "billing:read",
    enabled: false,
  },
  {
    id: "billing:write",
    enabled: false,
  },
  {
    id: "emails:read",
    enabled: true,
  },
  {
    id: "emails:write",
    enabled: true,
  },
  {
    id: "emailMetrics:read",
    enabled: true,
  },
  {
    id: "emailSettings:write",
    enabled: true,
  },
  {
    id: "flows:read",
    enabled: true,
  },
  {
    id: "flows:write",
    enabled: true,
  },
  {
    id: "broadcasts:read",
    enabled: true,
  },
  {
    id: "broadcasts:write",
    enabled: true,
  },
  {
    id: "people:read",
    enabled: true,
  },
  {
    id: "people:write",
    enabled: true,
  },
  {
    id: "people:export",
    enabled: true,
  },
  {
    id: "personFields:write",
    enabled: true,
  },
];
