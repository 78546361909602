import { toastError } from "domain/errorHandling/toaster";
import { getUserTimezone } from "domain/helpers/asDateTime";
import { segmentIdentify, segmentTrack } from "domain/helpers/segment.com";
import _ from "lodash";
import { action, runInAction } from "mobx";
import { DomainStore } from "store/domainStore";
import { updateAccount } from "store/persistence/persistAccount";
import {
  registerAccount,
  setIntialPassword,
} from "store/persistence/persistRegistration";

export type RegistrationData = Partial<{
  name: string;
  password: string;

  phone: string;
  site: string;
  industry: string;
  estimatedContacts: string;
  goals: string;
  previousTool: string;
}>;

export class RegistrationStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
  }

  @action
  async register({
    email,
    idToken,
    source,
  }: {
    email?: string;
    idToken?: string;
    source?: string;
  }) {
    const ui = this.rootStore.uiStore.accountRegister;
    try {
      if (!email && !idToken) {
        throw new Error("Missing registration email.");
      }
      ui.startLoading();
      const accountInfo = await registerAccount({
        idToken,
        email,
        source,
        timezone: getUserTimezone(),
      });
      this.rootStore.accountStore.populateAccountData(accountInfo);
      this.trackRegistrationAction({
        traits: { email: this.rootStore.accountStore.account?.email },
        eventName: "Account Registration Set Email",
      });
      ui.finishLoading();
    } catch (e) {
      toastError({
        message: "Error while loading templates.",
        extra: e,
      });
      ui.finishLoading(e.message);
    }
  }

  @action
  trackRegistrationAction({
    traits,
    eventName,
  }: {
    traits?: Dictionary<any>;
    eventName?: string;
  }) {
    const teamMemberId = this.rootStore.accountStore.account?.teamMemberId;
    const accountId = this.rootStore.accountStore.account?.accountId;
    if (traits) {
      segmentIdentify({ accountId, teamMemberId, traits });
    }
    if (eventName) {
      segmentTrack({ name: eventName });
    }
  }

  @action
  async saveAccountRegistrationData(accountData: RegistrationData) {
    this.rootStore.uiStore.accountEdit.startLoading();
    try {
      if (accountData.name) {
        this.trackRegistrationAction({
          traits: { name: accountData.name },
          eventName: "Account Registration Set Name",
        });
        await this.rootStore.accountStore.setTeamMemberName(accountData.name);
      }
      if (accountData.password) {
        this.trackRegistrationAction({
          eventName: "Account Registration Set Password",
        });
        await this.rootStore.accountStore.setAccountFlag(
          "hasSetInitialPassword",
          true
        );
        await setIntialPassword(accountData.password);
      } else if (accountData.phone) {
        this.trackRegistrationAction({
          traits: { phone: accountData.phone },
          eventName: "Account Registration Set Phone",
        });
        await this.rootStore.accountStore.setPhone(accountData.phone);
      } else {
        if (accountData.goals) {
          this.trackRegistrationAction({
            traits: {
              goals: accountData.goals,
              previousTool: accountData.previousTool,
            },
            eventName: "Account Registration Set Goals",
          });
          await this.rootStore.accountStore.setAccountFlag(
            "hasFilledGoals",
            true
          );
        }
        if (accountData.site) {
          this.trackRegistrationAction({
            traits: {
              site: accountData.site,
              estimatedContacts: accountData.estimatedContacts,
              industry: accountData.industry,
            },
            eventName: "Account Registration Set Company Info",
          });
          runInAction(() => {
            if (this.rootStore.accountStore.account) {
              this.rootStore.accountStore.account.site = accountData.site;
            }
          });
        }
        await updateAccount({
          data: _.omit(accountData, "password", "name"),
        });
      }
    } catch {
      toastError("Couldn't save your account.");
    } finally {
      this.rootStore.uiStore.accountEdit.finishLoading();
    }
  }
}
