export const loadChargebee = (onload: () => void) => {
  if (document.getElementById("chargebee-script")) {
    // script is loaded so trigger onload
    onload();
    // but dont load script again
    return;
  }
  const script = document.createElement("script");
  script.id = "chargebee-script";
  script.src = "https://js.chargebee.com/v2/chargebee.js";
  script.async = true;
  const siteName =
    process.env.REACT_APP_ENCHARGE_NODE_ENV === "production"
      ? "encharge"
      : "encharge-test";
  script["data-cb-site"] = siteName;
  script["data-cb-gtm-enabled"] = "true";
  // open customer portal if script is loaded
  script.onload = () => {
    // init charge bee instance
    (window as any).Chargebee.init({ site: siteName, enableGTMTracking: true });
    // proceed with checkout or portal
    onload();
  };
  document.body.appendChild(script);
};
