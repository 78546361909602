import { useCallback, useState } from "react";
import { Form, Input } from "reactstrap";

import { getQuery } from "domain/helpers/query";
import ButtonLoader from "components/ButtonLoader";
import { observer } from "mobx-react";
import GoogleRegistrationWidget from "components/Registration/GoogleRegistrationWidget";
import { useStores } from "store/useStore";
import { Link } from "react-router-dom";
import RegisterError from "components/Registration/RegisterError";

interface Props {
  submit: () => void;
}

const RegistrationStepEmail = (props: Props) => {
  const {
    registrationStore,
    uiStore: { accountRegister: ui },
  } = useStores();

  const [formData, setFormData] = useState({ email: "" });

  const source = getQuery().ref;

  const handleFieldChange = (e: React.FormEvent) => {
    /// @ts-ignore - e.target.name is not defined on state
    setFormData({ ...formData, [e.target.name]: e?.target?.value || "" });
  };
  const onSubmit = useCallback(
    (e) => {
      registrationStore.register({ email: formData.email, source }).then(() => {
        props.submit();
      });
      e.preventDefault();
      return false;
    },
    [registrationStore, formData.email, source, props]
  );

  const heading = "Start your 14-day free trial";
  const subheading = "All features included. No credit card required.";
  const subsubheading = (
    <>
      Already have an account? <Link to="/login">Login</Link>
    </>
  );

  const queryError = getQuery().error;
  const error = ui.error || queryError;

  return (
    <div className="max-width-500 ">
      <Form onSubmit={onSubmit}>
        <h1 className="h2 font-weight-bold">{heading}</h1>
        <div className="text-muted h5 mt-3 mb-5">
          {subheading}
          {subsubheading && (
            <>
              <br />
              <div className="additional-cta-section mt-2">{subsubheading}</div>
            </>
          )}
        </div>
        <div className="mt-4 mb-3">
          <GoogleRegistrationWidget source={source} />
        </div>
        <div>or</div>

        <Input
          value={formData.email}
          type="email"
          placeholder="Your Email"
          autoComplete="email"
          name="email"
          className="form-control-lg mb-3 mt-2"
          required={true}
          onChange={handleFieldChange}
        />

        <ButtonLoader
          className="mt-5"
          color="primary"
          size="lg"
          block={true}
          loading={ui.isLoading}
          type="submit"
        >
          Convert More Leads
        </ButtonLoader>
        {error && <RegisterError error={error} />}
      </Form>
    </div>
  );
};

export default observer(RegistrationStepEmail);
