import { observable, action, runInAction, when } from "mobx";
import { DomainStore } from "./domainStore";
import { toastError, toastSuccess } from "../domain/errorHandling/toaster";
import { lazyObservable, ILazyObservable } from "../domain/helpers/lazyLoad";
import _ from "lodash";
import {
  cancelBroadcast,
  createBroadcast,
  deleteBroadcast,
  getBroadcasts,
  sendBroadcast,
  updateBroadcast,
  checkBroadcastsStatus,
} from "./persistence/persistBroadcasts";
import { APIQueueFactory } from "domain/apiQueue";

import humanizeString from "encharge-domain/lib/helpers/humanizeString";
import { getDomainFromEmail } from "encharge-domain/lib/helpers/email_helper";
import { UnreachableCaseError } from "encharge-domain/definitions/ambient/ts-essentials";

export class BroadcastsStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
  }

  queue = APIQueueFactory({ name: "broadcasts", limit: 1 });

  @observable
  broadcasts: ILazyObservable<Broadcast[]> = lazyObservable<Broadcast[]>(
    (sink, onError) => {
      getBroadcasts()
        .then((broadcasts) => {
          sink(observable(broadcasts));

          this.checkBroadcastsStatus();
        })
        .catch((e) => {
          toastError({
            message: "Error while loading broadcasts.",
            extra: e,
          });
          onError(e);
          throw e;
        });
    }
  );

  @action
  async create(name?: string, open = true) {
    try {
      this.rootStore.uiStore.broadcastEdit.setIsUpdating(true);
      const broadcast = await createBroadcast(name || "My Broadcast");

      runInAction(() =>
        this.broadcasts.current().splice(0, 0, observable(broadcast))
      );
      if (open) {
        this.open(broadcast.id);
      }
      // Add to current folder
      const currentFolderId = this.rootStore.foldersStore.getSelectedFolder(
        "broadcasts"
      );
      if (currentFolderId) {
        this.rootStore.foldersStore.pushItemToFolder({
          folderId: currentFolderId,
          itemId: broadcast.id,
          type: "broadcasts",
        });
      }
      return broadcast;
    } catch (e) {
      toastError({
        message: "Error while creating broadcast.",
        extra: e,
      });
    } finally {
      this.rootStore.uiStore.broadcastEdit.setIsUpdating(false);
    }
    return;
  }

  @action
  async update(broadcast: Broadcast & { id: Broadcast["id"] }): Promise<any> {
    if (!broadcast.id) return;
    this.queue.addConfirmableAction({
      state: () => this.broadcasts.current(),
      performAction: () => {
        const broadcasts = this.broadcasts.current();

        // add this broadcast to the root folder
        const currentIndex = _.findIndex(
          broadcasts,
          (v) => v.id === broadcast.id
        );

        if (currentIndex === -1) return;
        if (_.isEqual(broadcasts[currentIndex], broadcast)) {
          return;
        }
        _.assign(broadcasts[currentIndex], broadcast);

        return async () => {
          this.rootStore.uiStore.broadcastEdit.setIsUpdating(true);
          try {
            await updateBroadcast(broadcasts[currentIndex]);
          } finally {
            this.rootStore.uiStore.broadcastEdit.setIsUpdating(false);
          }
        };
      },
      confirmErrorMessage: "Couldn't save broadcast.",
    });
  }

  get(id: Broadcast["id"]) {
    const broadcasts = this.broadcasts.current();
    if (!broadcasts) return;
    return _.find(broadcasts, (broadcast) => broadcast.id === id) as
      | Broadcast
      | undefined;
  }

  @action
  archive(id: Broadcast["id"]) {
    this.queue.addConfirmableAction({
      state: () => this.broadcasts.current(),
      performAction: () => {
        const broadcasts = this.broadcasts.current();
        const index = _.findIndex(broadcasts, (item) => item.id === id);
        if (index === -1) return;
        broadcasts.splice(index, 1);

        return async () => deleteBroadcast(id);
      },
      confirmErrorMessage: "Couldn't archive broadcast.",
    });
  }
  async send(id: Broadcast["id"]) {
    // Make sure the time is saved
    // if user didnt click save next to time picker, save it from here
    const broadcast = this.get(id);
    if (broadcast && this.rootStore.uiStore.broadcastEdit.timeChanged) {
      await this.update({
        ...broadcast,
        sendAt: this.rootStore.uiStore.broadcastEdit.timeChanged,
      });
      this.rootStore.uiStore.broadcastEdit.timeHasNotChanged();
    }

    this.queue.addConfirmableAction({
      state: () => this.broadcasts.current(),
      performAction: () => {
        return async () => {
          try {
            this.rootStore.uiStore.broadcastEdit.setIsUpdating(true);
            const broadcast = this.get(id);
            if (broadcast) {
              await this.canStartBroadcast(broadcast);
            }
            const updated = await sendBroadcast(id);
            // update broadcast in store
            const broadcasts = this.broadcasts.current();
            const currentIndex = _.findIndex(
              broadcasts,
              (item) => item.id === id
            );
            if (broadcasts[currentIndex]) {
              runInAction(() => _.assign(broadcasts[currentIndex], updated));
            }
            toastSuccess(
              `🚀 Awesome! Broadcast is ${
                updated.status === "sending" ? "sending" : "scheduled"
              }.`
            );
          } finally {
            this.rootStore.uiStore.broadcastEdit.setIsUpdating(false);
          }
        };
      },
      confirmErrorMessage: "Couldn't send broadcast.",
    });
  }

  cancel(id: Broadcast["id"]) {
    this.queue.addConfirmableAction({
      state: () => this.broadcasts.current(),
      performAction: () => {
        return async () => {
          this.rootStore.uiStore.broadcastEdit.setIsUpdating(true);
          try {
            const updated = await cancelBroadcast(id);

            // update broadcast in store
            const broadcasts = this.broadcasts.current();
            const currentIndex = _.findIndex(
              broadcasts,
              (item) => item.id === id
            );
            if (broadcasts[currentIndex]) {
              runInAction(() => _.assign(broadcasts[currentIndex], updated));
            }
            toastSuccess(`✅ Broadcast has been canceled.`);
          } finally {
            this.rootStore.uiStore.broadcastEdit.setIsUpdating(false);
          }
        };
      },
      confirmErrorMessage: "Couldn't cancel broadcast.",
    });
  }

  @action
  async duplicate(id: Broadcast["id"]) {
    const source = this.get(id);
    if (!source) {
      return;
    }

    const emptyBroadcast = await this.create(source.name, false);
    if (!emptyBroadcast) return;
    // const emptyBroadcast = await createBroadcast(source.name);
    // runInAction(() =>
    //   this.broadcasts.current().splice(0, 0, observable(emptyBroadcast))
    // );
    const copy = _.cloneDeep(
      _.omit(source, [
        "id",
        "createdBy",
        "createdAt",
        "updatedAt",
        "integrationId",
        "sendAt",
        "time",
      ])
    );
    await this.update({
      ...emptyBroadcast,
      ...copy,
      id: emptyBroadcast.id,
      status: "draft",
    });
    this.open(emptyBroadcast.id);
  }

  open(id: Broadcast["id"]) {
    this.rootStore.uiStore.broadcastEdit.open(id);
  }

  // Check if broadcasts which can change, have changed
  async checkBroadcastsStatus() {
    _.map(this.broadcasts.current(), (broadcast) => {
      if (
        [
          "ab-test-sending-winner",
          "ab-testing",
          "scheduled",
          "sending",
        ].includes(broadcast.status)
      ) {
        this.updateBroadcastStatus(broadcast.id);
      }
    });
  }

  // Update status of single broadcast
  async updateBroadcastStatus(id: Broadcast["id"]) {
    const result = await checkBroadcastsStatus(id);
    if (!result) return;
    // update broadcast status
    const broadcast = _.find(
      this.broadcasts.current(),
      (item) => item.id === result.id
    );
    if (broadcast && broadcast?.status !== result.status) {
      runInAction(() => (broadcast.status = result.status));
    }
  }

  async canStartBroadcast(broadcast: Broadcast): Promise<void> {
    if (!broadcast.emailId) {
      throw new Error("No email selected.");
    }
    // Get verified emails/domains
    _.noop(this.rootStore.emailsStore.emails);
    const promises = [
      when(() => !this.rootStore.emailSettingsStore.domains.isLoading()),
      when(() => !this.rootStore.emailSettingsStore.emails.isLoading()),
      when(() => !this.rootStore.emailsStore.loadingEmails),
    ];
    await Promise.all(promises);

    const emailDomains = this.rootStore.emailSettingsStore.domains.current();
    const validatedEmailAddresses = this.rootStore.emailSettingsStore.emails.current();
    // current email
    const email = this.rootStore.emailsStore.getEmailById(broadcast.emailId);

    const hasVerifiedEmail = _.reduce(
      validatedEmailAddresses,
      (acc, current) => {
        if (current.status === "verified" && current.email === email?.fromEmail)
          return true;
        return acc;
      },
      false
    );
    const hasVerifiedDomain = _.reduce(
      emailDomains,
      (acc, domain) => {
        if (
          domain.status === "verified" &&
          domain.domain === getDomainFromEmail(email?.fromEmail)
        )
          return true;
        return acc;
      },
      false
    );

    if (!hasVerifiedDomain && !hasVerifiedEmail) {
      throw new Error(
        `Address ${email?.fromEmail} has not been verified for sending email.`
      );
    }
  }
}

export const canDeleteBroadcast = (broadcast: Broadcast) => {
  return broadcast.status !== "sending";
};

export const canEditBroadcast = (broadcast: Broadcast) => {
  return broadcast.status === "draft" || broadcast.status === "scheduled";
};

export const canCancelBroadcast = (broadcast: Broadcast) => {
  return (
    broadcast.status === "ab-test-sending-winner" ||
    broadcast.status === "ab-testing" ||
    broadcast.status === "sending" ||
    broadcast.status === "scheduled"
  );
};

export const formatBroadcastStatus = (status: Broadcast["status"]) => {
  switch (status) {
    case "sent":
    case "draft":
    case "scheduled":
    case "sending":
    case "canceled":
    case "failed":
      return humanizeString(status);
    case "ab-testing":
      return "A/B Testing";
    case "ab-test-sending-winner":
      return "Sending A/B test winner";
    default:
      throw new UnreachableCaseError(status);
  }
};
export const getBroadcastStatusBadgeColor = (status: Broadcast["status"]) => {
  switch (status) {
    case "sent":
      return "success";
    case "ab-testing":
    case "ab-test-sending-winner":
    case "sending":
      return "primary";
    case "draft":
      return "light";
    case "scheduled":
      return "warning";
    case "canceled":
      return "secondary";
    case "failed":
      return "danger";
    default:
      throw new UnreachableCaseError(status);
  }
};
