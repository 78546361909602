export const apiBase =
  process.env.REACT_APP_API_URL || "https://api.encharge.io";

export const appBase =
  process.env.REACT_APP_APP_URL || "https://app.encharge.io";

export const peoplePageSize = 100;

export const segmentConditionEmailStatusValue =
  "x-encharge-reserved-email-status";
export const segmentConditionTagValue = "x-encharge-reserved-tag";
export const segmentConditionTypeformSubmissionValue =
  "x-encharge-reserved-typeform-submission";
export const segmentConditionGenericEventValue = "x-encharge-reserved-event";

export const toastMessageDuration = 3000;

export const peopleEventsPageSize = 100;

export const segmentPreviewMaxPeople = 20;

export const testTeamMemberIds = [34, 262];
