import { observable } from "mobx";
import _ from "lodash";
import { DomainStore } from "./domainStore";
import { toastError } from "../domain/errorHandling/toaster";
import { lazyObservable } from "../domain/helpers/lazyLoad";
import { getServices } from "./persistence/persistServices";

export class ServicesStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
  }
  @observable
  services = lazyObservable<IService[]>((sink, onError) => {
    getServices()
      .then((items) => {
        sink(observable(items));
      })
      .catch((e) => {
        toastError({
          message: "Error while loading services.",
          extra: e,
        });
        onError(e);
      });
  });

  getById(serviceId: string) {
    const services = this.services.current();
    if (!services) {
      return undefined;
    }
    return _.find(services, (service) => service.id === serviceId);
  }
}
