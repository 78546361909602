import UIStore from "store/uiStore";
import { AuthStore } from "store/AuthStore";
import { AccountStore } from "store/accountStore";
import { FlowsStore } from "store/flowsStore";
import { SegmentStore } from "store/segmentStore";
import { PeopleStore } from "store/peopleStore";
import { EmailsStore } from "store/emailsStore";
import { CreatePeopleImportStore } from "store/createPeopleImportStore";
import { PeopleImportsStore } from "store/peopleImportsStore";
import { LocationStore } from "store/locationStore";
import { RecipeStore } from "store/recipeStore";
import { ServicesStore } from "store/servicesStore";
import { EmailSettingsStore } from "store/emailSettingsStore";
import { PeopleActionStore } from "store/peopleActionStore";
import { EventsStore } from "store/eventsStore";
import { FormTrackingStore } from "store/formTrackingStore";
import { FoldersStore } from "./foldersStore";
import { PersonFieldsStore } from "./personFieldsStore";
import { PermissionsStore } from "./permissionsStore";
import { PeopleSearchStore } from "./peopleSearchStore";
import { BroadcastsStore } from "./broadcastsStore";
import { TeamMembersStore } from "./teamMembersStore";
import { TagsStore } from "./tagsStore";
import { CommunicationPreferencesStore } from "store/communicationPreferencesStore";
import { RegistrationStore } from "store/registrationStore";
import { AIWriterStore } from "store/aiWriterStore";

export class DomainStore {
  locationStore = new LocationStore(this);
  permissionsStore = new PermissionsStore(this);
  authStore = new AuthStore(this);
  uiStore = new UIStore(this);
  flowsStore = new FlowsStore(this);
  accountStore = new AccountStore(this);
  segmentStore = new SegmentStore(this);
  peopleStore = new PeopleStore(this);
  emailsStore = new EmailsStore(this);
  createPeopleImportStore = new CreatePeopleImportStore(this);
  peopleImportsStore = new PeopleImportsStore(this);
  recipeStore = new RecipeStore(this);
  servicesStore = new ServicesStore(this);
  emailSettingsStore = new EmailSettingsStore(this);
  peopleActionStore = new PeopleActionStore(this);
  eventsStore = new EventsStore(this);
  formTrackingStore = new FormTrackingStore(this);
  peopleSearchStore = new PeopleSearchStore(this);
  foldersStore = new FoldersStore(this);
  personFieldsStore = new PersonFieldsStore(this);
  broadcastsStore = new BroadcastsStore(this);
  teamMembersStore = new TeamMembersStore(this);
  tagsStore = new TagsStore(this);
  communicationPreferencesStore = new CommunicationPreferencesStore(this);
  registrationStore = new RegistrationStore(this);
  aiWriterStore = new AIWriterStore(this);
}

const store = new DomainStore();
// Keep a reference to the stores to be accessed by JS helpers, etc.
/// @ts-ignore - store is not defined on window
window.store = store;
export const domainStore = store;
