import { observable, runInAction, action } from "mobx";
import _ from "lodash";
import { DomainStore } from "./domainStore";

import { toastError } from "../domain/errorHandling/toaster";
import { createImport } from "./persistence/persistImport";
import { IFieldsSchema } from "encharge-domain/definitions/JSONSchema6";
import { formatTag } from "./tagsStore";

export class CreatePeopleImportStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
  }

  @observable
  name?: string;

  @observable
  people?: Dictionary<any>[];

  peopleCSV?: string;

  @observable
  headers?: string[];

  @observable
  tags: string[] = [];

  @observable
  fieldMapping?: IFieldsSchema;

  @observable
  existingPeopleStrategy: "update" | "ignore" = "update";

  @action
  resetAll() {
    this.name = undefined;
    this.people = undefined;
    this.peopleCSV = undefined;
    this.headers = undefined;
    this.tags = [];
    this.fieldMapping = undefined;
  }

  @action
  resetPeopleAndHeaders() {
    this.people = undefined;
    this.headers = undefined;
    this.peopleCSV = undefined;
  }

  @action
  setPeopleAndHeaders(
    people: Dictionary<any>[],
    headers: string[],
    peopleCSV: string
  ) {
    this.people = people;
    this.headers = headers;
    this.peopleCSV = peopleCSV;
  }

  @action
  setName(name: string) {
    this.name = name;
  }

  @action
  setFieldMapping(fieldMapping?: IFieldsSchema) {
    this.fieldMapping = fieldMapping;
  }

  @action
  setTags(tags: string[]) {
    this.tags = tags;
    // Add the tags to the account
    this.rootStore.tagsStore.addTagsLocal(
      // Map to tag objects
      _.map(tags, (tag) => ({ tag: formatTag(tag) }))
    );
  }

  @action
  setExistingPeopleStrategy(strategy: string) {
    if (strategy === "update") {
      this.existingPeopleStrategy = "update";
    }
    if (strategy === "ignore") {
      this.existingPeopleStrategy = "ignore";
    }
  }

  @observable
  isSavingImport: boolean = false;

  @action
  async saveImport() {
    if (!this.people || !this.peopleCSV) {
      toastError("No people to import.");
      return false;
    }
    if (!this.fieldMapping) {
      toastError("Can't import without mapped fields.");
      return false;
    }
    try {
      this.isSavingImport = true;
      await createImport({
        name: this.name || "",
        peopleCSV: this.peopleCSV,
        numPeople: this.people.length,
        fieldMapping: this.fieldMapping,
        tags: this.tags,
        existingPeopleStrategy: this.existingPeopleStrategy,
      });
      this.rootStore.peopleImportsStore.imports.refresh();
      this.resetAll();
      return true;
    } catch (e) {
      toastError({
        message: "Error while importing.",
        extra: e,
      });
    } finally {
      runInAction(() => {
        this.isSavingImport = false;
      });
    }
    return false;
  }
}
