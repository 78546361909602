import { log } from "./logToBackend";
import { toastError } from "./toaster";
import { errorToObject } from "encharge-domain/lib/helpers/error";

window.addEventListener("error", (event) => {
  if (!event.filename) {
    // Error in external script that we don't have control over
    return;
  }
  toastError(
    "An error occured 😓. Please try refreshing this page. Contact support if it persists.",
    {
      toastId: "top-level-error-caught",
    }
  );
  if (process.env.NODE_ENV === "production") {
    log({
      message: "Frontend Error",
      extra: {
        error: errorToObject(event.error),
        message: event.message,
        lineno: event.lineno,
        colno: event.colno,
        filename: event.filename,
      },
    });
  }
});

window.addEventListener("unhandledrejection", (event) => {
  toastError(
    "An error occured 😓. Please try refreshing this page. Contact support if it persists.",
    {
      toastId: "top-level-error-caught",
    }
  );
  if (process.env.NODE_ENV === "production") {
    log({ message: "Frontend Unhandled Rejection", extra: event?.reason });
  }
});
