import { useState } from "react";
import AppNavDropdown from "./AppNavDropdown";
import AppNavIcon from "./AppNavIcon";
import { ReactComponent as ImportPeopleIcon } from "../../assets/img/nav/import-people.svg";
import { ReactComponent as CustomFieldsIcon } from "../../assets/img/nav/custom-fields.svg";
import { ReactComponent as TagsIcon } from "../../assets/img/nav/tags.svg";

/**
 * Dropdown for app navigation
 */
const AppNavManage = () => {
  const [isOpen, setIsOpen] = useState(false);
  // return

  // </span>;
  return (
    <div
      className="sidebar-nav-item cursor-pointer mr-3"
      onClick={() => setIsOpen(!isOpen)}
    >
      <span>
        <span className="main-bar-nav-item">
          Manage
          <div className="ml-2 caret" />
        </span>
        <AppNavDropdown
          className="mt-0 main-bar-nav-item-manage"
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          placement={"bottom-start"}
          options={[
            // {
            //   contents: (
            //     <>
            //       <ImportPeopleIcon className="nav-icon" />
            //       Import People
            //     </>
            //   ),
            //   to: "/people/imports",
            // },
            {
              contents: (
                <>
                  <TagsIcon className="nav-icon" />
                  Tags
                </>
              ),
              to: "/settings/tags",
            },
            {
              contents: (
                <>
                  <CustomFieldsIcon className="nav-icon" />
                  Custom Fields
                </>
              ),
              to: "/settings/person-fields",
            },
          ]}
        />
      </span>
    </div>
  );
};
export default AppNavManage;
