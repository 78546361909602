import request, { AxiosResponse } from "axios";
import _ from "lodash";
import { apiBase } from "../../constants";

import { ISegment } from "encharge-domain/lib/definitions/ambient/segment";

export const getPerson = async (id: string) => {
  try {
    const res: AxiosResponse<{ users: IEndUser[] }> = await request({
      url: `${apiBase}/v1/people/${id}`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res?.data?.users[0];
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error. ";
    throw new Error(msg);
  }
};

export const updatePerson = async (person: IEndUser) => {
  try {
    const res: AxiosResponse<{ user: IEndUser }> = await request({
      url: `${apiBase}/v1/people/?source=manual`,
      method: "POST",
      data: person,
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.user;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error.";
    throw new Error(msg);
  }
};

export const addTag = async (tag: string, userId: string) => {
  try {
    await request({
      url: `${apiBase}/v1/tags/`,
      method: "POST",
      data: {
        tag,
        id: userId,
      },
      withCredentials: true,
    });
    return { success: true };
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error.";
    throw new Error(msg);
  }
};
export const removeTag = async (tag: string, userId: string) => {
  try {
    await request({
      url: `${apiBase}/v1/tags/`,
      method: "DELETE",
      data: {
        tag,
        id: userId,
      },
      withCredentials: true,
    });
    return { success: true };
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error.";
    throw new Error(msg);
  }
};

export const tagPeople = async ({
  peopleIds,
  segmentId,
  tag,
}: {
  peopleIds?: string[];
  segmentId?: ISegment["id"];
  tag: string;
}) => {
  try {
    const data = { tag } as any;
    if (peopleIds) {
      data.people = _.map(peopleIds, (personId) => ({ id: personId }));
    } else if (segmentId) {
      data.segmentId = segmentId;
    } else {
      throw new Error("Can't tag people: no people or segment selected.");
    }
    await request({
      url: `${apiBase}/v1/tags/bulk`,
      method: "POST",
      data,
      withCredentials: true,
    });
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error.";
    throw new Error(msg);
  }
};
export const exportPeople = async ({
  peopleIds,
  segmentId,
  notifyEmail,
}: {
  peopleIds?: string[];
  segmentId?: ISegment["id"];
  notifyEmail: string;
}) => {
  try {
    const data = { notifyEmail } as any;
    if (peopleIds) {
      data.people = _.map(peopleIds, (personId) => ({ id: personId }));
    } else if (segmentId) {
      data.segmentId = segmentId;
    } else {
      throw new Error("Can't export people: no people or segment selected.");
    }
    await request({
      url: `${apiBase}/v1/people/export`,
      method: "POST",
      data,
      withCredentials: true,
    });
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error.";
    throw new Error(msg);
  }
};

export const archivePeople = async ({
  peopleIds,
  segmentId,
}: {
  peopleIds?: string[];
  segmentId?: ISegment["id"];
}) => {
  try {
    const data = {} as any;
    if (peopleIds) {
      data.people = _.map(peopleIds, (personId) => ({ id: personId }));
    } else if (segmentId) {
      data.segmentId = segmentId;
    } else {
      throw new Error("Can't archive people: no people or segment selected.");
    }
    await request({
      url: `${apiBase}/v1/people/bulk`,
      method: "DELETE",
      data,
      withCredentials: true,
    });
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error.";
    throw new Error(msg);
  }
};

export const restorePeople = async (peopleIds: string[]) => {
  try {
    const data = { people: _.map(peopleIds, (personId) => ({ id: personId })) };
    await request({
      url: `${apiBase}/v1/people/restore`,
      method: "POST",
      data,
      withCredentials: true,
    });
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error.";
    throw new Error(msg);
  }
};

export const deletePerson = async (id: string) => {
  try {
    await request({
      url: `${apiBase}/v1/people?force=true`,
      method: "DELETE",
      data: { id },
      withCredentials: true,
    });
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error.";
    throw new Error(msg);
  }
};

export const searchPeople = async (query: string, page: number) => {
  try {
    const res: AxiosResponse<{ users: IEndUser[] }> = await request({
      url: `${apiBase}/v1/people/search`,
      params: { query, page, attributes: "all" },
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res?.data.users;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error. ";
    throw new Error(msg);
  }
};
