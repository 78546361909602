import { observable, action, autorun, computed, when, runInAction } from "mobx";
import { DomainStore } from "./domainStore";
import _ from "lodash";
import { IFlow } from "../components/Flows/FlowRoute";
import { IConfigureDrawerSize } from "../components/ConfigureStep/ConfigureDrawer";
import asDateTime from "../domain/helpers/asDateTime";
import {
  ISegment,
  ISegmentV2,
} from "encharge-domain/lib/definitions/ambient/segment";
import {
  GenericFolder,
  GenericFolderOrItem,
  FolderType,
} from "encharge-domain/lib/definitions/ambient/folder";
import { newTagId } from "components/TagsManagement/TagEditPopup";
import { ICommunicationCategory } from "encharge-domain/definitions/CommunicationCategory";
import { getFolderTypeForQuery } from "store/foldersStore";

export class UIStore {
  rootStore: DomainStore;
  activeFlowWarning = new ActiveFlowWarningUIStore(this);
  activeSegmentWarning = new ActiveSegmentWarningUIStore(this);
  emails = new EmailsUIStore(this);
  emailEditor = new EmailEditorUIStore(this);
  segmentUpdate = new SegmentUpdateUIStore(this);
  segmentDelete = new SegmentDeleteUIStore(this);
  personRetrive = new PersonRetrieveUIStore(this);
  personUpdate = new PersonUpdateUIStore(this);
  stepMetrics = new StepMetricsUIStore(this);
  emailMetrics = new EmailMetricsUIStore(this);
  emailDuplication = new EmailDuplicationUIStore(this);
  flowDelete = new FlowDeleteUIStore(this);
  flowCreate = new FlowCreateUIStore(this);
  recipeVideo = new RecipeVideoStore(this);
  editSegment = new EditSegmentUIStore(this);
  currentPersonUIStore = new CurrentPersonUIStore(this);
  configDrawer = new ConfigDrawerUIStore(this);
  stepsPreviewStore = new StepsPreviewStore(this);
  peopleSearch = new PeopleSearchStore(this);
  broadcastEdit = new BroadcastEditStore(this);
  mailingAddress = new MailingAddressDialogStore(this);
  communicationPreferences = new CommunicationPreferencesUIStore(this);
  aiWriter = new AiWriterUIStore(this);

  deleteFolderDialog = new ConfirmDialogStore<{
    folderId: GenericFolder["id"];
    itemsArchivable?: boolean;
    text?: string;
  }>(this);
  editFolderDialog = new ConfirmDialogStore<{
    item: GenericFolderOrItem;
    title?: string;
    confirmTitle?: string;
  }>(this);
  deleteSegmentDialog = new ConfirmDialogStore<{
    segmentId: ISegment["id"];
  }>(this);
  renameItemDialog = new ConfirmDialogStore<{
    item: any;
  }>(this);
  deleteFlowDialog = new ConfirmDialogStore<{
    flow: IFlow;
  }>(this);
  moveItemToFolderDialog = new ConfirmDialogStore<{
    type: FolderType;
    item: any;
  }>(this);
  pickColorDialog = new ConfirmDialogStore<{
    item: GenericFolderOrItem;
  }>(this);
  deleteAccountDialog = new ConfirmDialogStore<{
    id: IAccount["id"];
  }>(this);
  deleteDialog = new ConfirmDialogStore<{
    header?: string;
    text?: string;
    confirm?: string;
  }>(this);
  registerDialog = new ConfirmDialogStore(this);
  shareFlowDialog = new ConfirmDialogStore<{ flowId: IFlow["id"] }>(this);
  copyFlowDialog = new ConfirmDialogStore<{ flowId: IFlow["id"] }>(this);
  copyEmailDialog = new ConfirmDialogStore<{ emailId: IEmailContent["id"] }>(
    this
  );
  deleteCommunicationCategoryDialog = new ConfirmDialogStore<{
    categoryId: ICommunicationCategory["id"];
  }>(this);

  segmentCreate = new LoadingUIStore(this);
  emailDomainAdd = new LoadingUIStore(this);
  emailFontLoading = new LoadingUIStore(this);
  emailVerify = new LoadingUIStore(this);
  emailAllEmailsVerify = new LoadingUIStore(this);
  emailDomainDelete = new LoadingUIStore(this);
  switchAccount = new LoadingUIStore(this);
  deleteAccountLoading = new LoadingUIStore(this);
  flowStepsAndLinksRender = new LoadingUIStore(this);
  formTrackingSave = new LoadingUIStore(this);
  formTrackingDelete = new LoadingUIStore(this);
  accountPeopleTableFields = new LoadingUIStore(this);
  preparingMetricsPDF = new LoadingUIStore(this);
  segmentNameChange = new LoadingUIStore(this);
  teamMemberEdit = new LoadingUIStore(this);
  accountEdit = new LoadingUIStore(this);
  accountLogo = new LoadingUIStore(this);
  personAction = new LoadingUIStore(this);
  tagEditLoading = new LoadingUIStore(this);
  communicationCategoryLoading = new LoadingUIStore(this);
  communicationPreferencesLoading = new LoadingUIStore(this);
  accountFlagsUpdating = new LoadingUIStore(this);
  accountRegister = new LoadingWithErrorUIStore(this);
  aiWriterLoading = new LoadingUIStore(this);

  personFieldPopup: PersonFieldPopup = new PersonFieldPopup(
    this,
    "custom-field-edit",
    false
  );
  tagEditPopup = new TagEditPopup(this, "tag-edit", false);
  teamMemberPopup: TeamMemberPopup = new TeamMemberPopup(
    this,
    "team-member",
    false
  );
  communicationCategoryPopup: CommunicationCategoryPopup = new CommunicationCategoryPopup(
    this,
    "communication-category",
    false
  );

  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
  }
  // pendingStepUpdates: (IStep["id"] | IStep["tempId"]) = [];
  @observable
  loggingIn: {
    loading?: boolean;
    loadingGoogleSignin?: boolean;
    redirect?: boolean;
    showLogin?: boolean;
  } = { showLogin: true };

  @observable
  account: {
    loading?: boolean;
    error?: string;
  } = { loading: false, error: undefined };

  @observable
  accountCredentials: {
    loading?: boolean;
    error?: string;
  } = {};

  @observable
  flows: {
    loading?: boolean;
    error?: string;
  } = {};

  @observable
  editor: {
    isNewStepPopupOpen: boolean;
    isEmailPickerOpen: boolean;
    flowOnOff: {
      loading: boolean;
      flowId?: IFlow["id"];
    };
    flowName: {
      loading: boolean;
    };
  } = {
    isNewStepPopupOpen: false,
    isEmailPickerOpen: false,
    flowOnOff: { loading: false },
    flowName: { loading: false },
  };

  @action
  openNewStepPopup() {
    this.editor.isNewStepPopupOpen = true;
  }
  @action
  closeNewStepPopup() {
    this.editor.isNewStepPopupOpen = false;
  }
  @action
  toggleNewStepPopup() {
    this.editor.isNewStepPopupOpen = !this.editor.isNewStepPopupOpen;
  }

  @action
  openEmailPicker() {
    this.editor.isEmailPickerOpen = true;
  }
  @action
  closeEmailPicker() {
    this.editor.isEmailPickerOpen = false;
  }
}

class ActiveFlowWarningUIStore {
  uiStore: UIStore;
  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;
  }

  @observable
  showConfirmEditActiveFlow: boolean = false;

  @action
  showConfirmModal() {
    this.showConfirmEditActiveFlow = true;
  }

  @action
  closeConfirmModal() {
    this.showConfirmEditActiveFlow = false;
  }
}
class ActiveSegmentWarningUIStore {
  uiStore: UIStore;
  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;
  }

  @observable
  showConfirmEditActiveSegment: boolean = false;

  @observable
  flowsThatUseSegment: Dictionary<IFlow> = {};

  @action
  showConfirmModal(flows: Dictionary<IFlow>) {
    this.showConfirmEditActiveSegment = true;
    this.flowsThatUseSegment = flows;
  }

  @action
  closeConfirmModal() {
    this.showConfirmEditActiveSegment = false;
  }
}

class PersonRetrieveUIStore {
  uiStore: UIStore;
  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;
  }
  @observable
  loading = false;

  @observable
  error?: boolean;

  startPersonRetrieve() {
    this.loading = true;
    this.error = undefined;
  }
  stopPersonRetrive() {
    this.loading = false;
  }
}

class PersonUpdateUIStore {
  uiStore: UIStore;
  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;
  }
  @observable
  loading = false;

  @observable
  error?: boolean;

  startPersonRetrieve() {
    this.loading = true;
    this.error = undefined;
  }
  stopPersonRetrive() {
    this.loading = false;
  }
}

class SegmentUpdateUIStore {
  uiStore: UIStore;
  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;
  }

  @observable
  isUpdating: boolean = false;

  @action
  startUpdate() {
    console.log("start update");
    this.isUpdating = true;
  }

  @action
  finishUpdate() {
    console.log("end update");
    this.isUpdating = false;
  }
}

class FlowDeleteUIStore {
  uiStore: UIStore;
  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;
  }

  @observable
  flowIdsBeingDeleted: IFlow["id"][] = [];

  @action
  start(id: IFlow["id"]) {
    this.flowIdsBeingDeleted.push(id);
  }

  @action
  finish(finishedId: IFlow["id"]) {
    // remove this id from segments being deleted
    this.flowIdsBeingDeleted = _.filter(
      this.flowIdsBeingDeleted,
      (id) => id !== finishedId
    );
  }

  isDeleting(id: IFlow["id"]) {
    return this.flowIdsBeingDeleted.includes(id);
  }
}

class FlowCreateUIStore {
  uiStore: UIStore;
  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;

    autorun(() => {
      // access it so mobx knows when to run this reaction
      const isModalOpen = this.isModalOpen;
      // Make sure the rootStore is initialized
      if (!this.uiStore.rootStore || !this.uiStore.rootStore.locationStore) {
        return;
      }
      // Update the url from the state
      if (isModalOpen) {
        this.uiStore.rootStore.locationStore.addQueryParameter({
          parameter: "create-flow",
          value: "1",
          push: false,
        });
      } else {
        this.uiStore.rootStore.locationStore.removeQueryParameter({
          parameter: "create-flow",
          push: false,
        });
      }
    });
  }

  @observable
  isModalOpen: boolean = false;

  @action
  openModal() {
    this.isModalOpen = true;
  }

  @action
  closeModal() {
    this.isModalOpen = false;
  }

  @action
  toggleModal() {
    this.isModalOpen = !this.isModalOpen;
  }

  @observable
  isCreating: boolean = false;

  @action
  start() {
    this.isCreating = true;
  }

  @action
  finish() {
    // remove this id from segments being deleted
    this.isCreating = false;
  }
}

class SegmentDeleteUIStore {
  uiStore: UIStore;
  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;
  }

  @observable
  segmentIdsBeingDeleted: ISegment["id"][] = [];

  @action
  start(segmentId: ISegment["id"]) {
    this.segmentIdsBeingDeleted.push(segmentId);
  }

  @action
  finish(segmentId: ISegment["id"]) {
    // remove this id from segments being deleted
    this.segmentIdsBeingDeleted = _.filter(
      this.segmentIdsBeingDeleted,
      (id) => id !== segmentId
    );
  }

  isDeleting(segmentId: ISegment["id"]) {
    return this.segmentIdsBeingDeleted.includes(segmentId);
  }
}
class EmailsUIStore {
  uiStore: UIStore;
  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;
  }

  @observable
  search: string = "";

  @action
  setSearch(searchString: string) {
    this.search = searchString;
  }
}
class EmailEditorUIStore {
  uiStore: UIStore;
  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;
  }

  @computed
  get currentEmailId() {
    const idString = this.uiStore.rootStore.locationStore.getQueryParameter(
      "email"
    );
    return Number(idString) ? Number(idString) : idString;
  }

  @action
  setCurrentEmailId(id?: IEmailContent["id"]) {
    console.log("setCurrentEmailId", id);
    if (id) {
      this.uiStore.rootStore.locationStore.addQueryParameter({
        parameter: "email",
        value: String(id),
      });
    } else {
      this.uiStore.rootStore.locationStore.removeQueryParameter({
        parameter: "email",
      });
    }
  }

  @observable
  isVisualEmailEditorOpen: boolean = false;
  @observable
  isRichTextEmailEditorOpen: boolean = false;
  @observable
  isUnlayerEmailEditorOpen: boolean = false;
  @observable
  isEmailViewOnlyOpen: boolean = false;

  @observable
  onEditorCloseCallback?: (id: IEmailContent["id"]) => void = undefined;

  @action
  openEmailEditor({
    emailId,
    onEditorCloseCallback,
    type,
  }: {
    emailId?: IEmailContent["id"];
    onEditorCloseCallback?: (id: IEmailContent["id"]) => void;
    type?: EmailEditorType;
  } = {}) {
    this.setCurrentEmailId(emailId);

    this.openAppropriateEditor(emailId, type);

    this.onEditorCloseCallback = onEditorCloseCallback;
    // when we are opening the email editor,
    // by default it should be in edit mode
    this.switchEditorToEditMode();
  }

  @action
  private openAppropriateEditor(
    emailId?: IEmailContent["id"],
    type?: EmailEditorType
  ) {
    if (emailId) {
      // Open readonly editor
      if (
        !this.uiStore.rootStore.permissionsStore.checkScope("emails:write") ||
        type === "preview"
      ) {
        this.isEmailViewOnlyOpen = true;
        return;
      }
      const email = this.uiStore.rootStore.emailsStore.getEmailById(emailId);
      const editorType = email?.editor?.type;
      if (editorType === "richtext") {
        this.isRichTextEmailEditorOpen = true;
      } else if (editorType === "unlayer" || !email) {
        this.isUnlayerEmailEditorOpen = true;
      } else {
        this.isVisualEmailEditorOpen = true;
      }
      return;
    }

    if (type === "richtext") {
      this.isRichTextEmailEditorOpen = true;
    } else if (type === "unlayer" || type === undefined) {
      this.isUnlayerEmailEditorOpen = true;
    } else if (type === "visual") {
      this.isVisualEmailEditorOpen = true;
    }
  }

  @action
  closeEmailEditor(emailId?: IEmailContent["id"]) {
    this.isVisualEmailEditorOpen = false;
    this.isRichTextEmailEditorOpen = false;
    this.isUnlayerEmailEditorOpen = false;
    this.isEmailViewOnlyOpen = false;
    if (this.onEditorCloseCallback && emailId) {
      this.onEditorCloseCallback(emailId);
    }
    // clear after closing
    this.onEditorCloseCallback = undefined;

    // Resets the current email id
    this.setCurrentEmailId(undefined);

    // clear warnings flag
    this.hasWarnings = false;
    this.warningsDialog = {
      show: false,
    };
  }

  @observable
  emailEditorMode: "preview" | "edit" = "edit";
  @action
  switchEditorToEditMode() {
    this.emailEditorMode = "edit";
  }
  @action
  switchEditorToPreviewMode() {
    this.emailEditorMode = "preview";
  }

  @observable
  isExtraFieldsOpen: boolean = false;
  @action
  toggleEmailExtraFields() {
    this.isExtraFieldsOpen = !this.isExtraFieldsOpen;
  }
  @action
  showEmailExtraFields() {
    this.isExtraFieldsOpen = true;
  }

  @observable
  isSavingEmail = false;

  @observable
  isSendingTestEmail = false;

  @observable
  hasWarnings = false;
  @action
  setHasWarnings(hasWarnings: boolean) {
    this.hasWarnings = hasWarnings;
  }

  @observable
  warningsDialog: {
    show: boolean;
    onSaveButtonClick?: () => void;
  } = {
    show: false,
  };
  @action
  setShowWarningsDialog({
    show,
    onSaveButtonClick,
  }: typeof EmailEditorUIStore["prototype"]["warningsDialog"]) {
    this.warningsDialog = {
      show,
      onSaveButtonClick,
    };
  }
}

class StepMetricsUIStore {
  uiStore: UIStore;
  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;
  }

  @observable
  isLoading = false;

  @action
  startLoading() {
    this.isLoading = true;
  }
  @action
  stopLoading() {
    this.isLoading = false;
  }
}
class EmailMetricsUIStore {
  uiStore: UIStore;
  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;
  }

  @observable
  metricName: IPredefinedStatsPeriods = "last30Days";

  @observable
  flowsIds: IIntegration["id"][] = [];

  @action
  setFlowIds(ids: IIntegration["id"][]) {
    this.flowsIds = ids;
  }

  @observable
  emailIds: IIntegration["id"][] = [];

  @action
  setEmailIds(ids: IIntegration["id"][]) {
    this.emailIds = ids;
  }

  @action
  setMetric(name: IPredefinedStatsPeriods) {
    this.metricName = name;
  }

  @computed
  get metricsPeriod(): Exclude<IStatsPeriod, "allTime"> {
    switch (this.metricName) {
      case "last7Days":
      case "lastWeek":
      case "thisWeek":
        return "hour";
      case "last30Days":
      case "last90Days":
      case "lastMonth":
      case "thisMonth":
      case "lastQuarter":
      case "thisQuarter":
        return "day";
      case "lastYear":
      case "thisYear":
        return "week";
      default:
        throw new Error("Couldn't set metric period.");
    }
  }

  @computed
  get startDate() {
    switch (this.metricName) {
      case "last7Days":
        return asDateTime(new Date())
          .minus({ days: 7 })
          .startOf("day")
          .toJSDate();
      case "last30Days":
        return asDateTime(new Date())
          .minus({ days: 30 })
          .startOf("day")
          .toJSDate();
      case "last90Days":
        return asDateTime(new Date())
          .minus({ days: 90 })
          .startOf("day")
          .toJSDate();
      case "lastWeek":
        return asDateTime(new Date())
          .minus({ week: 1 })
          .startOf("week")
          .toJSDate();
      case "thisWeek":
        return asDateTime(new Date()).startOf("week").toJSDate();
      case "lastMonth":
        return asDateTime(new Date())
          .minus({ month: 1 })
          .startOf("month")
          .toJSDate();
      case "thisMonth":
        return asDateTime(new Date()).startOf("month").toJSDate();
      case "lastQuarter":
        return asDateTime(new Date())
          .minus({ quarter: 1 })
          .startOf("quarter")
          .toJSDate();
      case "thisQuarter":
        return asDateTime(new Date()).startOf("quarter").toJSDate();
      case "lastYear":
        return asDateTime(new Date())
          .minus({ year: 1 })
          .startOf("year")
          .toJSDate();
      case "thisYear":
        return asDateTime(new Date()).startOf("year").toJSDate();
      default:
        throw new Error("Couldn't set metric start date.");
    }
  }

  @computed
  get endDate() {
    switch (this.metricName) {
      case "last7Days":
        return asDateTime(new Date()).endOf("day").toJSDate();
      case "last30Days":
        return asDateTime(new Date()).endOf("day").toJSDate();
      case "last90Days":
        return asDateTime(new Date()).endOf("day").toJSDate();
      case "lastWeek":
        return asDateTime(new Date())
          .minus({ week: 1 })
          .endOf("week")
          .toJSDate();
      case "thisWeek":
        return asDateTime(new Date()).toJSDate();
      case "lastMonth":
        return asDateTime(new Date())
          .minus({ month: 1 })
          .endOf("month")
          .toJSDate();
      case "thisMonth":
        return asDateTime(new Date()).toJSDate();
      case "lastQuarter":
        return asDateTime(new Date())
          .minus({ quarter: 1 })
          .endOf("quarter")
          .toJSDate();
      case "thisQuarter":
        return asDateTime(new Date()).toJSDate();
      case "lastYear":
        return asDateTime(new Date())
          .minus({ year: 1 })
          .endOf("year")
          .toJSDate();
      case "thisYear":
        return asDateTime(new Date()).toJSDate();
      default:
        throw new Error("Couldn't set metric end date.");
    }
  }

  @observable
  isLoading = false;

  @action
  startLoading() {
    console.log("loading metrics");
    this.isLoading = true;
  }
  @action
  stopLoading() {
    this.isLoading = false;
  }
}

class EmailDuplicationUIStore {
  uiStore: UIStore;
  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;
  }

  @observable
  loading = false;

  @action
  startLoading() {
    this.loading = true;
  }

  @action
  stopLoading() {
    this.loading = false;
  }
}

class RecipeVideoStore {
  uiStore: UIStore;
  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;
  }

  @observable
  isVisible = false;

  @action
  show() {
    this.isVisible = true;
  }

  @action
  hide() {
    this.isVisible = false;
  }
}

class LoadingUIStore {
  uiStore: UIStore;
  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;
  }

  @observable
  isLoading: boolean = false;

  @action
  startLoading() {
    this.isLoading = true;
  }

  @action
  finishLoading() {
    this.isLoading = false;
  }
}

class LoadingWithErrorUIStore {
  uiStore: UIStore;
  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;
  }

  @observable
  isLoading: boolean = false;
  @observable
  error?: string;

  @action
  startLoading() {
    this.isLoading = true;
    this.error = undefined;
  }

  @action
  finishLoading(error?: string) {
    this.isLoading = false;
    this.error = error;
  }
}

class CurrentPersonUIStore {
  uiStore: UIStore;
  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;
  }

  @observable
  selectedPerson?: string;

  updateCurrentPersonFromRoute() {
    const path = window.location.pathname;
    if (path.includes("/people/") && path.split("/people/")[1]) {
      // only update if necessary
      const person = path.split("/people/")[1];
      if (person !== this.selectedPerson) {
        this.selectPerson(person);
      }
    } else {
      // only update if necessary
      if (this.selectedPerson) {
        this.unselectPerson();
      }
    }
    return this.selectedPerson;
  }

  @action
  selectPerson(id: string) {
    this.selectedPerson = id;
  }

  @action
  unselectPerson() {
    this.selectedPerson = undefined;
  }
}

class ConfigDrawerUIStore {
  uiStore: UIStore;
  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;
  }

  @observable
  size?: IConfigureDrawerSize;

  @action
  setSize(size: IConfigureDrawerSize | undefined) {
    if (this.size !== size) {
      this.size = size;
    }
  }

  @observable
  saved: Boolean = true;

  @action
  setSaved(saved: boolean) {
    this.saved = saved;
  }
}
class EditSegmentUIStore {
  uiStore: UIStore;
  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;
  }

  @observable
  conditionsOpen: boolean = false;

  @action
  async setConditionsOpen(state?: boolean) {
    const newState =
      state === undefined
        ? // toggle
          !this.conditionsOpen
        : state;
    // if we are opening conditions, reset segment WIP
    if (newState === true && this.conditionsOpen !== true) {
      this.setSegmentWIP();
    }
    if (newState === false && this.conditionsOpen !== false) {
      const canClose = await this.checkCanCloseConditions();

      if (!canClose) return;
      // reset segment WIP
      this.setSegmentWIP();
    }
    // finally set the state
    runInAction(() => (this.conditionsOpen = newState));
  }

  @observable
  closeConditionsDialogOpen: boolean = false;
  @observable
  closeConditionsOK: boolean = false;
  @observable
  closeConditionsCancel: boolean = false;

  @action
  async checkCanCloseConditions() {
    // conditions editting not open, so safely discard
    if (!this.conditionsOpen) return true;
    // no segment work in progress, so safely discard
    if (!this.segmentWIP) return true;

    // WIP is same than current segment
    if (
      _.isEqual(
        this.segmentWIP.conditions,
        this.uiStore.rootStore.segmentStore.currentSegment?.conditions
      )
    ) {
      return true;
    }
    this.closeConditionsDialogOpen = true;
    return new Promise((resolve) => {
      // We can edit the flow, so return true
      when(
        () => this.closeConditionsOK,
        () => {
          // return true to OK the action waiting on this
          resolve(true);
          runInAction(() => {
            this.closeConditionsOK = false;
          });
        }
      );
      // Cancel editing the flow
      when(
        () => this.closeConditionsCancel,
        () => {
          // return false to cancel the action waiting on this
          resolve(false);
          runInAction(() => {
            this.closeConditionsCancel = false;
          });
        }
      );
    });
  }
  @action
  setCloseConditionsOK() {
    this.setSegmentWIP();
    this.closeConditionsDialogOpen = false;
    this.closeConditionsOK = true;
  }
  @action
  setCloseConditionsCancel() {
    this.closeConditionsDialogOpen = false;
    this.closeConditionsOK = false;
  }

  segmentWIP?: ISegmentV2 = undefined;
  @action
  setSegmentWIP(segment?: ISegmentV2) {
    this.segmentWIP = segment;
  }
}

class StepsPreviewStore {
  uiStore: UIStore;
  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;
  }

  @observable
  preview?: boolean = true;

  @action
  hidePreview() {
    this.preview = false;
  }
  @action
  showPreview() {
    this.preview = true;
  }
}
class PeopleSearchStore {
  uiStore: UIStore;
  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;
  }

  @observable
  isOpen?: boolean = false;
  @action
  open() {
    this.isOpen = true;
  }
  @action
  close() {
    this.isOpen = false;
    this.isLoading = false;
    this.uiStore.rootStore.peopleSearchStore.reset();
  }
  @action
  toggle() {
    this.isOpen ? this.close() : this.open();
    // clear selected people
    if (this.isOpen) this.uiStore.rootStore.peopleSearchStore.reset();
  }

  @observable
  isLoading: boolean = false;
  @action
  startLoading() {
    this.isLoading = true;
  }
  @action
  finishLoading() {
    // remove this id from segments being deleted
    this.isLoading = false;
  }
}

export class ConfirmDialogStore<T> {
  uiStore: UIStore;
  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;
  }

  @observable
  isOpen: boolean = false;

  @observable
  onConfirmCallback: undefined | ((...args: any) => any);

  @observable
  onCancelCallback: undefined | ((...args: any) => void);

  @observable
  additionalData: T | undefined;

  @action
  open({
    onConfirmCallBack,
    onCancelCallback,
    additionalData,
  }: {
    onConfirmCallBack?: (...args: any) => any;
    onCancelCallback?: (...args: any) => any;
    additionalData?: T;
  } = {}) {
    this.isOpen = true;
    this.onConfirmCallback = onConfirmCallBack;
    this.onCancelCallback = onCancelCallback;
    this.additionalData = additionalData;
  }

  @action
  close({
    invokeOnCancel,
    invokeOnConfirm,
  }: {
    invokeOnConfirm?: boolean;
    invokeOnCancel?: boolean;
  } = {}) {
    this.isOpen = false;
    if (invokeOnCancel) this.onCancelCallback?.();
    this.onCancelCallback = undefined;
    if (invokeOnConfirm) this.onConfirmCallback?.();
    this.onConfirmCallback = undefined;
    this.additionalData = undefined;
  }
}

// Uses URL query as the source of truth
class URLQueryMonitorStore {
  uiStore: UIStore;
  constructor(uiStore: UIStore, name: string, push: boolean = true) {
    this.uiStore = uiStore;
    this.parameterName = name;

    this.push = push;
  }
  // whether to push changes in the url history
  push: boolean;
  // The query parameter name we are interested in
  private parameterName: string;

  @computed
  get value() {
    return this.uiStore.rootStore.locationStore.getQueryParameter(
      this.parameterName
    );
  }

  @action
  setValue(value?: string, push?: boolean) {
    const pushInURLHistory = push !== undefined ? push : this.push;
    if (value === undefined) {
      this.uiStore.rootStore.locationStore.removeQueryParameter({
        parameter: this.parameterName,
        push: pushInURLHistory,
      });
    } else {
      this.uiStore.rootStore.locationStore.addQueryParameter({
        parameter: this.parameterName,
        value,
        push: pushInURLHistory,
      });
    }
  }
}

class PersonFieldPopup extends URLQueryMonitorStore {
  @action
  open(fieldName: string) {
    this.setValue(fieldName);
  }

  @action
  close() {
    this.setValue();
    this.initialFieldType = undefined;
    this.initialFieldName = undefined;
    this.initialFieldDescription = undefined;
  }

  @computed
  get isOpen() {
    return this.value !== undefined;
  }

  @observable
  initialFieldName?: string;

  @observable
  initialFieldType?: string;

  @observable
  initialFieldDescription?: string;

  @action
  setInitialFieldData({
    initialFieldName,
    initialFieldType,
    initialFieldDescription,
  }: {
    initialFieldName?: string;
    initialFieldType?: string;
    initialFieldDescription?: string;
  }) {
    this.initialFieldName = initialFieldName;
    this.initialFieldType = initialFieldType;
    this.initialFieldDescription = initialFieldDescription;
  }
}

class TagEditPopup extends URLQueryMonitorStore {
  @action
  newTag() {
    this.setValue(newTagId);
  }

  @action
  open(tag: string) {
    this.setValue(tag);
  }

  @action
  close() {
    this.setValue();
  }

  @computed
  get isOpen() {
    return this.value !== undefined;
  }
}

class TeamMemberPopup extends URLQueryMonitorStore {
  @action
  open(id: number | "new") {
    this.setValue(String(id));
  }
  @action
  close() {
    this.setValue();
  }
  @computed
  get isOpen() {
    return this.value !== undefined;
  }
}

class CommunicationCategoryPopup extends URLQueryMonitorStore {
  @action
  open(id: number | "new") {
    this.setValue(String(id));
  }
  @action
  close() {
    this.setValue();
  }
  @computed
  get isOpen() {
    return this.value !== undefined;
  }
}

class BroadcastEditStore {
  uiStore: UIStore;
  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;
  }

  @observable
  isUpdating = false;

  @action
  setIsUpdating(isCreating: boolean) {
    this.isUpdating = isCreating;
  }

  @action
  open(id?: Broadcast["id"]) {
    if (id) {
      this.uiStore.rootStore.locationStore.redirect({
        path: `/broadcasts/${id}`,
        keepQuery: true,
      });
    }
  }

  @action
  close() {
    this.uiStore.rootStore.locationStore.redirect({
      path: `/broadcasts`,
      keepQuery: [getFolderTypeForQuery("broadcasts")],
    });
  }

  // Used to show a save button when the broadcast time was changed
  @observable
  timeChanged?: Date;

  @action
  timeHasChanged(time: Date) {
    this.timeChanged = time;
  }

  @action
  timeHasNotChanged() {
    this.timeChanged = undefined;
  }
}
class MailingAddressDialogStore {
  uiStore: UIStore;
  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;
  }

  @observable
  isOpen = false;

  @action
  toggle() {
    this.isOpen = !this.isOpen;
  }

  @action
  open() {
    this.isOpen = true;
  }

  @action
  close() {
    this.isOpen = false;
  }
}

class CommunicationPreferencesUIStore {
  uiStore: UIStore;
  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;
  }

  @observable
  error?: string;

  @action
  setError(error?: string) {
    this.error = error;
  }
}

class AiWriterUIStore {
  uiStore: UIStore;
  constructor(uiStore: UIStore) {
    this.uiStore = uiStore;
  }

  @observable
  isOpen: boolean = false;

  @action
  close() {
    this.isOpen = false;
  }
  @action
  open() {
    this.isOpen = true;
  }
  @action
  toggle() {
    this.isOpen = !this.isOpen;
  }
}

export default UIStore;
