import * as coreHttp from "@azure/core-http";
import { EnchargeAPIOptionalParams } from "./models";

const packageName = "EnchargeAPI";
const packageVersion = "1.0.0";

export class EnchargeAPIContext extends coreHttp.ServiceClient {
  $host: string;

  /**
   * Initializes a new instance of the EnchargeAPIContext class.
   * @param credentials Subscription credentials which uniquely identify client subscription.
   * @param options The parameter options
   */
  constructor(
    credentials: coreHttp.TokenCredential | coreHttp.ServiceClientCredentials,
    options?: EnchargeAPIOptionalParams
  ) {
    if (credentials === undefined) {
      throw new Error("'credentials' cannot be null");
    }

    // Initializing default values for options
    if (!options) {
      options = {};
    }

    if (!options.userAgent) {
      const defaultUserAgent = coreHttp.getDefaultUserAgentValue();
      options.userAgent = `${packageName}/${packageVersion} ${defaultUserAgent}`;
    }

    super(credentials, options);

    this.requestContentType = "application/json; charset=utf-8";

    this.baseUri = options.endpoint || "https://api.encharge.io/v1";

    // Assigning values to Constant parameters
    this.$host = options.$host || "https://api.encharge.io/v1";
  }
}
