import request, { AxiosResponse } from "axios";
import { apiBase } from "../../constants";

import { IPersonField } from "encharge-domain/definitions/PersonField";

export const createFields = async (fields: IPersonField[]) => {
  try {
    const res: AxiosResponse<{
      items: IPersonField[];
    }> = await request({
      url: `${apiBase}/v1/fields`,
      method: "POST",
      data: fields,
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.items;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't create these fields. ";
    throw new Error(msg);
  }
};

export const editField = async (field: IPersonField) => {
  try {
    const res: AxiosResponse<{
      item: IPersonField;
    }> = await request({
      url: `${apiBase}/v1/fields/${field.name}`,
      method: "PATCH",
      data: field,
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.item;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't edit this field.";
    throw new Error(msg);
  }
};

export const deleteField = async (fieldName: IPersonField["name"]) => {
  try {
    await request({
      url: `${apiBase}/v1/fields/${fieldName}`,
      method: "DELETE",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't delete this field.";
    throw new Error(msg);
  }
};
