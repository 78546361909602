import { useRef } from "react";
import { Badge, Progress, UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import { useStores } from "store/useStore";
import { observer } from "mobx-react";

const OnboardingBadge = () => {
  const nextStepData = useNextStep();
  if (!nextStepData) {
    return null;
  }
  const { progress, nextStepPath, nextStepTitle, numSteps } = nextStepData;
  if (!nextStepTitle || !nextStepPath) {
    return null;
  }
  return (
    <div className="float-right main-bar-right-section">
      <Link to={nextStepPath}>
        <>
          {/* On large screen display getting started widget */}
          <div className="col d-none d-sm-block" style={{ marginTop: "-6px" }}>
            <small className="text-secondary">Getting Started</small>

            <Progress
              color="success"
              value={progress}
              style={{ width: "90px", height: "10px", background: "#DEDDDC" }}
            />
          </div>
          {/* On small screens show much smaller badge */}
          <div className="d-inline-block d-sm-none">
            <Badge
              className="d-flex align-items-center"
              color="success"
              style={{ height: "32px" }}
            >
              {progress / 20} / {numSteps}
            </Badge>
          </div>
        </>
      </Link>

      <span className="main-bar-pipe" />
    </div>
  );
};

const useNextStep = () => {
  const { accountStore } = useStores();
  // Wait for the account to load
  if (!accountStore?.account) {
    return null;
  }
  const numSteps = 4;
  if (
    !accountStore?.account?.onboarding?.import &&
    // if there are people on the account, ignore import
    // there is at least 1 person which is the account owner
    (accountStore?.account?.peopleCount || 0) <= 1
  ) {
    return {
      progress: 20,
      nextStepPath: "/start/import",
      nextStepTitle: "Import People",
      numSteps,
    };
  }
  if (!accountStore?.account?.onboarding?.apps) {
    return {
      progress: 40,
      nextStepPath: "/start/apps",
      nextStepTitle: "Connect Apps",
      numSteps,
    };
  }
  if (!accountStore?.account?.onboarding?.flow) {
    return {
      progress: 60,
      nextStepPath: "/start/flow",
      nextStepTitle: "Create Flow",
      numSteps,
    };
  }
  if (!accountStore?.account?.onboarding?.help) {
    return {
      progress: 80,
      nextStepPath: "/start/help",
      nextStepTitle: "Get Help",
      numSteps,
    };
  }
  return null;
};

export default observer(OnboardingBadge);
