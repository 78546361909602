import request, { AxiosResponse } from "axios";
import { apiBase, segmentPreviewMaxPeople } from "../../constants";
import {
  SerializableStep,
  StepBase,
  IStep,
  IStepPeople,
} from "../../components/FlowEditor/Step/StepBase";

export const createStep = async (step: SerializableStep) => {
  const res: AxiosResponse<{
    step: IIntegrationStep & { service?: IService };
  }> = await request({
    url: `${apiBase}/v1/steps/`,
    method: "POST",
    data: StepBase.serialize(step),
    withCredentials: true,
    validateStatus: (status) => status < 300,
  });
  const newOp = await StepBase.unserialize(res.data.step);
  return newOp;
};

export const updateStep = async (step: SerializableStep) => {
  if (!step.id) {
    throw new Error("Can't update step without ID.");
  }
  const res: AxiosResponse<{
    step: IIntegrationStep & { service?: IService };
  }> = await request({
    url: `${apiBase}/v1/steps/${step.id}`,
    method: "PATCH",
    data: StepBase.serialize(step),
    withCredentials: true,
    validateStatus: (status) => status < 300,
  });
  return StepBase.unserialize(res.data.step);
};

export const deleteStep = async (id: NonNullable<IStep["id"]>) => {
  if (!id) {
    throw new Error("Can't delete step without ID.");
  }
  return request({
    url: `${apiBase}/v1/steps/${id}`,
    method: "DELETE",
    withCredentials: true,
    validateStatus: (status) => status < 300,
  });
};

export const getStep = async (id: IStep["id"], refresh = true) => {
  if (!id) {
    throw new Error("Can't refresh step without ID.");
  }
  const res: AxiosResponse<{
    step: IIntegrationStep & { service: IService };
  }> = await request({
    url: `${apiBase}/v1/steps/${id}`,
    params: {
      refresh,
    },
    method: "GET",
    withCredentials: true,
    validateStatus: (status) => status < 300,
  });
  return StepBase.unserialize(res.data.step);
};

export const getStepPeople = async (id: IStep["id"]) => {
  if (!id) {
    throw new Error("Can't get people for step without ID.");
  }
  const res: AxiosResponse<{
    people: IStepPeople;
  }> = await request({
    url: `${apiBase}/v1/steps/${id}/people?limit=${segmentPreviewMaxPeople}`,
    method: "GET",
    withCredentials: true,
    validateStatus: (status) => status < 300,
  });
  return res.data.people;
};
