import { DomainStore } from "./domainStore";
import { action, observable, computed } from "mobx";
import qs from "qs";
import { LocationDescriptorObject } from "history";
import { RouteComponentProps } from "react-router-dom";
import _ from "lodash";
import { param } from "jquery";
import { computedFn, deepObserve } from "mobx-utils";

export class LocationStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
    this.setQuery(window.location.search);
    this.setPath(window.location.pathname);
  }

  @observable
  private query: Dictionary<any> = {};
  @action
  private setQuery(search: Location["search"]) {
    this.query = qs.parse(search.replace(/^\??/, ""));
    // const newQuery = qs.parse(search.replace(/^\??/, ""));
    // _.map(this.query, (_value, key) => {
    //   if (!_.isEqual(this.query[key], newQuery[key])) {
    //     if (typeof newQuery[key] === "undefined") {
    //       delete this.query[key];
    //     } else {
    //       this.query[key] = newQuery[key];
    //     }
    //   }
    // });
    // _.map(newQuery, (value, key) => {
    //   if (!this.query[key]) {
    //     this.query[key] = value;
    //   }
    // });
  }

  @observable
  path: string | undefined;
  @action
  private setPath(path: string) {
    this.path = path;
  }

  historyInitted = false;
  history: RouteComponentProps["history"] | undefined;

  // Follow changes from react router
  initHistory({
    history,
    force,
  }: {
    history: RouteComponentProps["history"];
    force?: boolean;
  }) {
    if (this.historyInitted && !force) {
      return;
    }
    this.history = history;
    history.listen(() => {
      this.setPath(window.location.pathname);
      this.setQuery(window.location.search);
    });
    this.historyInitted = true;
  }

  @action
  redirect({
    path,
    push = true,
    keepQuery = false,
  }: {
    path: string | LocationDescriptorObject;
    push?: boolean;
    keepQuery?: boolean | string[];
  }) {
    if (!this.history) return;
    let redirectPath;
    if (typeof path === "string") {
      let query = "";
      if (typeof keepQuery === "boolean") {
        query = keepQuery ? window.location.search : "";
      } else if (typeof keepQuery?.length !== "undefined") {
        // Keep query parameters as indicated in keepQuery array
        const queryParsed = qs.parse(
          window.location.search.replace(/^\??/, "")
        );
        const queryToKeep = _.pick(queryParsed, keepQuery);
        query = `?${qs.stringify(queryToKeep)}`;
      }
      redirectPath = `${path}${query}`;
    } else {
      redirectPath = this.history.createHref(path);
    }
    if (push) {
      this.history?.push(redirectPath);
    } else {
      this.history?.replace(redirectPath);
    }
  }

  // Getting unexisting properties of query wont trigger reactions (rerenders)
  // as mobx will have nothing to observer. Thus, we utilize the computedFn
  // as described in https://mobx.js.org/computeds-with-args.html#4-use-computedfn-
  // getQueryParameter = computedFn((parameter: string) => {
  //   return this.query[parameter];
  // });
  getQueryParameter = (parameter: string) => {
    return computed(() => this.query[parameter]).get();
  };

  @action
  addQueryParameter({
    parameter,
    value,
    push = true,
  }: {
    parameter: string;
    value: string;
    push?: boolean;
  }) {
    // We need to remove the leading ? from the query string
    const query = qs.parse(window.location.search.replace(/^\??/, ""));
    // don't redirect if the parameter is already present
    if (query[parameter] && query[parameter] === value) {
      return;
    }
    query[parameter] = value;
    if (!this.query) {
      this.query = {};
    }
    this.query[parameter] = value;
    this.redirect({
      path: {
        pathname: window.location.pathname,
        search: qs.stringify(query),
        hash: window.location.hash,
      },
      push,
    });
  }

  @action
  removeQueryParameter({
    parameter,
    push = true,
  }: {
    parameter: string;
    push?: boolean;
  }) {
    // We need to remove the leading ? from the query string
    const query = qs.parse(window.location.search.replace(/^\??/, ""));
    // don't redirect if the parameter is already removed
    if (query[parameter] === undefined) {
      return;
    }
    delete query[parameter];
    this.redirect({
      path: {
        pathname: window.location.pathname,
        search: qs.stringify(query),
        hash: window.location.hash,
      },
      push,
    });
    this.query = query;
  }
}
