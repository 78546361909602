import { useCallback, useState } from "react";
import { Alert, Input, Label } from "reactstrap";

import { observer } from "mobx-react";
import RegistrationStepFormCommon from "components/Registration/RegistrationStepFormCommon";
import AccountIndustrySelect from "components/AccountInfo/AccountIndustrySelect";
import HelpTooltip from "components/Common/HelpTooltip";
import { RegistrationData } from "store/registrationStore";

interface Props {
  submit: (formData: any) => void;
  loading?: boolean;
  error?: string;

  name: string;
}
const RegistrationStepCompany = (props: Props) => {
  const [formData, setFormData] = useState<RegistrationData>({
    site: "",
    industry: "",
    estimatedContacts: "",
  });
  const [inSiteInput, setInSiteInput] = useState(false);

  const handleFieldChange = (e: React.FormEvent) => {
    /// @ts-ignore - e.target.name is not defined on state
    setFormData({ ...formData, [e.target.name]: e?.target?.value || "" });
  };
  const submitProp = props.submit;
  const onSubmit = useCallback(() => {
    submitProp(formData);
  }, [submitProp, formData]);

  const isSiteValid = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-zA-Z0-9]+([\-\.]{1}[a-zA-Z0-9]+)*\.[a-zA-Z]{2,25}(:[0-9]{1,5})?(\/.*)?$/.test(
    formData.site || ""
  );

  const heading = `${props.name}, tell us about your business.`;
  const subheading = "Help us to personalize your experience.";
  const subsubheading = "";

  return (
    <RegistrationStepFormCommon
      heading={heading}
      subheading={subheading}
      subsubheading={subsubheading}
      onSubmit={onSubmit}
      loading={props.loading}
      error={props.error}
      disabled={
        !Boolean(
          formData.site &&
            isSiteValid &&
            formData.industry &&
            formData.estimatedContacts
        )
      }
    >
      <Label className="mt-3 col-form-label-lg" for="site">
        Company Site
      </Label>
      <Input
        value={formData.site}
        type="text"
        placeholder="google.com"
        autoComplete="site"
        name="site"
        className="form-control-lg mb-3 mt-2"
        required={true}
        onChange={handleFieldChange}
        id="site"
        data-testid="site"
        onFocus={() => setInSiteInput(true)}
        onBlur={() => setInSiteInput(false)}
      />
      {formData.site && !isSiteValid && !inSiteInput && (
        <Alert color="warning">Double-check your site.</Alert>
      )}
      <AccountIndustrySelect
        value={formData.industry || ""}
        onChange={(value) => {
          setFormData({ ...formData, industry: value });
        }}
        labelClassName={"col-form-label-lg"}
        inputClassName={"form-control-lg"}
      />
      <Label for="estimatedContacts" className="mt-3 col-form-label-lg">
        How many contacts do you have?{" "}
        <HelpTooltip>
          This is the total number of email addresses in your database.
        </HelpTooltip>
      </Label>
      <Input
        type="select"
        name="estimatedContacts"
        id="estimatedContacts"
        className="form-control-lg"
        value={formData.estimatedContacts}
        onChange={handleFieldChange}
      >
        <option>Select an option...</option>
        <option value="0 - 500">0 - 500</option>
        <option value="1,000 - 2,000">1,000 - 2,000</option>
        <option value="2,000 - 5,000">2,000 - 5,000</option>
        <option value="5,000 - 10,000">5,000 - 10,000</option>
        <option value="10,000 - 20,000">10,000 - 20,000</option>
        <option value="20,000 - 50,000">20,000 - 50,000</option>
        <option value="50,000 - 100,000">50,000 - 100,000</option>
        <option value="100,000 - 200,000">100,000 - 200,000</option>
        <option value="200,000 - 500,000">200,000 - 500,000</option>
        <option value="500,000 - 1,000,000">500,000 - 1,000,000</option>
        <option value="Over 1,000,000">Over 1,000,000</option>
      </Input>
    </RegistrationStepFormCommon>
  );
};

export default observer(RegistrationStepCompany);
