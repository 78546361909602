import { IStep } from "../../components/FlowEditor/Step/StepBase";
import request, { AxiosResponse } from "axios";
import { apiBase } from "../../constants";
import { handleForbiddenRead } from "./handleForbidden";

export const getStepMetrics = async ({
  id,
  period,
  timezone,
}: {
  id: IStep["id"];
  period: IStatsPeriod;
  timezone: string;
}) => {
  if (!id) {
    throw new Error("Can't refresh step without ID.");
  }
  const res: AxiosResponse<{
    stats: IEmailsStats;
  }> = await request({
    url: `${apiBase}/v1/emails/stats`,
    params: {
      period,
      stepId: [id],
      timezone,
    },
    method: "GET",
    withCredentials: true,
    validateStatus: (status) => status < 300,
  });
  return res.data.stats;
};

export const getEmailsMetrics = async (params: {
  period: IStatsPeriod;
  timezone: string;
  startDate?: Date;
  endDate?: Date;
  flowIds?: IIntegration["id"][];
  emailIds?: IEmailContent["id"][];
  broadcastId?: Broadcast["id"];
  abTest?: boolean;
  groupByEmail?: boolean;
}) => {
  try {
    const res: AxiosResponse<{
      stats: IEmailsStats;
      clicks: IEmailClicksMetrics;
    }> = await request({
      url: `${apiBase}/v1/emails/stats`,
      params,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    if (handleForbiddenRead(e.response)) return { stats: {}, clicks: [] };
    throw e;
  }
};

export const getPeopleMetrics = async (period: IStatsPeriod) => {
  try {
    const res: AxiosResponse<{
      metrics: IPeopleMetrics;
    }> = await request({
      url: `${apiBase}/v1/people/metrics/${period}`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });

    return res.data.metrics;
  } catch (e) {
    if (handleForbiddenRead(e.response)) return undefined;
    throw e;
  }
};
