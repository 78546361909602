import { Component } from "react";
import { Collapse } from "reactstrap";
/// @ts-ignore - no definitions
import StackTracey from "stacktracey";

interface Props {
  msg: { message: string; extra: string };
}
interface State {
  isExpanded: boolean;
}

export default class ToasterMessage extends Component<Props, State> {
  state = { isExpanded: false };
  render() {
    let extra: any = this.props.msg.extra;
    let errMessage = "";
    // If is an error OR looks like an error
    if (extra instanceof Error || (extra.message && extra.stack)) {
      const trace = new StackTracey(extra.stack)[0];
      // try getting http response error if any
      errMessage =
        extra?.details?.error?.message ||
        extra?.response?.data?.error?.message ||
        extra?.message;
      extra = (
        <>
          at {trace.calleeShort} in {trace.fileName}:{trace.line}{" "}
        </>
      );
    }
    return (
      <>
        {this.props.msg.message}
        {this.props.msg.message && errMessage && <br />} {errMessage}
        {process.env.NODE_ENV === "development" && extra && (
          <>
            <br />
            <span
              className="btn-link"
              onClick={() =>
                this.setState({ isExpanded: !this.state.isExpanded })
              }
            >
              {!this.state.isExpanded && "More"}
              {this.state.isExpanded && "Less"}
            </span>
            <Collapse isOpen={this.state.isExpanded}>
              <>{extra}</>
            </Collapse>
          </>
        )}
      </>
    );
  }
}
