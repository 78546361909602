import { useStores } from "../../store/useStore";
import { Container } from "reactstrap";
import { observer } from "mobx-react";
import Jumbotron from "reactstrap/lib/Jumbotron";
import useDisableScrolling from "../../hooks/useDisableScrolling";

const AccountBlockedMessage = observer(() => {
  const { accountStore } = useStores();

  // Disable scrolling underneath
  const setContainerRef = useDisableScrolling();

  if (!accountStore.account?.blocked) {
    return null;
  }
  return (
    <div className="full-screen-message col" ref={setContainerRef}>
      <Jumbotron className="h-100">
        <Container className="text-center">
          <h3 className="mt-3">Account Suspended</h3>
          <div className="mt-3 lead">
            Your account has been suspended. <br />
            If you believe this to be in error, please{" "}
            <a href="mailto:support@encharge.io">contact us</a>.
          </div>
        </Container>
      </Jumbotron>
    </div>
  );
});

export default AccountBlockedMessage;
