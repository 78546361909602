import AIWriterGeneratedContent from "components/AIWriter/AIWriterGeneratedContent";
import AIWriterGenerateForm from "components/AIWriter/AIWriterGenerateForm";
import { AIWriterUseCase } from "encharge-domain/definitions/AIContentSuggestion";
import _ from "lodash";
import { observer } from "mobx-react";
import { useState } from "react";
import { Container } from "reactstrap";

interface Props {
  onInsert?: (text: string) => void;
}

const AIWriter = (_props: Props) => {
  const [selectedGenerateOption, setSelectedGenerateOption] = useState<
    AIWriterUseCase
  >("writeEmail");
  return (
    <Container className="pt-5 px-5 ai-writer h-100 overflow-auto">
      <AIWriterGenerateForm
        selectedUseCase={selectedGenerateOption}
        setSelectedUseCase={setSelectedGenerateOption}
      />
      <AIWriterGeneratedContent selectedUseCase={selectedGenerateOption} />
    </Container>
  );
};
export default observer(AIWriter);
