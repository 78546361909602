import classNames from "classnames";
import { Col } from "reactstrap";

interface Props {
  testimonialId?: number;
  halfPage?: boolean;
}

const LoginHeroSection = (props: Props) => {
  const testimonials = [
    {
      text: `I have tested a lot of options and Encharge is by far the most powerful, easy to use and affordable marketing automation software.`,
      name: "Gabriel Garavit",
      title: "CEO & Founder, Crowdsquad",
      image: "gabriel.jpeg",
    },
    {
      text: `I can assure you that Encharge is like having two full-time interns. This makes me focus 100% on analyzing the data and growing the marketing area much faster.`,
      name: "Xavier Ribas",
      title: "Marketing Operations Analyst, Landbot",
      image: "xavi.jpg",
    },
    {
      text: `Since I started using Encharge, I have seen dramatic improvement in my business, specially after incorporating the automation features.`,
      name: "Mustafa Mohsen",
      title: "International Society of Neuro-Semantics",
      image: "mustafa.jpeg",
    },
    {
      text: `Where has Encharge been all my life? I cannot recommend it highly enough, it has saved my business loads of time. We now have a fantastic email marketing strategy for our business, thanks to the amazing Encharge.`,
      name: "Tony Hill",
      title: "CEO/Founder | Trill Marketing",
      image: "tony.jpeg",
    },
    {
      text: `Do yourself a favor and test Encharge. The most powerful feature for any SaaS - you can send your most important events to encharge, not just your users, but their actions inside your software and then you can create very specific segments of people.`,
      name: "Victor Zertuche",
      title: "CEO, SMS Masivos",
      image: "victor.jpeg",
    },
    {
      text: `This software is Amazing! I was able to get my entire list moved over in minutes and setting up my flows has been straight forward and easy.`,
      name: "Naomi Nakashima",
      title: "Founder, Freelance Writing Success Summit",
      image: "naomi.jpeg",
    },
  ];

  const testimonialId = props.testimonialId || 0;
  const testimonial = testimonials[testimonialId] || testimonials[0];
  return (
    <Col
      xs="24"
      sm="24"
      md="24"
      lg={props.halfPage ? "12" : "8"}
      className="half-page-block-register d-none d-lg-flex flex-column justify-content-center align-items-center m-0 p-0"
    >
      <div className="register-testimonial-text">"{testimonial.text}" </div>
      <div
        className={classNames(
          "register-testimonial-photo",
          "angel",
          testimonial.image === "victor.jpeg" && "small"
        )}
        style={{
          backgroundImage: `url("${process.env.PUBLIC_URL}/assets/img/testimonials/${testimonial.image}")`,
        }}
      />
      <div className="register-testimonial-name">
        <div className="register-testimonial-name-only">{testimonial.name}</div>{" "}
        <div className="register-testimonial-title">{testimonial.title}</div>
      </div>
    </Col>
  );
};
export default LoginHeroSection;
