import * as coreHttp from "@azure/core-http";
import * as Parameters from "./models/parameters";
import * as Mappers from "./models/mappers";
import { EnchargeAPIContext } from "./enchargeAPIContext";
import {
  EnchargeAPIOptionalParams,
  EnchargeAPIGetContentSuggestionsResponse,
  AIContentSuggestionGenerateParameters,
  EnchargeAPIGenerateContentSuggestionsResponse,
  EnchargeAPIGetCommunicationCategoriesResponse,
  PickIcommunicationCategoryNameOrDescription,
  EnchargeAPICreateCommunicationCategoryResponse,
  EnchargeAPIGetCommunicationCategoriesPeopleCountsResponse,
  PickIcommunicationCategoryNameOrDescriptionOrIsArchived,
  EnchargeAPIEditCommunicationCategoryResponse,
  EnchargeAPIGetCommunicationPreferencesDataResponse,
  CommunicationCategoryOptInValues,
  EnchargeAPIGetEmailVerificationsResponse,
  Paths1Aso2SlSettingsEmailAddressesPostRequestbodyContentApplicationJsonSchema,
  EnchargeAPICreateEmailVerificationResponse,
  EnchargeAPIPerformEmailVerificationsResponse,
  EnchargeAPIGetAccountTagsResponse,
  Paths5Gr823TagsManagementPostRequestbodyContentApplicationJsonSchema,
  EnchargeAPICreateAccountTagsResponse,
  EnchargeAPIGetTagCountsResponse,
  Tag,
  EnchargeAPIUpdateAccountTagResponse,
  EnchargeAPIGetTeamMembersResponse,
  Paths1Yfp89NTeamMembersPostRequestbodyContentApplicationJsonSchema,
  EnchargeAPICreateTeamMemberResponse,
  Paths1P1Ub3PTeamMembersIdPatchRequestbodyContentApplicationJsonSchema,
  EnchargeAPIUpdateTeamMemberResponse,
  EnchargeAPIGetTeamMemberAccountsResponse,
  PathsNkvme4TeamMembersAccountsPostRequestbodyContentApplicationJsonSchema,
  EnchargeAPICreateAccountForTeamMemberResponse
} from "./models";

export class EnchargeAPI extends EnchargeAPIContext {
  /**
   * Initializes a new instance of the EnchargeAPI class.
   * @param credentials Subscription credentials which uniquely identify client subscription.
   * @param options The parameter options
   */
  constructor(
    credentials: coreHttp.TokenCredential | coreHttp.ServiceClientCredentials,
    options?: EnchargeAPIOptionalParams
  ) {
    super(credentials, options);
  }

  /**
   * Get existing content suggestions
   * @param options The options parameters.
   */
  getContentSuggestions(
    options?: coreHttp.OperationOptions
  ): Promise<EnchargeAPIGetContentSuggestionsResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { options: operationOptions },
      getContentSuggestionsOperationSpec
    ) as Promise<EnchargeAPIGetContentSuggestionsResponse>;
  }

  /**
   * Generate content suggestions
   * @param body
   * @param options The options parameters.
   */
  generateContentSuggestions(
    body: AIContentSuggestionGenerateParameters,
    options?: coreHttp.OperationOptions
  ): Promise<EnchargeAPIGenerateContentSuggestionsResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { body, options: operationOptions },
      generateContentSuggestionsOperationSpec
    ) as Promise<EnchargeAPIGenerateContentSuggestionsResponse>;
  }

  /**
   * Get all communication categories.
   * @param options The options parameters.
   */
  getCommunicationCategories(
    options?: coreHttp.OperationOptions
  ): Promise<EnchargeAPIGetCommunicationCategoriesResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { options: operationOptions },
      getCommunicationCategoriesOperationSpec
    ) as Promise<EnchargeAPIGetCommunicationCategoriesResponse>;
  }

  /**
   * Create Communication Category
   * @param body From T, pick a set of properties whose keys are in the union K
   * @param options The options parameters.
   */
  createCommunicationCategory(
    body: PickIcommunicationCategoryNameOrDescription,
    options?: coreHttp.OperationOptions
  ): Promise<EnchargeAPICreateCommunicationCategoryResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { body, options: operationOptions },
      createCommunicationCategoryOperationSpec
    ) as Promise<EnchargeAPICreateCommunicationCategoryResponse>;
  }

  /**
   * Get people counts in communication categories.
   * @param options The options parameters.
   */
  getCommunicationCategoriesPeopleCounts(
    options?: coreHttp.OperationOptions
  ): Promise<EnchargeAPIGetCommunicationCategoriesPeopleCountsResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { options: operationOptions },
      getCommunicationCategoriesPeopleCountsOperationSpec
    ) as Promise<EnchargeAPIGetCommunicationCategoriesPeopleCountsResponse>;
  }

  /**
   * Modify Communication Category
   * @param id
   * @param body From T, pick a set of properties whose keys are in the union K
   * @param options The options parameters.
   */
  editCommunicationCategory(
    id: number,
    body: PickIcommunicationCategoryNameOrDescriptionOrIsArchived,
    options?: coreHttp.OperationOptions
  ): Promise<EnchargeAPIEditCommunicationCategoryResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { id, body, options: operationOptions },
      editCommunicationCategoryOperationSpec
    ) as Promise<EnchargeAPIEditCommunicationCategoryResponse>;
  }

  /**
   * Get communication preferences data.
   * @param token
   * @param options The options parameters.
   */
  getCommunicationPreferencesData(
    token: string,
    options?: coreHttp.OperationOptions
  ): Promise<EnchargeAPIGetCommunicationPreferencesDataResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { token, options: operationOptions },
      getCommunicationPreferencesDataOperationSpec
    ) as Promise<EnchargeAPIGetCommunicationPreferencesDataResponse>;
  }

  /**
   * Save communication preferences for user.
   * @param token
   * @param body Dictionary of <CommunicationCategoryOptInValues>
   * @param options The options parameters.
   */
  saveCommunicationPreferences(
    token: string,
    body: { [propertyName: string]: CommunicationCategoryOptInValues },
    options?: coreHttp.OperationOptions
  ): Promise<coreHttp.RestResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { token, body, options: operationOptions },
      saveCommunicationPreferencesOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Unsubscribe user from all communications.
   * @param token
   * @param options The options parameters.
   */
  unsubscribeFromAllCommunications(
    token: string,
    options?: coreHttp.OperationOptions
  ): Promise<coreHttp.RestResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { token, options: operationOptions },
      unsubscribeFromAllCommunicationsOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Get email addresses being verified
   * @param options The options parameters.
   */
  getEmailVerifications(
    options?: coreHttp.OperationOptions
  ): Promise<EnchargeAPIGetEmailVerificationsResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { options: operationOptions },
      getEmailVerificationsOperationSpec
    ) as Promise<EnchargeAPIGetEmailVerificationsResponse>;
  }

  /**
   * Start verification of email address.
   * @param body
   * @param options The options parameters.
   */
  createEmailVerification(
    body: Paths1Aso2SlSettingsEmailAddressesPostRequestbodyContentApplicationJsonSchema,
    options?: coreHttp.OperationOptions
  ): Promise<EnchargeAPICreateEmailVerificationResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { body, options: operationOptions },
      createEmailVerificationOperationSpec
    ) as Promise<EnchargeAPICreateEmailVerificationResponse>;
  }

  /**
   * Check the verification status of all email addresses for the given account.
   * @param options The options parameters.
   */
  performEmailVerifications(
    options?: coreHttp.OperationOptions
  ): Promise<EnchargeAPIPerformEmailVerificationsResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { options: operationOptions },
      performEmailVerificationsOperationSpec
    ) as Promise<EnchargeAPIPerformEmailVerificationsResponse>;
  }

  /**
   * Start verification of email address.
   * @param email
   * @param options The options parameters.
   */
  deleteEmailVerification(
    email: string,
    options?: coreHttp.OperationOptions
  ): Promise<coreHttp.RestResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { email, options: operationOptions },
      deleteEmailVerificationOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Get all tags.
   * @param options The options parameters.
   */
  getAccountTags(
    options?: coreHttp.OperationOptions
  ): Promise<EnchargeAPIGetAccountTagsResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { options: operationOptions },
      getAccountTagsOperationSpec
    ) as Promise<EnchargeAPIGetAccountTagsResponse>;
  }

  /**
   * Create new tags.
   * @param body
   * @param options The options parameters.
   */
  createAccountTags(
    body: Paths5Gr823TagsManagementPostRequestbodyContentApplicationJsonSchema,
    options?: coreHttp.OperationOptions
  ): Promise<EnchargeAPICreateAccountTagsResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { body, options: operationOptions },
      createAccountTagsOperationSpec
    ) as Promise<EnchargeAPICreateAccountTagsResponse>;
  }

  /**
   * Get all tags.
   * @param options The options parameters.
   */
  getTagCounts(
    options?: coreHttp.OperationOptions
  ): Promise<EnchargeAPIGetTagCountsResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { options: operationOptions },
      getTagCountsOperationSpec
    ) as Promise<EnchargeAPIGetTagCountsResponse>;
  }

  /**
   * Update tag.
   * @param tag
   * @param body Update data
   * @param options The options parameters.
   */
  updateAccountTag(
    tag: string,
    body: Tag,
    options?: coreHttp.OperationOptions
  ): Promise<EnchargeAPIUpdateAccountTagResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { tag, body, options: operationOptions },
      updateAccountTagOperationSpec
    ) as Promise<EnchargeAPIUpdateAccountTagResponse>;
  }

  /**
   * Remove tag(s) from existing user.
   * @param tag
   * @param options The options parameters.
   */
  deleteAccountTag(
    tag: string,
    options?: coreHttp.OperationOptions
  ): Promise<coreHttp.RestResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { tag, options: operationOptions },
      deleteAccountTagOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Get all team members per account
   * @param options The options parameters.
   */
  getTeamMembers(
    options?: coreHttp.OperationOptions
  ): Promise<EnchargeAPIGetTeamMembersResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { options: operationOptions },
      getTeamMembersOperationSpec
    ) as Promise<EnchargeAPIGetTeamMembersResponse>;
  }

  /**
   * Create new team member
   * @param body
   * @param options The options parameters.
   */
  createTeamMember(
    body: Paths1Yfp89NTeamMembersPostRequestbodyContentApplicationJsonSchema,
    options?: coreHttp.OperationOptions
  ): Promise<EnchargeAPICreateTeamMemberResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { body, options: operationOptions },
      createTeamMemberOperationSpec
    ) as Promise<EnchargeAPICreateTeamMemberResponse>;
  }

  /**
   * Edit new team member
   * @param id Any object
   * @param body
   * @param options The options parameters.
   */
  updateTeamMember(
    id: any,
    body: Paths1P1Ub3PTeamMembersIdPatchRequestbodyContentApplicationJsonSchema,
    options?: coreHttp.OperationOptions
  ): Promise<EnchargeAPIUpdateTeamMemberResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { id, body, options: operationOptions },
      updateTeamMemberOperationSpec
    ) as Promise<EnchargeAPIUpdateTeamMemberResponse>;
  }

  /**
   * Delete team member
   * @param id
   * @param options The options parameters.
   */
  deleteTeamMember(
    id: number,
    options?: coreHttp.OperationOptions
  ): Promise<coreHttp.RestResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { id, options: operationOptions },
      deleteTeamMemberOperationSpec
    ) as Promise<coreHttp.RestResponse>;
  }

  /**
   * Get subaccounts connected to current account (siblings, children or parents).
   * @param options The options parameters.
   */
  getTeamMemberAccounts(
    options?: coreHttp.OperationOptions
  ): Promise<EnchargeAPIGetTeamMemberAccountsResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { options: operationOptions },
      getTeamMemberAccountsOperationSpec
    ) as Promise<EnchargeAPIGetTeamMemberAccountsResponse>;
  }

  /**
   * Create another account for this team member
   * @param body
   * @param options The options parameters.
   */
  createAccountForTeamMember(
    body: PathsNkvme4TeamMembersAccountsPostRequestbodyContentApplicationJsonSchema,
    options?: coreHttp.OperationOptions
  ): Promise<EnchargeAPICreateAccountForTeamMemberResponse> {
    const operationOptions: coreHttp.RequestOptionsBase = coreHttp.operationOptionsToRequestOptionsBase(
      options || {}
    );
    return this.sendOperationRequest(
      { body, options: operationOptions },
      createAccountForTeamMemberOperationSpec
    ) as Promise<EnchargeAPICreateAccountForTeamMemberResponse>;
  }
}
// Operation Specifications

const serializer = new coreHttp.Serializer(Mappers, /* isXml */ false);

const getContentSuggestionsOperationSpec: coreHttp.OperationSpec = {
  path: "/ai-writer",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1O7H36AAiWriterGetResponses200ContentApplicationJsonSchema
    }
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const generateContentSuggestionsOperationSpec: coreHttp.OperationSpec = {
  path: "/ai-writer",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths18I7KqlAiWriterPostResponses200ContentApplicationJsonSchema
    }
  },
  requestBody: Parameters.body,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept1],
  mediaType: "json",
  serializer
};
const getCommunicationCategoriesOperationSpec: coreHttp.OperationSpec = {
  path: "/settings/communication-categories",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths7Kf2KySettingsCommunicationCategoriesGetResponses200ContentApplicationJsonSchema
    }
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const createCommunicationCategoryOperationSpec: coreHttp.OperationSpec = {
  path: "/settings/communication-categories",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsZb8RjpSettingsCommunicationCategoriesPostResponses200ContentApplicationJsonSchema
    }
  },
  requestBody: Parameters.body1,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept1],
  mediaType: "json",
  serializer
};
const getCommunicationCategoriesPeopleCountsOperationSpec: coreHttp.OperationSpec = {
  path: "/settings/communication-categories/counts",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1B9Lgf5SettingsCommunicationCategoriesCountsGetResponses200ContentApplicationJsonSchema
    }
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const editCommunicationCategoryOperationSpec: coreHttp.OperationSpec = {
  path: "/settings/communication-categories/{id}",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths17Coml5SettingsCommunicationCategoriesIdPatchResponses200ContentApplicationJsonSchema
    }
  },
  requestBody: Parameters.body2,
  urlParameters: [Parameters.$host, Parameters.id],
  headerParameters: [Parameters.contentType, Parameters.accept1],
  mediaType: "json",
  serializer
};
const getCommunicationPreferencesDataOperationSpec: coreHttp.OperationSpec = {
  path: "/communication-preferences",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths7A3UwnCommunicationPreferencesGetResponses200ContentApplicationJsonSchema
    }
  },
  queryParameters: [Parameters.token],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const saveCommunicationPreferencesOperationSpec: coreHttp.OperationSpec = {
  path: "/communication-preferences",
  httpMethod: "POST",
  responses: { 204: {} },
  requestBody: Parameters.body3,
  queryParameters: [Parameters.token],
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType],
  mediaType: "json",
  serializer
};
const unsubscribeFromAllCommunicationsOperationSpec: coreHttp.OperationSpec = {
  path: "/communication-preferences/all",
  httpMethod: "POST",
  responses: { 204: {} },
  queryParameters: [Parameters.token],
  urlParameters: [Parameters.$host],
  serializer
};
const getEmailVerificationsOperationSpec: coreHttp.OperationSpec = {
  path: "/settings/email/addresses",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsVn8UerSettingsEmailAddressesGetResponses200ContentApplicationJsonSchema
    }
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const createEmailVerificationOperationSpec: coreHttp.OperationSpec = {
  path: "/settings/email/addresses",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1Ehqlq6SettingsEmailAddressesPostResponses200ContentApplicationJsonSchema
    }
  },
  requestBody: Parameters.body4,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept1],
  mediaType: "json",
  serializer
};
const performEmailVerificationsOperationSpec: coreHttp.OperationSpec = {
  path: "/settings/email/addresses/verification",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1Pq701YSettingsEmailAddressesVerificationPostResponses200ContentApplicationJsonSchema
    }
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const deleteEmailVerificationOperationSpec: coreHttp.OperationSpec = {
  path: "/settings/email/addresses/{email}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [Parameters.$host, Parameters.email],
  serializer
};
const getAccountTagsOperationSpec: coreHttp.OperationSpec = {
  path: "/tags-management",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsJh2Cb5TagsManagementGetResponses200ContentApplicationJsonSchema
    }
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const createAccountTagsOperationSpec: coreHttp.OperationSpec = {
  path: "/tags-management",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsQovxsvTagsManagementPostResponses200ContentApplicationJsonSchema
    }
  },
  requestBody: Parameters.body5,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept1],
  mediaType: "json",
  serializer
};
const getTagCountsOperationSpec: coreHttp.OperationSpec = {
  path: "/tags-management/counts",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsSnksa5TagsManagementCountsGetResponses200ContentApplicationJsonSchema
    }
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const updateAccountTagOperationSpec: coreHttp.OperationSpec = {
  path: "/tags-management/{tag}",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsVpki7FTagsManagementTagPatchResponses200ContentApplicationJsonSchema
    }
  },
  requestBody: Parameters.body6,
  urlParameters: [Parameters.$host, Parameters.tag],
  headerParameters: [Parameters.contentType, Parameters.accept1],
  mediaType: "json",
  serializer
};
const deleteAccountTagOperationSpec: coreHttp.OperationSpec = {
  path: "/tags-management/{tag}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [Parameters.$host, Parameters.tag],
  serializer
};
const getTeamMembersOperationSpec: coreHttp.OperationSpec = {
  path: "/team-members",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.PathsH2Wj9HTeamMembersGetResponses200ContentApplicationJsonSchema
    }
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const createTeamMemberOperationSpec: coreHttp.OperationSpec = {
  path: "/team-members",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1P98FvkTeamMembersPostResponses200ContentApplicationJsonSchema
    }
  },
  requestBody: Parameters.body7,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept1],
  mediaType: "json",
  serializer
};
const updateTeamMemberOperationSpec: coreHttp.OperationSpec = {
  path: "/team-members/{id}",
  httpMethod: "PATCH",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1809Np1TeamMembersIdPatchResponses200ContentApplicationJsonSchema
    }
  },
  requestBody: Parameters.body8,
  urlParameters: [Parameters.$host, Parameters.id1],
  headerParameters: [Parameters.contentType, Parameters.accept1],
  mediaType: "json",
  serializer
};
const deleteTeamMemberOperationSpec: coreHttp.OperationSpec = {
  path: "/team-members/{id}",
  httpMethod: "DELETE",
  responses: { 204: {} },
  urlParameters: [Parameters.$host, Parameters.id],
  serializer
};
const getTeamMemberAccountsOperationSpec: coreHttp.OperationSpec = {
  path: "/team-members/accounts",
  httpMethod: "GET",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths1XngsapTeamMembersAccountsGetResponses200ContentApplicationJsonSchema
    }
  },
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.accept],
  serializer
};
const createAccountForTeamMemberOperationSpec: coreHttp.OperationSpec = {
  path: "/team-members/accounts",
  httpMethod: "POST",
  responses: {
    200: {
      bodyMapper:
        Mappers.Paths13Rl502TeamMembersAccountsPostResponses200ContentApplicationJsonSchema
    }
  },
  requestBody: Parameters.body9,
  urlParameters: [Parameters.$host],
  headerParameters: [Parameters.contentType, Parameters.accept1],
  mediaType: "json",
  serializer
};
