import _ from "lodash";
import { observer } from "mobx-react";
import { getAccountName } from "components/AppNav/Account/AccountName";
import AccountLogoView from "components/AppNav/Account/AccountLogoView";
import { FrontendAccount } from "store/accountStore";
import {
  formatAsReactSelectOptions,
  reactSelectCommonProps,
} from "components/ReactSelect/reactSelectCommon";
import Select from "react-select";

interface Props {
  accounts: FrontendAccount["allAccounts"];
  selectedAccount?: IAccount["id"];
  onChange: (id: IAccount["id"]) => void;
}

/**
 * Render dropdown to select an account
 */
const AccountPickerSelect = observer((props: Props) => {
  // const { accountStore, uiStore } = useStores();
  const { accounts, selectedAccount } = props;
  if (!accounts) {
    return null;
  }

  const { currentOption, options } = formatAsReactSelectOptions({
    items: accounts,
    currentValue: selectedAccount,
    idField: "id",
    labelField: "name",
    formatLabel: (account) => (
      <div className="d-flex align-items-center my-1">
        {/* Show which account is the current one */}
        <AccountLogoView
          site={account.site}
          name={account.name}
          logo={account.logo}
          linkToHome={false}
        />
        <span>{getAccountName(account)}</span>
      </div>
    ),
  });
  return (
    <Select
      {...reactSelectCommonProps}
      options={options}
      value={currentOption}
      onChange={(option) => props.onChange((option as any)?.value)}
      getOptionValue={(option) => (option as any)?.labelText || option?.label}
    />
  );
});

export default AccountPickerSelect;
