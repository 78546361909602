import AIWriterGeneratedContentSingle from "components/AIWriter/AIWriterGeneratedContentSingle";
import { AIWriterUseCase } from "encharge-domain/definitions/AIContentSuggestion";
import _ from "lodash";
import { observer } from "mobx-react";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import { useStores } from "store/useStore";

interface Props {
  selectedUseCase: AIWriterUseCase;
}

const AIWriterGeneratedContent = (props: Props) => {
  const { aiWriterStore } = useStores();

  const filteredSuggestions = _.filter(
    aiWriterStore.contentSuggestions,
    (suggestion) => suggestion.useCase === props.selectedUseCase
  );
  const contentSuggestions = _.map(
    [...filteredSuggestions].reverse(),
    (contentSuggestion) => {
      return (
        <AIWriterGeneratedContentSingle
          key={contentSuggestion.id}
          contentSuggestion={contentSuggestion}
        />
      );
    }
  );
  return (
    <div className="mt-5">
      <ReactCSSTransitionGroup
        className="div"
        transitionName="transition"
        transitionEnter={true}
        transitionEnterTimeout={300}
        transitionLeave={false}
        transitionLeaveTimeout={300}
        transitionAppear={true}
        transitionAppearTimeout={300}
      >
        {contentSuggestions}
      </ReactCSSTransitionGroup>
    </div>
  );
};
export default observer(AIWriterGeneratedContent);
