import { Component, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import routes from "../../routes";
import { observer, inject } from "mobx-react";
import { AuthStore } from "../../store/AuthStore";
import TrialExpiredMessage from "../../components/Billing/TrialExpiredMessage";
import TopBar from "components/AppNav/TopBar";
import AccountBlockedMessage from "components/Billing/AccountBlockedMessage";
import { shouldSkipResouces } from "domain/helpers/shouldSkipResouces";
import RegistrationFinishedCheck from "components/Registration/RegistrationFinishedCheck";
import AIWriterDrawer from "components/AIWriter/AIWriterDrawer";

/* tslint:disable:jsx-no-multiline-js */
/* tslint:jsx-no-lambda */

const AuthenticatedRoute = observer(
  ({ component: Component, isAuthenticated, ...rest }: any) => {
    const render = (props: any) => {
      const isAuthOk =
        isAuthenticated ||
        // We dont need to auth on login and register
        shouldSkipResouces();

      const hideTopBar =
        shouldSkipResouces() || props.location.pathname.startsWith("/start");

      return isAuthOk ? (
        <>
          {!hideTopBar && <TopBar />}
          {/* <div className="col position-relative overflow-hidden"> */}
          <div className="page-main">
            <Component {...props} />
          </div>
          {/* </div> */}
        </>
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location },
          }}
          push={true}
        />
      );
    };
    useEffect(() => {
      if (rest.name) {
        document.title = `${rest.name} | Encharge Marketing Automation`;
      }
    }, [rest.name]);

    return <Route {...rest} render={render} />;
  }
);

interface Props {
  authStore?: AuthStore;
}

@inject("authStore")
@observer
class DefaultLayout extends Component<Props> {
  render() {
    const routeComponents = routes.map((route) =>
      route.component ? (
        <AuthenticatedRoute
          isAuthenticated={this.props.authStore!.loggedIn}
          component={route.component}
          key={route.path}
          path={route.path}
          exact={route.exact}
          name={route.name}
          // tslint:disable-next-line:jsx-no-lambda
          // render={props => <route.component {...props} />}
        />
      ) : null
    );
    return (
      <div className="app">
        {/* <AppHeader fixed={true}> */}
        {/* <DefaultHeader /> */}
        {/* </AppHeader> */}
        <div className="app-body">
          {/* <AppSidebar fixed={true} display="hidden"> */}
          {/* <AppSidebarHeader /> */}
          {/* <AppSidebarForm /> */}
          {/* <AppSidebarNav navConfig={navigation} {...this.props} /> */}
          {/* <AppSidebarFooter /> */}
          {/* <AppSidebarMinimizer /> */}
          {/* </AppSidebar> */}
          <main className="main">
            <TrialExpiredMessage />
            <AccountBlockedMessage />
            <RegistrationFinishedCheck />
            <AIWriterDrawer />
            {/* <AppBreadcrumb appRoutes={routes} /> */}
            {/* <Container fluid={true} style={{ minHeight: "80%", height: "80%" }}> */}
            <Switch>
              {routeComponents}
              <Redirect from="/" to="/dashboard" />
            </Switch>
            {/* </Container> */}
          </main>
        </div>
      </div>
    );
  }
}

export default DefaultLayout;
