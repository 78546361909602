import { Button } from "reactstrap";
import { AIContentSuggestion } from "encharge-domain/definitions/AIContentSuggestion";
import TextareaAutosize from "react-textarea-autosize";
import { useStores } from "store/useStore";
import { observer } from "mobx-react";
import _ from "lodash";
import CopyButtonInline from "components/Common/CopyButtonInline";
import { ReactComponent as InsertIcon } from "../../assets/img/insert-icon.svg";
import { ReactComponent as CopyIcon } from "../../assets/img/copy-icon.svg";
import { ReactComponent as MoreLikeThisIcon } from "../../assets/img/more-icon.svg";
import { ReactComponent as HeartIcon } from "../../assets/img/heart-icon.svg";

interface Props {
  contentSuggestion: AIContentSuggestion;
}

const AIWriterGeneratedContentSingle = (props: Props) => {
  const { aiWriterStore } = useStores();
  // const contentSuggestion = _.find(aiWriterStore.contentSuggestions, {
  //   id: props.contentSuggestion.id,
  // });
  // if (!contentSuggestion) {
  //   return null;
  // }
  return (
    <div className="generated-content-single-wrapper mb-1 py-3">
      <TextareaAutosize
        className="generated-content-single-textarea form-control"
        value={props.contentSuggestion.content}
        onChange={(e) => {
          aiWriterStore.updateContentSuggestion(
            props.contentSuggestion.id,
            e.currentTarget.value
          );
        }}
      />
      <div className="mt-4 generated-content-single-actions">
        <Button className="btn-sm btn-custom-outline-dark btn-with-icon-outline mr-2">
          <InsertIcon className="mr-2" width="16" height="16" />
          Insert
        </Button>
        <span className="mr-2">
          <CopyButtonInline
            content={props.contentSuggestion.content}
            buttonText={
              <>
                <CopyIcon className="mr-2" width="16" height="16" />
                Copy
              </>
            }
            size="sm"
          />
        </span>
        <Button className="btn-sm btn-custom-outline-dark btn-with-icon-outline mr-2">
          <MoreLikeThisIcon className="mr-2" width="16" height="16" />
          More like this
        </Button>
        <Button className="btn-sm btn-custom-outline-dark btn-with-icon-outline mr-2">
          <HeartIcon className="mr-2" width="16" height="16" />
          Save
        </Button>
      </div>
    </div>
  );
};
export default observer(AIWriterGeneratedContentSingle);
