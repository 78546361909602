import { Component } from "react";
import { BrowserRouter, Switch } from "react-router-dom";

// Containers
import { DefaultLayout } from "./containers";
import { observer } from "mobx-react";
import ErrorBoundary from "./domain/errorHandling/ErrorBoundary";
import ToastMessagesContainer from "./domain/errorHandling/ToastMessagesContainer";
import RouterToMobx from "RouterToMobx";

@observer
class App extends Component<{}, {}> {
  render() {
    return (
      <>
        <ToastMessagesContainer />
        <ErrorBoundary>
          <BrowserRouter>
            <RouterToMobx>
              <Switch>
                <DefaultLayout />
              </Switch>
            </RouterToMobx>
          </BrowserRouter>
        </ErrorBoundary>
      </>
    );
  }
}

export default App;
