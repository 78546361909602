import request, { AxiosResponse } from "axios";
import { apiBase } from "../../constants";

import { AccountUpdate } from "encharge-domain/lib/definitions/api/AccountUpdate";
import { AccountInfoResponse } from "store/accountStore";

export const getAccount = async () => {
  try {
    const res: AxiosResponse<AccountInfoResponse> = await request({
      url: `${apiBase}/v1/accounts`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve your account. ";
    throw new Error(msg);
  }
};

export const saveServiceCredentials = async (
  serviceId: string,
  credentials: any
) => {
  try {
    const res: AxiosResponse<{ status: string }> = await request({
      url: `${apiBase}/v1/auth/${serviceId}`,
      method: "POST",
      data: credentials,
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't save your credentials. ";
    throw new Error(msg);
  }
};

export const updateAccount = async (accountData: AccountUpdate) => {
  try {
    await request({
      url: `${apiBase}/v1/accounts/me`,
      method: "PATCH",
      data: accountData,
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't save your account changes. ";
    throw new Error(msg);
  }
};

export const switchAccount = async (accountId: IAccount["id"]) => {
  try {
    await request({
      url: `${apiBase}/v1/accounts/${accountId}/switch`,
      method: "POST",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't switch accounts. ";
    throw new Error(msg);
  }
};
export const deleteAccount = async (accountId: IAccount["id"]) => {
  try {
    const res: AxiosResponse<{ result: string }> = await request({
      url: `${apiBase}/v1/accounts/${accountId}`,
      method: "DELETE",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.result;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't switch accounts. ";
    throw new Error(msg);
  }
};

export const uploadLogo = async (logo: File) => {
  try {
    const formData = new FormData();
    formData.append("file", logo);

    const res: AxiosResponse<{ url: string; key: string }> = await request({
      url: `${apiBase}/v1/accounts/logo`,
      method: "POST",
      data: {
        contentType: logo.type,
      },
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });

    const presignedS3Url = res.data.url;
    if (!presignedS3Url) {
      throw new Error("Couldn't get logo upload URL.");
    }

    const axiosResponse = await request({
      url: presignedS3Url.replace(".eu-west-1", ""),
      method: "PUT",
      data: logo,
      headers: {
        "Content-Type": logo.type,
      },
      withCredentials: false,
    });
    // cloudfront for the s3 bucket
    const url = `http://d2qa3j78m9awdn.cloudfront.net/${res.data.key}`;
    return url;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't save the logo. ";
    throw new Error(msg);
  }
};

export const getAccountIssues = async () => {
  try {
    const res: AxiosResponse<{
      issues: { message: string; name: string }[];
    }> = await request({
      url: `${apiBase}/v1/accounts/issues`,
      method: "GET",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.issues;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve account issues. ";
    throw new Error(msg);
  }
};

export const rotateAPIKey = async () => {
  try {
    const res = await request({
      url: `${apiBase}/v1/accounts/rotate-api-key`,
      method: "POST",
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't rotate this API key. ";
    throw new Error(msg);
  }
};
