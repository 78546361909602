import request, { AxiosResponse } from "axios";
import { enchargeSiteTrackingSlug } from "encharge-domain/lib/helpers/constants";

import { apiBase, peopleEventsPageSize } from "../../constants";

export const getLastSiteTrackingEvent = async () => {
  try {
    const res: AxiosResponse<{
      events: IPersonEvent[];
    }> = await request({
      url: `${apiBase}/v1/people/all/events`,
      params: {
        limit: 1,
        source: enchargeSiteTrackingSlug,
        includeEndUsers: false,
      },
      method: "GET",
      withCredentials: true,
    });
    return res.data.events[0];
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error.";
    throw new Error(msg);
  }
};

export const getAllFormEvents = async () => {
  try {
    const res: AxiosResponse<{
      events: IPersonEvent[];
    }> = await request({
      url: `${apiBase}/v1/settings/site-tracking/unique-form-events`,
      method: "GET",
      withCredentials: true,
    });
    return res.data.events;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error.";
    throw new Error(msg);
  }
};

export const getPersonEvents = async (
  userId: string = "all",
  cursor?: string
) => {
  try {
    const res: AxiosResponse<{
      events: IPersonEvent[];
      paging: {
        after?: string;
      };
    }> = await request({
      url: `${apiBase}/v1/people/${userId}/events`,
      method: "GET",
      params: {
        cursor,
        limit: peopleEventsPageSize,
      },
      withCredentials: true,
    });
    return { events: res.data.events, cursor: res.data.paging!.after };
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message || e.message || "Unknown error.";
    throw new Error(msg);
  }
};
