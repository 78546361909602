import * as coreHttp from "@azure/core-http";

export const Paths1O7H36AAiWriterGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1O7H36AAiWriterGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      suggestions: {
        serializedName: "suggestions",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "String"
            }
          }
        }
      }
    }
  }
};

export const AIContentSuggestionGenerateParameters: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AIContentSuggestionGenerateParameters"
  }
};

export const AIContentSuggestionWriteEmail: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AIContentSuggestionWriteEmail",
    modelProperties: {
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Composite",
          className: "AIContentSuggestionWriteEmailParameters"
        }
      },
      useCase: {
        serializedName: "useCase",
        required: true,
        type: {
          name: "Enum",
          allowedValues: ["writeEmail", "subjectLine", "emailOutline"]
        }
      }
    }
  }
};

export const AIContentSuggestionWriteEmailParameters: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AIContentSuggestionWriteEmailParameters",
    modelProperties: {
      emailAbout: {
        serializedName: "emailAbout",
        required: true,
        type: {
          name: "String"
        }
      },
      companyName: {
        serializedName: "companyName",
        required: true,
        type: {
          name: "String"
        }
      },
      tone: {
        serializedName: "tone",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AIContentSuggestionSubjectLine: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AIContentSuggestionSubjectLine",
    modelProperties: {
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Composite",
          className: "AIContentSuggestionSubjectLineParameters"
        }
      },
      useCase: {
        serializedName: "useCase",
        required: true,
        type: {
          name: "Enum",
          allowedValues: ["writeEmail", "subjectLine", "emailOutline"]
        }
      }
    }
  }
};

export const AIContentSuggestionSubjectLineParameters: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AIContentSuggestionSubjectLineParameters",
    modelProperties: {
      emailAbout: {
        serializedName: "emailAbout",
        required: true,
        type: {
          name: "String"
        }
      },
      companyName: {
        serializedName: "companyName",
        required: true,
        type: {
          name: "String"
        }
      },
      tone: {
        serializedName: "tone",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const AIContentSuggestionEmailOutline: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AIContentSuggestionEmailOutline",
    modelProperties: {
      parameters: {
        serializedName: "parameters",
        type: {
          name: "Composite",
          className: "AIContentSuggestionEmailOutlineParameters"
        }
      },
      useCase: {
        serializedName: "useCase",
        required: true,
        type: {
          name: "Enum",
          allowedValues: ["writeEmail", "subjectLine", "emailOutline"]
        }
      }
    }
  }
};

export const AIContentSuggestionEmailOutlineParameters: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AIContentSuggestionEmailOutlineParameters",
    modelProperties: {
      emailAbout: {
        serializedName: "emailAbout",
        required: true,
        type: {
          name: "String"
        }
      },
      companyName: {
        serializedName: "companyName",
        required: true,
        type: {
          name: "String"
        }
      },
      tone: {
        serializedName: "tone",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Paths18I7KqlAiWriterPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths18I7KqlAiWriterPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      suggestions: {
        serializedName: "suggestions",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "AIContentSuggestion"
            }
          }
        }
      }
    }
  }
};

export const AIContentSuggestion: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "AIContentSuggestion",
    modelProperties: {
      useCase: {
        serializedName: "useCase",
        required: true,
        type: {
          name: "Enum",
          allowedValues: ["writeEmail", "emailOutline", "subjectLine"]
        }
      },
      content: {
        serializedName: "content",
        required: true,
        type: {
          name: "String"
        }
      },
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Paths7Kf2KySettingsCommunicationCategoriesGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths7Kf2KySettingsCommunicationCategoriesGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      items: {
        serializedName: "items",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ICommunicationCategory"
            }
          }
        }
      }
    }
  }
};

export const ICommunicationCategory: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ICommunicationCategory",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        required: true,
        type: {
          name: "String"
        }
      },
      type: {
        serializedName: "type",
        required: true,
        type: {
          name: "Enum",
          allowedValues: ["marketing", "transactional"]
        }
      },
      accountId: {
        serializedName: "accountId",
        required: true,
        type: {
          name: "Number"
        }
      },
      isDefault: {
        serializedName: "isDefault",
        type: {
          name: "Boolean"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const PickIcommunicationCategoryNameOrDescription: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PickIcommunicationCategoryNameOrDescription",
    modelProperties: {
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        required: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PathsZb8RjpSettingsCommunicationCategoriesPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsZb8RjpSettingsCommunicationCategoriesPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      item: {
        serializedName: "item",
        type: {
          name: "Composite",
          className: "ICommunicationCategory"
        }
      }
    }
  }
};

export const Paths1B9Lgf5SettingsCommunicationCategoriesCountsGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1B9Lgf5SettingsCommunicationCategoriesCountsGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      items: {
        serializedName: "items",
        required: true,
        type: {
          name: "any"
        }
      }
    }
  }
};

export const PickIcommunicationCategoryNameOrDescriptionOrIsArchived: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "PickIcommunicationCategoryNameOrDescriptionOrIsArchived",
    modelProperties: {
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String"
        }
      },
      description: {
        serializedName: "description",
        required: true,
        type: {
          name: "String"
        }
      },
      isArchived: {
        serializedName: "isArchived",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const Paths17Coml5SettingsCommunicationCategoriesIdPatchResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths17Coml5SettingsCommunicationCategoriesIdPatchResponses200ContentApplicationJsonSchema",
    modelProperties: {
      item: {
        serializedName: "item",
        type: {
          name: "Composite",
          className: "ICommunicationCategory"
        }
      }
    }
  }
};

export const Paths7A3UwnCommunicationPreferencesGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths7A3UwnCommunicationPreferencesGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      categoryId: {
        serializedName: "categoryId",
        required: true,
        type: {
          name: "Number"
        }
      },
      site: {
        serializedName: "site",
        required: true,
        type: {
          name: "String"
        }
      },
      categoriesForPerson: {
        serializedName: "categoriesForPerson",
        required: true,
        type: {
          name: "any"
        }
      },
      categories: {
        serializedName: "categories",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "ICommunicationCategory"
            }
          }
        }
      },
      person: {
        serializedName: "person",
        type: {
          name: "Composite",
          className:
            "PathsRwag2PCommunicationPreferencesGetResponses200ContentApplicationJsonSchemaPropertiesPerson"
        }
      },
      accountId: {
        serializedName: "accountId",
        required: true,
        type: {
          name: "Number"
        }
      }
    }
  }
};

export const PathsRwag2PCommunicationPreferencesGetResponses200ContentApplicationJsonSchemaPropertiesPerson: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsRwag2PCommunicationPreferencesGetResponses200ContentApplicationJsonSchemaPropertiesPerson",
    modelProperties: {
      email: {
        serializedName: "email",
        required: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PathsVn8UerSettingsEmailAddressesGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsVn8UerSettingsEmailAddressesGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      emails: {
        serializedName: "emails",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "IEmailAddressVerification"
            }
          }
        }
      }
    }
  }
};

export const IEmailAddressVerification: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "IEmailAddressVerification",
    modelProperties: {
      email: {
        serializedName: "email",
        required: true,
        type: {
          name: "String"
        }
      },
      status: {
        serializedName: "status",
        required: true,
        type: {
          name: "Enum",
          allowedValues: ["verified", "pending"]
        }
      },
      accountId: {
        serializedName: "accountId",
        required: true,
        type: {
          name: "Number"
        }
      },
      data: {
        serializedName: "data",
        required: true,
        type: {
          name: "any"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      updatedAt: {
        serializedName: "updatedAt",
        type: {
          name: "DateTime"
        }
      }
    }
  }
};

export const Paths1Aso2SlSettingsEmailAddressesPostRequestbodyContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1Aso2SlSettingsEmailAddressesPostRequestbodyContentApplicationJsonSchema",
    modelProperties: {
      email: {
        serializedName: "email",
        required: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Paths1Ehqlq6SettingsEmailAddressesPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1Ehqlq6SettingsEmailAddressesPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      email: {
        serializedName: "email",
        type: {
          name: "Composite",
          className: "IEmailAddressVerification"
        }
      }
    }
  }
};

export const Paths1Pq701YSettingsEmailAddressesVerificationPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1Pq701YSettingsEmailAddressesVerificationPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      emails: {
        serializedName: "emails",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "IEmailAddressVerification"
            }
          }
        }
      }
    }
  }
};

export const PathsJh2Cb5TagsManagementGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsJh2Cb5TagsManagementGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      tags: {
        serializedName: "tags",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Tag"
            }
          }
        }
      }
    }
  }
};

export const Tag: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "Tag",
    modelProperties: {
      count: {
        serializedName: "count",
        type: {
          name: "Number"
        }
      },
      createdAt: {
        serializedName: "createdAt",
        type: {
          name: "DateTime"
        }
      },
      description: {
        serializedName: "description",
        type: {
          name: "String"
        }
      },
      tag: {
        serializedName: "tag",
        required: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Paths5Gr823TagsManagementPostRequestbodyContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths5Gr823TagsManagementPostRequestbodyContentApplicationJsonSchema",
    modelProperties: {
      tags: {
        serializedName: "tags",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Tag"
            }
          }
        }
      }
    }
  }
};

export const PathsQovxsvTagsManagementPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsQovxsvTagsManagementPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      tags: {
        serializedName: "tags",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "Tag"
            }
          }
        }
      }
    }
  }
};

export const PathsSnksa5TagsManagementCountsGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsSnksa5TagsManagementCountsGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      tagCounts: {
        serializedName: "tagCounts",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className:
                "Paths69Ldh4TagsManagementCountsGetResponses200ContentApplicationJsonSchemaPropertiesTagcountsItems"
            }
          }
        }
      }
    }
  }
};

export const Paths69Ldh4TagsManagementCountsGetResponses200ContentApplicationJsonSchemaPropertiesTagcountsItems: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths69Ldh4TagsManagementCountsGetResponses200ContentApplicationJsonSchemaPropertiesTagcountsItems",
    modelProperties: {
      count: {
        serializedName: "count",
        required: true,
        type: {
          name: "Number"
        }
      },
      tag: {
        serializedName: "tag",
        required: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const PathsVpki7FTagsManagementTagPatchResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsVpki7FTagsManagementTagPatchResponses200ContentApplicationJsonSchema",
    modelProperties: {
      tag: {
        serializedName: "tag",
        type: {
          name: "Composite",
          className: "Tag"
        }
      }
    }
  }
};

export const PathsH2Wj9HTeamMembersGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsH2Wj9HTeamMembersGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      teamMembers: {
        serializedName: "teamMembers",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "any"
            }
          }
        }
      }
    }
  }
};

export const Paths1Yfp89NTeamMembersPostRequestbodyContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1Yfp89NTeamMembersPostRequestbodyContentApplicationJsonSchema",
    modelProperties: {
      scopes: {
        serializedName: "scopes",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TeamMemberScope"
            }
          }
        }
      },
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        required: true,
        type: {
          name: "String"
        }
      }
    }
  }
};

export const TeamMemberScope: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "TeamMemberScope",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Enum",
          allowedValues: [
            "emails:read",
            "emails:write",
            "people:read",
            "people:write",
            "people:export",
            "flows:read",
            "flows:write",
            "broadcasts:read",
            "broadcasts:write",
            "emailMetrics:read",
            "team:read",
            "team:write",
            "emailSettings:write",
            "apps:write",
            "personFields:write",
            "account:write",
            "apiKeys:read",
            "apiKeys:write",
            "billing:read",
            "billing:write",
            "subaccounts:write"
          ]
        }
      },
      enabled: {
        serializedName: "enabled",
        required: true,
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const Paths1P98FvkTeamMembersPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1P98FvkTeamMembersPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      teamMember: {
        serializedName: "teamMember",
        type: {
          name: "Composite",
          className: "ITeamMember"
        }
      }
    }
  }
};

export const ITeamMember: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ITeamMember",
    modelProperties: {
      id: {
        serializedName: "id",
        required: true,
        type: {
          name: "Number"
        }
      },
      name: {
        serializedName: "name",
        required: true,
        type: {
          name: "String"
        }
      },
      email: {
        serializedName: "email",
        required: true,
        type: {
          name: "String"
        }
      },
      accountId: {
        serializedName: "accountId",
        required: true,
        type: {
          name: "Number"
        }
      },
      data: {
        serializedName: "data",
        type: {
          name: "Composite",
          className: "ITeamMemberData"
        }
      },
      accountData: {
        serializedName: "accountData",
        type: {
          name: "Composite",
          className: "ITeamMemberAccountData"
        }
      }
    }
  }
};

export const ITeamMemberData: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ITeamMemberData",
    modelProperties: {
      createdWithGoogleSignin: {
        serializedName: "createdWithGoogleSignin",
        type: {
          name: "Boolean"
        }
      }
    }
  }
};

export const ITeamMemberAccountData: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className: "ITeamMemberAccountData",
    modelProperties: {
      isOwner: {
        serializedName: "isOwner",
        type: {
          name: "Boolean"
        }
      },
      scopes: {
        serializedName: "scopes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TeamMemberScope"
            }
          }
        }
      }
    }
  }
};

export const Paths1P1Ub3PTeamMembersIdPatchRequestbodyContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1P1Ub3PTeamMembersIdPatchRequestbodyContentApplicationJsonSchema",
    modelProperties: {
      scopes: {
        serializedName: "scopes",
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "Composite",
              className: "TeamMemberScope"
            }
          }
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Paths1809Np1TeamMembersIdPatchResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1809Np1TeamMembersIdPatchResponses200ContentApplicationJsonSchema",
    modelProperties: {
      teamMember: {
        serializedName: "teamMember",
        type: {
          name: "Composite",
          className: "ITeamMember"
        }
      }
    }
  }
};

export const Paths1XngsapTeamMembersAccountsGetResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths1XngsapTeamMembersAccountsGetResponses200ContentApplicationJsonSchema",
    modelProperties: {
      accounts: {
        serializedName: "accounts",
        required: true,
        type: {
          name: "Sequence",
          element: {
            type: {
              name: "any"
            }
          }
        }
      }
    }
  }
};

export const PathsNkvme4TeamMembersAccountsPostRequestbodyContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "PathsNkvme4TeamMembersAccountsPostRequestbodyContentApplicationJsonSchema",
    modelProperties: {
      timezone: {
        serializedName: "timezone",
        type: {
          name: "String"
        }
      },
      industry: {
        serializedName: "industry",
        type: {
          name: "String"
        }
      },
      site: {
        serializedName: "site",
        type: {
          name: "String"
        }
      },
      name: {
        serializedName: "name",
        type: {
          name: "String"
        }
      }
    }
  }
};

export const Paths13Rl502TeamMembersAccountsPostResponses200ContentApplicationJsonSchema: coreHttp.CompositeMapper = {
  type: {
    name: "Composite",
    className:
      "Paths13Rl502TeamMembersAccountsPostResponses200ContentApplicationJsonSchema",
    modelProperties: {
      subaccount: {
        serializedName: "subaccount",
        required: true,
        type: {
          name: "any"
        }
      }
    }
  }
};
