// Dynamic Form Width

/* eslint-disable */
$.fn.textWidth = function (text, font) {
  if (!$.fn.textWidth.fakeEl)
    $.fn.textWidth.fakeEl = $("<span>").hide().appendTo(document.body);

  $.fn.textWidth.fakeEl
    .text(text || this.val() || this.text() || this.attr("placeholder"))
    .css("font", font || this.css("font"));

  const width = $.fn.textWidth.fakeEl.width();
  $.fn.textWidth.fakeEl.text("");
  return width;
};
