import {
  OperationParameter,
  OperationURLParameter,
  OperationQueryParameter
} from "@azure/core-http";
import {
  AIContentSuggestionGenerateParameters as AIContentSuggestionGenerateParametersMapper,
  PickIcommunicationCategoryNameOrDescription as PickIcommunicationCategoryNameOrDescriptionMapper,
  PickIcommunicationCategoryNameOrDescriptionOrIsArchived as PickIcommunicationCategoryNameOrDescriptionOrIsArchivedMapper,
  Paths1Aso2SlSettingsEmailAddressesPostRequestbodyContentApplicationJsonSchema as Paths1Aso2SlSettingsEmailAddressesPostRequestbodyContentApplicationJsonSchemaMapper,
  Paths5Gr823TagsManagementPostRequestbodyContentApplicationJsonSchema as Paths5Gr823TagsManagementPostRequestbodyContentApplicationJsonSchemaMapper,
  Tag as TagMapper,
  Paths1Yfp89NTeamMembersPostRequestbodyContentApplicationJsonSchema as Paths1Yfp89NTeamMembersPostRequestbodyContentApplicationJsonSchemaMapper,
  Paths1P1Ub3PTeamMembersIdPatchRequestbodyContentApplicationJsonSchema as Paths1P1Ub3PTeamMembersIdPatchRequestbodyContentApplicationJsonSchemaMapper,
  PathsNkvme4TeamMembersAccountsPostRequestbodyContentApplicationJsonSchema as PathsNkvme4TeamMembersAccountsPostRequestbodyContentApplicationJsonSchemaMapper
} from "../models/mappers";

export const accept: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "application/json",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String"
    }
  }
};

export const $host: OperationURLParameter = {
  parameterPath: "$host",
  mapper: {
    serializedName: "$host",
    required: true,
    type: {
      name: "String"
    }
  },
  skipEncoding: true
};

export const contentType: OperationParameter = {
  parameterPath: ["options", "contentType"],
  mapper: {
    defaultValue: "application/json",
    isConstant: true,
    serializedName: "Content-Type",
    type: {
      name: "String"
    }
  }
};

export const body: OperationParameter = {
  parameterPath: "body",
  mapper: AIContentSuggestionGenerateParametersMapper
};

export const accept1: OperationParameter = {
  parameterPath: "accept",
  mapper: {
    defaultValue: "application/json",
    isConstant: true,
    serializedName: "Accept",
    type: {
      name: "String"
    }
  }
};

export const body1: OperationParameter = {
  parameterPath: "body",
  mapper: PickIcommunicationCategoryNameOrDescriptionMapper
};

export const body2: OperationParameter = {
  parameterPath: "body",
  mapper: PickIcommunicationCategoryNameOrDescriptionOrIsArchivedMapper
};

export const id: OperationURLParameter = {
  parameterPath: "id",
  mapper: {
    serializedName: "id",
    required: true,
    type: {
      name: "Number"
    }
  }
};

export const token: OperationQueryParameter = {
  parameterPath: "token",
  mapper: {
    serializedName: "token",
    required: true,
    type: {
      name: "String"
    }
  }
};

export const body3: OperationParameter = {
  parameterPath: "body",
  mapper: {
    serializedName: "body",
    required: true,
    type: {
      name: "Dictionary",
      value: {
        type: {
          name: "Enum",
          allowedValues: ["optIn", "optOut", "unspecified"]
        }
      }
    }
  }
};

export const body4: OperationParameter = {
  parameterPath: "body",
  mapper: Paths1Aso2SlSettingsEmailAddressesPostRequestbodyContentApplicationJsonSchemaMapper
};

export const email: OperationURLParameter = {
  parameterPath: "email",
  mapper: {
    serializedName: "email",
    required: true,
    type: {
      name: "String"
    }
  }
};

export const body5: OperationParameter = {
  parameterPath: "body",
  mapper: Paths5Gr823TagsManagementPostRequestbodyContentApplicationJsonSchemaMapper
};

export const body6: OperationParameter = {
  parameterPath: "body",
  mapper: TagMapper
};

export const tag: OperationURLParameter = {
  parameterPath: "tag",
  mapper: {
    serializedName: "tag",
    required: true,
    type: {
      name: "String"
    }
  }
};

export const body7: OperationParameter = {
  parameterPath: "body",
  mapper: Paths1Yfp89NTeamMembersPostRequestbodyContentApplicationJsonSchemaMapper
};

export const body8: OperationParameter = {
  parameterPath: "body",
  mapper: Paths1P1Ub3PTeamMembersIdPatchRequestbodyContentApplicationJsonSchemaMapper
};

export const id1: OperationURLParameter = {
  parameterPath: "id",
  mapper: {
    serializedName: "id",
    required: true,
    type: {
      name: "any"
    }
  }
};

export const body9: OperationParameter = {
  parameterPath: "body",
  mapper: PathsNkvme4TeamMembersAccountsPostRequestbodyContentApplicationJsonSchemaMapper
};
