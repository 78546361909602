import { useCallback, useState } from "react";
import { Input } from "reactstrap";

import { observer } from "mobx-react";
import RegistrationStepFormCommon from "components/Registration/RegistrationStepFormCommon";

interface Props {
  submit: (formData: any) => void;
  loading?: boolean;
  error?: string;

  name: string;
}
const RegistrationStepPassword = (props: Props) => {
  const [formData, setFormData] = useState({
    password: "",
  });

  const handleFieldChange = (e: React.FormEvent) => {
    /// @ts-ignore - e.target.name is not defined on state
    setFormData({ ...formData, [e.target.name]: e?.target?.value || "" });
  };
  const submitProp = props.submit;
  const onSubmit = useCallback(() => {
    submitProp(formData);
  }, [submitProp, formData]);

  const heading = `Last step, we promise! Choose your password.`;
  const subheading = "You are almost there!";
  const subsubheading = "";

  return (
    <>
      <RegistrationStepFormCommon
        heading={heading}
        subheading={subheading}
        subsubheading={subsubheading}
        buttonText="Take me to my account"
        onSubmit={onSubmit}
        loading={props.loading}
        error={props.error}
        disabled={!formData.password}
      >
        <Input
          value={formData.password}
          type="password"
          placeholder="Password"
          autoComplete="password"
          name="password"
          className="form-control-lg mb-3 mt-2"
          required={true}
          onChange={handleFieldChange}
        />
      </RegistrationStepFormCommon>
      <div className="small mt-3">
        By clicking on the button above, I agree to the{" "}
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://encharge.io/legal/privacy.html"
          className="mx-1"
        >
          Privacy Policy
        </a>
        and the
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://encharge.io/legal/tos.html"
          className="ml-1"
        >
          Terms of Service
        </a>
        .
      </div>
    </>
  );
};

export default observer(RegistrationStepPassword);
