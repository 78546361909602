import { action, observable } from "mobx";
import _ from "lodash";
import { DomainStore } from "./domainStore";
import { toastError, toastSuccess } from "../domain/errorHandling/toaster";
import { lazyObservable } from "../domain/helpers/lazyLoad";
import enchargeAPI from "./persistence/enchargeAPI";

import { getQuery } from "domain/helpers/query";
import { EnchargeAPIGetCommunicationPreferencesDataResponse } from "store/persistence/api_client/generated/src";
import { CommunicationCategoriesPreferences } from "encharge-domain/definitions/CommunicationCategory";

export class CommunicationPreferencesStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
  }

  @observable
  preferencesData = lazyObservable<CommunicationPreferencesAPIResponse>(
    (sink, onError) => {
      // clear any errors
      this.rootStore.uiStore.communicationPreferences.setError();
      enchargeAPI
        .getCommunicationPreferencesData(getQuery()?.["t"])
        .then((preferences) => {
          const prefsLocal = _.omit(preferences, ["_response"]);
          prefsLocal.categoriesForPerson = _.mapKeys(
            preferences.categoriesForPerson,
            (_val, key) => Number(key)
          );
          sink(preferences);
        })
        .catch((e) => {
          // use this to display the error to the user
          this.rootStore.uiStore.communicationPreferences.setError(
            e?.details?.error?.message || e?.message
          );
          onError(e);
        });
    }
  );

  @action
  async updateCommunicationPreferences(
    personCategories: CommunicationCategoriesPreferences
  ) {
    this.rootStore.uiStore.communicationPreferencesLoading.startLoading();
    const token = getQuery()?.["t"];
    try {
      await enchargeAPI.saveCommunicationPreferences(token, personCategories);
      toastSuccess("Preferences updated.");
    } catch (e) {
      toastError({
        message: "Error while updating communication preferences.",
        extra: e,
      });
    } finally {
      this.rootStore.uiStore.communicationPreferencesLoading.finishLoading();
    }
  }

  @action
  async unsubscribeFromAllCommunications() {
    this.rootStore.uiStore.communicationPreferencesLoading.startLoading();
    const token = getQuery()?.["t"];
    try {
      await enchargeAPI.unsubscribeFromAllCommunications(token);
      toastSuccess("Preferences updated.");
    } catch (e) {
      toastError({
        message: "Error while updating communication preferences.",
        extra: e,
      });
    } finally {
      this.rootStore.uiStore.communicationPreferencesLoading.finishLoading();
    }
  }
}

export interface CommunicationPreferencesAPIResponse
  extends Omit<
    EnchargeAPIGetCommunicationPreferencesDataResponse,
    "_response" | "categoriesForPerson"
  > {
  categoriesForPerson: CommunicationCategoriesPreferences;
}
