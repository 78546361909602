import { useCallback, useRef, useState } from "react";
import _ from "lodash";
import { ReactComponent as WarningIcon } from "../../assets/img/warning-icon.svg";
import {
  Button,
  Modal,
  UncontrolledTooltip,
  ModalHeader,
  ModalFooter,
  ModalBody,
} from "reactstrap";
import { useStores } from "store/useStore";
import { observer } from "mobx-react";

const AccountWarnings = () => {
  const iconRef = useRef<any>();
  const { accountStore } = useStores();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const toggleDialog = useCallback(() => {
    setIsDialogOpen(!isDialogOpen);
  }, [isDialogOpen, setIsDialogOpen]);

  const account = accountStore.account;
  if (!account) return null;

  // Retrieve issues for this account
  const issues = accountStore.accountIssues.current();
  if (accountStore.accountIssues.isLoading()) {
    return null;
  }
  const hasWarnings = issues?.length > 0;
  if (!hasWarnings) {
    return null;
  }

  const isTrial = account.status === "trial";
  const warning = isTrial ? (
    <div>
      Due to unusual activity on your account, we have temporarily disabled
      email sending. <br />
      Someone will be in touch with you shortly to resolve the issue.
      <br />
      If you need immediate assistance, you can{" "}
      <a href="mailto:support@encharge.io">write to us</a>.
    </div>
  ) : (
    <div>
      <p>
        Due to unusual activity on your account, we have temporarily disabled
        email sending.
      </p>
      <p>Our team will review it manually. </p>
      <p>
        Please note that your email is currently paused but it should go out
        shortly.
      </p>
      <p>
        If you need immediate assistance, you can{" "}
        <a href="mailto:support@encharge.io">write to us</a>.
      </p>
    </div>
  );
  return (
    <>
      <div className="account-warnings-container mr-3">
        <span className="cursor-pointer warning-active" onClick={toggleDialog}>
          <WarningIcon ref={iconRef} />
        </span>
        {iconRef.current && (
          <UncontrolledTooltip target={iconRef.current}>
            There are issues with your account. <br />
            Click for more information.
          </UncontrolledTooltip>
        )}
        <Modal
          isOpen={isDialogOpen}
          toggle={toggleDialog}
          zIndex={1400}
          size={"lg"}
        >
          <ModalHeader>Pending Account Issues</ModalHeader>
          <ModalBody>{hasWarnings && warning}</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggleDialog}>
              Close
            </Button>{" "}
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};
export default observer(AccountWarnings);
