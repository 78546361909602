import { useEffect, useState } from "react";
import { Button, Modal } from "reactstrap";
import { observer } from "mobx-react";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";
import { useStores } from "store/useStore";
import Loading from "components/Loading";
import ButtonLoader from "components/ButtonLoader";
import { loadChargebee } from "components/Billing/loadChargebee";
import {
  activePlans,
  landbotPlans,
  appSumoPlans,
} from "encharge-domain/lib/values/plans";
interface Props {
  email: string;
  planName?: string;
  planPeriod?: string;
  planPeopleUpperLimit?: number;
  onCancel: () => void;
  confirmButtonText?: string;
}

const ChargebeeCheckout = observer((props: Props) => {
  const { accountStore } = useStores();
  const confirmButtonText = props.confirmButtonText || "Upgrade to this plan";
  const peopleUpperLimit = props.planPeopleUpperLimit || 0;

  // checks if script is loaded
  const [chargebeeLoaded, setChargebeeLoaded] = useState(
    Boolean(document.getElementById("chargebee-script"))
  );
  useEffect(() => {
    // allow us to checkout
    const onload = () => setChargebeeLoaded(true);
    loadChargebee(onload);
  });
  if (!accountStore.account) {
    return <Loading />;
  }

  const isAppSumo = Boolean(accountStore?.account?.payment?.appsumo);

  let plans = isAppSumo ? appSumoPlans : activePlans;
  if (accountStore.account.accountId === 1841) {
    plans = landbotPlans;
  }
  const monthlyGrowthPlans: JSX.Element[] = [];
  const monthlyPremiumPlans: JSX.Element[] = [];
  const yearlyGrowthPlans: JSX.Element[] = [];
  const yearlyPremiumPlans: JSX.Element[] = [];
  // normal plans
  plans.forEach((plan) => {
    if (peopleUpperLimit < plan.peopleUpperLimit) {
      let price: number | string =
        plan.period === "month" ? plan.price : Math.ceil(plan.price / 12);
      const planOption = (
        <option
          key={`${plan.name}:${plan.peopleUpperLimit}`}
          value={`${plan.name}:${plan.peopleUpperLimit}`}
        >
          {plan.peopleLowerLimit} - {plan.peopleUpperLimit} People: ${price}{" "}
          {
            // display annual prices per month too
          }
          per month{" "}
          {plan.period === "year" && "(billed yearly) - 2 Months Free!"}
          {plan.name.includes("appsumo")
            ? "- 50% Exclusive AppSumo Discount!"
            : ""}
        </option>
      );
      if (plan.period === "month") {
        if (plan.tier === "growth") {
          monthlyGrowthPlans.push(planOption);
        } else {
          monthlyPremiumPlans.push(planOption);
        }
      } else {
        if (plan.tier === "growth") {
          yearlyGrowthPlans.push(planOption);
        } else {
          yearlyPremiumPlans.push(planOption);
        }
      }
    }
  });
  return (
    <Modal isOpen={true} toggle={() => props.onCancel()} zIndex={1100}>
      <ModalHeader>Upgrade plan</ModalHeader>
      <ModalBody>
        <form id="preorder-form" style={{ height: "100px" }}>
          <select
            className="form-control mt-3 mb-3"
            id="plans-people-picker"
            required={true}
          >
            <optgroup label="Monthy Growth">{monthlyGrowthPlans}</optgroup>
            <optgroup label="Monthy Premium">{monthlyPremiumPlans}</optgroup>
            {yearlyGrowthPlans?.length && (
              <optgroup label="Annual Growth - 2 Months Free">
                {yearlyGrowthPlans}
              </optgroup>
            )}
            <optgroup label="Annual Premium - 2 Months Free">
              {yearlyPremiumPlans}
            </optgroup>
          </select>
          <div className="float-right">
            <Button
              color="link"
              className="mt-3"
              onClick={() => props.onCancel()}
            >
              Cancel
            </Button>{" "}
            {!chargebeeLoaded ? (
              // display loading if chargebee is not loaded
              <Loading />
            ) : (
              <ButtonLoader
                color="primary"
                className="mt-3"
                type="button"
                onClick={() => {
                  if (!chargebeeLoaded) return;
                  openCheckout(
                    accountStore.account?.mainAccountId ||
                      accountStore.account!.accountId,
                    accountStore.account!.email
                  );
                }}
              >
                {confirmButtonText}
              </ButtonLoader>
            )}
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
});

export default ChargebeeCheckout;

const openCheckout = (accountId: IAccount["id"], email?: string) => {
  // get the currently selected value from the dropdown
  // NOTE: This is definitely not the react way but doing it
  //  'properly' involves too many workarounds
  const dropdownValue = $("#plans-people-picker").val();
  if (typeof dropdownValue !== "string") return;
  const upgradePlan = dropdownValue.split(":")[0];
  const upgradePeople = Number(dropdownValue.split(":")[1]);

  const chargebeeInstance = (window as any).Chargebee.getInstance();
  const cart = chargebeeInstance.getCart();
  const product = chargebeeInstance.initializeProduct(
    upgradePlan,
    upgradePeople
  );
  const customerData = {
    email,
    id: accountId,
    cf_accountid: accountId,
  } as Dictionary<any>;
  // Get the ID to track affiliate signups
  const firstPromoterId = (window as any)?.$FPROM?.data?.tid;
  if (firstPromoterId) {
    customerData.til = firstPromoterId;
  }

  cart.setCustomer(customerData);
  cart.replaceProduct(product);
  cart.proceedToCheckout();
};
