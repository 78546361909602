import { Input, Button, Label, Alert, FormGroup } from "reactstrap";
import ButtonLoader from "components/ButtonLoader";
import Drawer from "components/Drawer";
import ConfigureStepHeader from "components/ConfigureStep/ConfigureStepTabs/ConfigureStepHeader";
import { Tag } from "encharge-domain/definitions/Tag";
import { formatTag } from "store/tagsStore";

interface Props {
  tag: Tag["tag"];
  onChangeTag: (tag: Tag["tag"]) => void;
  description: Tag["description"];
  onChangeDescription: (description?: Tag["description"]) => void;
  isLoading: boolean;
  isTagTaken: boolean;
  mode: "create" | "edit";

  onConfirm: () => void;
  closeModal: () => void;
}

const TagEditPopupView = (props: Props) => {
  return (
    <Drawer
      open={true}
      onClose={props.closeModal}
      className="col-23 col-md-18 col-sm-16 col-lg-12 col-xl-8 float-right"
    >
      <form
        className="w-100"
        onSubmit={(e) => {
          props.onConfirm();
          // make sure form doesnt reload page
          e.preventDefault();
          e.stopPropagation();
        }}
      >
        <ConfigureStepHeader
          onClose={props.closeModal}
          stepName={props.mode === "create" ? "New Tag" : "Edit Tag"}
          subtitle={
            props.mode === "create" ? "Create new tag" : "Modify existing tag"
          }
        />
        <div className="step-nav-tab-pane-content py-3 py-md-5 px-2 px-md-3 px-lg-5">
          <FormGroup className="mb-4">
            {props.mode === "edit" && (
              <div>
                Modifying this tag will change it in flows, segments and people.
              </div>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="tag-name">Tag</Label>
            <Input
              id="tag-name"
              autoFocus={true}
              type="text"
              value={props.tag}
              onChange={(e) =>
                props.onChangeTag(formatTag(e.target.value, false))
              }
              required={true}
            />
            {props.isTagTaken && (
              <Alert color="warning">This tag already exists.</Alert>
            )}
          </FormGroup>
          <FormGroup>
            <Label for="tag-description">
              Description <span className="text-muted small">Optional</span>
            </Label>
            <Input
              id="tag-description"
              type="textarea"
              value={props.description}
              onChange={(e) => props.onChangeDescription(e.target.value)}
            />
          </FormGroup>
        </div>
        <div className="form-button-fixed-container d-flex justify-content-between flex-row-reverse">
          <ButtonLoader
            color="primary"
            type="submit"
            disabled={
              // Disable if empty tag or if tag name is taken
              !props.tag || props.isTagTaken
            }
            loading={props.isLoading}
            data-testid="confirm-tag-edit"
          >
            {props.mode === "create" ? "Create" : "Save"}
          </ButtonLoader>
          <Button
            className="float-right"
            color="link"
            onClick={props.closeModal}
            data-testid="cancel-tag-edit"
          >
            Cancel
          </Button>
        </div>
      </form>
    </Drawer>
  );
};

export default TagEditPopupView;
