import { DomainStore } from "./domainStore";
import { observable, runInAction, action } from "mobx";
import {
  sendLogin,
  checkLogin,
  sendGoogleLogin,
} from "./persistence/persistLogin";
import { toastError } from "../domain/errorHandling/toaster";
import Cookies from "js-cookie";

export class AuthStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
    this.loggedIn = true;
    // readonly, don't try to login
    if (this.rootStore.permissionsStore.readOnlyFlow) return;
    this.tryLogin();
  }

  @observable
  loggedIn?: boolean;

  @action
  async logOut() {
    Cookies.remove("connect.sid", { path: "/", domain: ".encharge.io" });
    this.loggedIn = false;
  }

  @action
  async logIn(credentials: {
    email?: string;
    password?: string;
    googleToken?: string;
  }) {
    const uiStore = this.rootStore.uiStore;
    try {
      if (credentials.email && credentials.password) {
        uiStore.loggingIn.loading = true;
        uiStore.loggingIn.loadingGoogleSignin = false;
        uiStore.loggingIn.showLogin = true;
        await sendLogin(credentials.email, credentials.password);
      } else if (credentials.googleToken) {
        uiStore.loggingIn.loadingGoogleSignin = true;
        uiStore.loggingIn.loading = false;
        uiStore.loggingIn.showLogin = true;

        await sendGoogleLogin(credentials.googleToken);
      } else {
        throw new Error("Missing email or password.");
      }

      // All good, we are logged in. Save in state and go on our merrily way.
      runInAction(() => {
        uiStore.loggingIn.loading = false;
        uiStore.loggingIn.loadingGoogleSignin = false;
        uiStore.loggingIn.redirect = true;
        uiStore.loggingIn.showLogin = false;

        this.loggedIn = true;
      });
    } catch (e) {
      toastError({
        message: "We couldn't log you in.",
        extra: e,
      });

      runInAction(() => {
        uiStore.loggingIn.loading = false;
        uiStore.loggingIn.loadingGoogleSignin = false;
        uiStore.loggingIn.showLogin = true;
      });
    }
  }

  async tryLogin() {
    try {
      await checkLogin();
      runInAction(() => {
        this.loggedIn = true;
      });
    } catch (error) {
      // unauthorized
      if (error.response && error.response.status === 401) {
        runInAction(() => {
          this.loggedIn = false;
        });
      } else {
        // TODO
        // EN-783 Check for internet connectivity and display retry overlay
        toastError(
          "We are having issues connecting to our servers. Please check your internet connection and reload."
        );
      }
    }
  }
}
