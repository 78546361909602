import { ITeamMember } from "encharge-domain/definitions/TeamMember";

export const segmentIdentify = ({
  accountId,
  teamMemberId,
  traits,
}: {
  teamMemberId?: ITeamMember["id"];
  accountId?: IAccount["id"];
  traits: Dictionary<any>;
}) => {
  const analytics = (window as any)?.analytics;
  analytics?.identify?.(teamMemberId, { groupId: accountId, ...traits });
};

export const segmentTrack = ({
  name,
  properties,
}: {
  name: string;
  properties?: Dictionary<any>;
}) => {
  const analytics = (window as any)?.analytics;
  analytics?.track?.(name, properties);
};
