import { action, runInAction, when } from "mobx";
import { PersonField } from "encharge-domain/lib/entities/personField";
import {
  createFields,
  editField,
  deleteField,
} from "./persistence/persistPersonFields";
import humanizeString from "encharge-domain/lib/helpers/humanizeString";
import { DomainStore } from "./domainStore";
import _ from "lodash";
import { APIQueueFactory } from "domain/apiQueue";
import { FieldsSchema } from "encharge-domain/lib/entities/fields_schema";
import { IPersonField } from "encharge-domain/definitions/PersonField";
import { IFieldsSchema } from "encharge-domain/definitions/JSONSchema6";

interface CreatableField {
  name: string;
  title: string;
  fieldType: IPersonField["type"];
  fieldFormat: IPersonField["format"];
  description?: string;

  createdBy?: IPersonField["createdBy"];
}

export class PersonFieldsStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
  }

  queue = APIQueueFactory({ name: "personFields", limit: 1 });

  get account() {
    return this.rootStore.accountStore.account;
  }

  get personFields() {
    return this.account?.personFields;
  }

  @action
  async addFields({
    fieldsToCreate,
    // Whether to add the newly created fields in the current folder (if any)
    placeInCurrentFolder = false,
  }: {
    fieldsToCreate: CreatableField[];
    placeInCurrentFolder?: boolean;
  }) {
    //If no fields to add
    if (fieldsToCreate.length === 0) return;
    if (!this.account) return;

    this.queue.addConfirmableAction({
      state: () => this.account!.personFields,
      performAction: (existingFields) => {
        // create field objects out of the passed data
        const newFields = _.map(
          fieldsToCreate,
          (field) =>
            new PersonField({
              title: field.title,
              name: field.name,
              firstClassField: false,
              format: field.fieldFormat,
              type: field.fieldType,
              tooltip: field.description,
              createdBy: field.createdBy,
            })
        );
        // add fields locally
        existingFields.push(...newFields);

        // Add newly created fields in current folder
        // Note: this is done here to speed up UI. It can lead to children in
        // folder that dont exist (if field creation fails). However, folders
        // ignore unexisting children, so this is ok.
        if (placeInCurrentFolder) {
          const currentFolderId = this.rootStore.foldersStore.getSelectedFolder(
            "personFields"
          );
          if (currentFolderId) {
            _.map(newFields, (field) =>
              this.rootStore.foldersStore.pushItemToFolder({
                folderId: currentFolderId,
                itemId: field.name,
                type: "personFields",
              })
            );
          }
        }
        // persist new fields
        return async () => {
          await createFields(newFields);
          runInAction(() => {
            // add the newly created fields to the peopleTableFields
            // only add if the people table fields are defined
            if (this.account!.peopleTableFields) {
              const withNewFields = this.account!.peopleTableFields.concat(
                ..._.map(newFields, (newField) => ({
                  name: newField.name,
                  enabled: true,
                }))
              );
              this.rootStore.accountStore.setPeopleTableFields(withNewFields);
            }
          });
        };
      },
    });
  }

  /**
   *  Modify existing field.
   */
  @action
  async editField(fieldData: IPersonField) {
    if (!this.account) {
      return;
    }
    this.queue.addConfirmableAction({
      state: () => this.account!.personFields,
      performAction: (existingFields) => {
        // Note: Since field name should be immutable, use name in fieldData
        // to find field to edit.
        const fieldToEditIndex = _.findIndex(
          existingFields,
          (current) => current.name === fieldData.name
        );
        // make sure field exists
        if (fieldToEditIndex === -1) return;
        // modify by index
        existingFields[fieldToEditIndex] = fieldData;
        // persist
        return () => editField(existingFields[fieldToEditIndex]);
      },
    });
  }

  /**
   * Delete field by name
   */
  @action
  async deleteField(fieldName: IPersonField["name"]) {
    if (!this.account) {
      return;
    }
    this.queue.addConfirmableAction({
      state: () => this.account!.personFields,
      performAction: (existingFields) => {
        const fieldToRemoveIndex = _.findIndex(
          existingFields,
          (current) => current.name === fieldName && canEditField(current)
        );
        // if not found, exit
        if (fieldToRemoveIndex === -1) return;
        // remove locally
        existingFields.splice(fieldToRemoveIndex, 1);
        // persist
        return () => deleteField(fieldName);
      },
    });
  }

  /**
   * Check output schema for unexisting fields and add them to the account.
   *
   * @param {IFieldsSchema} outputFieldsSchema
   * @returns
   * @memberof AccountStore
   */
  @action
  async createUnexistingOutputFields(outputFieldsSchema: IFieldsSchema) {
    // make sure we have the account loaded
    await when(() => this.account !== undefined);

    // find the fields to create
    // by traversing the schema with cloneDeep
    const fieldsToCreate: CreatableField[] = [];

    _.cloneDeepWith(outputFieldsSchema, (value: any) => {
      if (value && value.endUserField) {
        // if the mapping comes from static properties mapping
        if (typeof value.endUserField === "string") {
          const fieldName = value.endUserField;
          // if field doesn't exist
          if (
            !_.find(
              this.account!.personFields,
              (field) => field.name === fieldName
            )
          ) {
            // schedule it for creation
            fieldsToCreate.push({
              name: fieldName,
              title: humanizeString(fieldName),
              fieldType: value.type,
              fieldFormat: value.format,
              description: value.description,
            });
          }
        }
        // mapping comes from additional properties mapping
        // (e.g. segment.com mapping), then endUserField is a object like:
        // { remoteField: enchargeField }
        else if (typeof value.endUserField === "object") {
          // _.forEach(value.endUserField, (fieldName) => {
          //   if (
          //     !_.find(
          //       this.account!.personFields,
          //       (field) => field.name === fieldName
          //     )
          //   ) {
          //     // schedule it for creation
          //     fieldsToCreate.push({
          //       name: fieldName,
          //       title: humanizeString(fieldName),
          //       fieldType: "string",
          //       fieldFormat: undefined,
          //     });
          //   }
          // });
        }
      }
    });
    if (fieldsToCreate.length === 0) {
      // No fields to add
      return;
    }
    return this.addFields({ fieldsToCreate });
  }

  /**
   *  Retrieve a field by name if it exists in this account
   */
  getField(fieldName: string) {
    // await when(() => this.account !== undefined);
    return _.find(
      this.account?.personFields,
      (field) => field.name === fieldName
    );
  }

  /**
   *  Retrieve a field by name if it exists in this account
   */
  getFieldTitle(fieldName: string) {
    const field = this.getField(fieldName);
    if (!field) return undefined;
    return formatFieldTitle(field);
  }

  /**
   * Retrieve the name of a given field as it is stored in the DB
   */
  async getFieldDBName(fieldName: string) {
    await when(() => this.account !== undefined);
    const field = _.find(
      this.account!.personFields,
      (field) => field.name === fieldName
    );
    // If we didn't find the field, assume it's a data field
    // Not first class fields are stored in "data"
    if (!field || !field.firstClassField) {
      return `data.${fieldName}`;
    }
    // First class field (not in data)
    return fieldName;
  }
}

/**
 * Check if a field can be edited. I.e. not default field currently.
 */
export const canEditField = (field: IPersonField) => {
  if (field.createdBy && field.createdBy === "system") {
    return false;
  }
  if (field.firstClassField) return false;
  if (field.name === "unsubscribeReason") return false;
  return true;
};

/**
 * Format name so that it can be used as identifier in the API, in queries, etc.
 */
export const formatFieldName = (name: string) => {
  // remove non-alphanumeric (with unicode support) chars,
  // but allow dot, dash, underscore and space
  const alphanumericName = name.replace(/[^_.\w\- ]/gu, "");
  // Remove more than 1 dot next to each other,
  // Allow people to create nested structures
  const nameWithSingleDots = alphanumericName.replace(/[.]{2,}/g, "");

  // remove whitespace also leading and trailing dots
  return nameWithSingleDots
    .replace(/\s/g, "")
    .replace(/^\./, "")
    .replace(/\.$/, "");
};

export const formatFieldTitle = (field: IPersonField) => {
  return field.title || humanizeString(field.name);
};

export const tagsField: IPersonField = {
  array: true,
  name: "tags",
  readOnly: false,
  type: "string",
  canMapFrom: false,
  firstClassField: true,
  icon: "icn-tag",
  tooltip:
    "Pass tags to this field to tag the user. Separate tags by comma. This field can't be used to remove tags.",
  title: "Tags",
};
