import { useStores } from "../../store/useStore";
import { Button, Container } from "reactstrap";
import { observer } from "mobx-react";
import ChangePlan from "./ChangePlan";
import Jumbotron from "reactstrap/lib/Jumbotron";
import useDisableScrolling from "../../hooks/useDisableScrolling";

const TrialExpiredMessage = observer(() => {
  const { accountStore } = useStores();

  // Disable scrolling underneath
  const setContainerRef = useDisableScrolling();

  let message = "";
  if (accountStore.account?.status === "trialExpired") {
    message = "Oh no! Your trial has expired 😥";
  } else if (accountStore.account?.status === "canceled") {
    message = "Oh, your account is canceled 😥";
  } else {
    return null;
  }
  return (
    <div
      className="full-screen-message trial-expired-message col"
      ref={setContainerRef}
    >
      <Jumbotron className="h-100">
        <Container className="text-center">
          <h3 className="mt-3">{message}</h3>
          <div className="mt-3 lead">
            Choose a plan to get back to your marketing:
          </div>
          <div className="mt-3 lead">
            <div className="col mt-3">
              <ChangePlan
                button={<Button color="primary">Choose a plan</Button>}
              />
            </div>
            {accountStore.account?.status === "trialExpired" && (
              <div className="col mt-3 small">
                <hr style={{ width: "100px" }} />
                or
                <br />
                <a href="https://calendly.com/encharge/encharge-demo-call">
                  Book a free strategy call
                </a>
                <br />
                <a href="mailto:support@encharge.io?subject=I%20need%20a%20trial%20extension&body=Hi%2C%20would%20you%20extend%20my%20Encharge%20trial.">
                  Get your trial extended
                </a>
              </div>
            )}
          </div>
        </Container>
      </Jumbotron>
    </div>
  );
});

export default TrialExpiredMessage;
