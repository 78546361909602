import { useCallback, useRef, useState } from "react";
import AppNavDropdown from "./AppNavDropdown";
import { ReactComponent as AccountIcon } from "../../assets/img/nav/account.svg";
import { ReactComponent as TeamMembersIcon } from "../../assets/img/nav/team-members.svg";
import { ReactComponent as PlanBillingIcon } from "../../assets/img/nav/plan-billing.svg";
import { ReactComponent as ManageAccountsIcon } from "../../assets/img/nav/manage-accounts.svg";
import { ReactComponent as ChangelogIcon } from "../../assets/img/nav/changelog.svg";
import { ReactComponent as LogoutIcon } from "../../assets/img/nav/logout.svg";
import { useStores } from "store/useStore";
import { UncontrolledTooltip } from "reactstrap";
import { PersonAvatar } from "components/Person/PersonAvatar";
import { observer } from "mobx-react";
import AppNavAccountInfo from "./Account/AppNavAccountInfo";
import LoadingOverlay from "components/LoadingOverlay";
import LoadingCentered from "components/LoadingCentered";
import ChangelogWidget from "./ChangelogWidget";
import ServiceStatusWidget from "components/AppNav/ServiceStatusWidget";

/**
 * User profile in nav
 */
const AppNavProfile = () => {
  const { authStore, accountStore, uiStore } = useStores();
  const account = accountStore.account;

  const [isOpen, setIsOpen] = useState(false);
  const [isAccountPickerOpen, setIsAccountPickerOpen] = useState(false);

  const divRef = useRef<HTMLDivElement | null>(null);

  const avatar = !account ? (
    // Placeholder
    <div style={{ width: "40px", height: "40px" }} />
  ) : (
    <PersonAvatar name={account?.name} email={account?.email} size={40} />
  );

  const close = useCallback(() => {
    // Give a bit of time for the account picker to work if it was clicked
    setTimeout(() => setIsOpen(false), 50);
  }, []);
  return (
    <>
      {divRef.current && !isOpen && (
        <UncontrolledTooltip
          target={divRef.current}
          placement={"bottom"}
          delay={0}
        >
          Your Profile
        </UncontrolledTooltip>
      )}
      {uiStore.switchAccount.isLoading && <LoadingOverlay />}
      <div
        ref={divRef}
        data-testid="profile-menu"
        className="sidebar-nav-item sidebar-nav-profile cursor-pointer mr-2 my-auto"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>
          {avatar}
          <AppNavDropdown
            isOpen={isOpen}
            setIsOpen={close}
            options={[
              {
                contents: account ? (
                  <AppNavAccountInfo
                    isOpen={isAccountPickerOpen}
                    setIsOpen={setIsAccountPickerOpen}
                  />
                ) : (
                  <LoadingCentered size="small" />
                ),
                onClick: (e) => {
                  setIsAccountPickerOpen(!isAccountPickerOpen);
                  e?.stopPropagation();
                },
              },
              {
                contents: (
                  <>
                    <ManageAccountsIcon className="nav-icon" />
                    Manage Accounts
                  </>
                ),
                to: "/settings/accounts",
              },

              {
                contents: (
                  <>
                    <AccountIcon className="nav-icon" />
                    Your Account
                  </>
                ),
                to: "/account/info",
              },
              {
                contents: (
                  <>
                    <TeamMembersIcon className="nav-icon" />
                    Team Members
                  </>
                ),
                to: "/settings/team",
              },
              {
                contents: (
                  <>
                    <PlanBillingIcon className="nav-icon" />
                    Plan and Billing
                  </>
                ),
                to: "/billing",
              },
              {
                contents: (
                  <>
                    <ChangelogIcon className="nav-icon" />
                    <ChangelogWidget />
                  </>
                ),
                onClick: (e) => {
                  if (e && $(e.target).attr("id") !== "changelog-button") {
                    $("#changelog-button").trigger("click");
                  }
                },
              },
              {
                contents: (
                  <>
                    <ServiceStatusWidget />
                  </>
                ),
                href: "https://status.encharge.io/",
              },
              {
                contents: account ? (
                  <div className="d-flex">
                    <div style={{ minWidth: "40px" }}>{avatar}</div>
                    <div className="ml-3 account-details-person-name">
                      <div>{accountStore.account?.name || ""}</div>
                      <div className="small text-muted">
                        {accountStore.account?.email || ""}
                      </div>
                    </div>
                  </div>
                ) : (
                  <LoadingCentered size="small" />
                ),
                to: "/profile",
              },
              {
                contents: (
                  <>
                    <LogoutIcon className="nav-icon" />
                    <span className="text-danger" data-testid="logout-button">
                      Log out
                    </span>
                  </>
                ),
                onClick: () => {
                  authStore.logOut();
                },
              },
            ]}
          />
        </span>
      </div>
    </>
  );
};
export default observer(AppNavProfile);
