import ButtonLoader from "components/ButtonLoader";
import { PropsWithChildren, useCallback } from "react";
import { Form } from "reactstrap";

interface Props {
  onSubmit: (e: any) => void;
  error?: string;
  loading?: boolean;

  disabled?: boolean;

  heading: string;
  subheading?: string;
  subsubheading?: string;
  buttonText?: string;
}

const RegistrationStepFormCommon = (props: PropsWithChildren<Props>) => {
  const propsSubmit = props.onSubmit;
  const onSubmit = useCallback(
    (e) => {
      propsSubmit(e);
      e.preventDefault();
      return false;
    },
    [propsSubmit]
  );
  return (
    <div className="max-width-500 ">
      <Form onSubmit={onSubmit}>
        <h1 className="h2 font-weight-bold">{props.heading}</h1>
        <div className="text-muted h5 mt-2 mb-3">
          {props.subheading}
          {props.subsubheading && (
            <>
              <br />
              <div className="additional-cta-section mt-2">
                {props.subsubheading}
              </div>
            </>
          )}
        </div>
        {props.children}
        <ButtonLoader
          className="mt-5"
          color="primary"
          size="lg"
          block={true}
          loading={props.loading}
          type="submit"
          disabled={props.disabled}
        >
          {props.buttonText || "Next Step"}
        </ButtonLoader>
        {props.error}
      </Form>
    </div>
  );
};
export default RegistrationStepFormCommon;
