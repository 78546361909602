import { DomainStore } from "./domainStore";
// import LogRocket from "logrocket";

import { observable, runInAction, action, autorun, computed } from "mobx";

import {
  getAccount,
  saveServiceCredentials,
  updateAccount,
  switchAccount,
  uploadLogo,
  deleteAccount,
  getAccountIssues,
} from "./persistence/persistAccount";
import { toastError, toastSuccess } from "../domain/errorHandling/toaster";
import _ from "lodash";

import { redirectWithReload } from "../domain/helpers/redirect";
import { shouldSkipResouces } from "../domain/helpers/shouldSkipResouces";
import {
  updateServiceConfig,
  refreshServiceResponseSchema,
} from "store/persistence/persistServices";
import { testTeamMemberIds } from "../constants";
import { IPersonField } from "encharge-domain/definitions/PersonField";
import { IFieldsSchema } from "encharge-domain/definitions/JSONSchema6";
import { hasScopes } from "encharge-domain/lib/helpers/authScopes";
import {
  ITeamMember,
  TeamMemberScopes,
  TeamMemberScopeTypes,
} from "encharge-domain/lib/definitions/TeamMember";
import { PartialAccount } from "encharge-domain/definitions/PartialAccount";
import enchargeAPI from "./persistence/enchargeAPI";
import { lazyObservable } from "domain/helpers/lazyLoad";
import { DateTime } from "luxon";

type IOnboardingActions =
  | "import"
  | "apps"
  | "flow"
  | "help"
  | "flowHowToVideo";

export interface FrontendAccount {
  version?: number;
  personFields: IPersonField[];
  accountId: IAccount["id"];
  mainAccountId?: IAccount["id"];
  apiKeys: string[];
  writeKey: string;
  activeServices: IService["id"][];
  accountName?: NonNullable<IAccount["data"]>["name"];
  site?: NonNullable<IAccount["data"]>["site"];
  phone?: NonNullable<IAccount["data"]>["phone"];
  name: ITeamMember["name"];
  email?: ITeamMember["email"];
  createdWithGoogleSignin?: boolean;
  teamMemberId?: ITeamMember["id"];
  teamMemberIsOwner?: boolean;
  teamMemberScopes?: TeamMemberScopes;
  allAccounts: {
    id: IAccount["id"];
    name?: string;
    site?: string;
    logo?: string;
  }[];
  timezone: string;
  outputFieldMappings: NonNullable<IAccount["data"]>["outputFieldMappings"];
  formFieldMappings: NonNullable<IAccount["data"]>["formFieldMappings"];
  onboarding?: { [action in IOnboardingActions]?: boolean };
  flags?: {
    showConnectedServicesOnly?: boolean;
    hideAnonymous?: boolean;
    hasSetInitialPassword?: boolean;
    hasFilledGoals?: boolean;
    [key: string]: any;
  };
  payment?: NonNullable<IAccount["data"]>["payment"];
  status: NonNullable<IAccount["data"]["payment"]>["status"];
  peopleTableFields?: {
    name: string;
    enabled: boolean;
  }[];
  approved: boolean;
  blocked?: boolean;
  serviceConfig: NonNullable<NonNullable<IAccount["data"]>["serviceConfig"]>;
  peopleCount: number;
  linkUTMs: IAccount["data"]["linkUTMs"];
  testAccount: IAccount["data"]["testAccount"];
  webhookSeed: string;
  mailingAddress: IAccount["data"]["mailingAddress"];
  logo: IAccount["data"]["logo"];
  emailFonts: IAccount["data"]["emailFonts"];
}

export type AccountInfoResponse = {
  accountId: IAccount["id"];
  mainAccountId: IAccount["id"];
  name: NonNullable<IAccount["data"]>["name"];
  site: NonNullable<IAccount["data"]>["site"];
  phone: NonNullable<IAccount["data"]>["phone"];
  teamMember: {
    name: ITeamMember["name"];
    email: ITeamMember["email"];
    id: ITeamMember["id"];
    createdWithGoogleSignin?: boolean;
    scopes?: TeamMemberScopes;
    isOwner?: boolean;
    accounts: {
      id: IAccount["id"];
      name: IAccount["data"]["name"];
    }[];
  };
  apiKeys: [string];
  writeKey: string;
  fields: IPersonField[];
  activeServices: IService["id"][];
  timezone: string;
  outputFieldMappings: NonNullable<IAccount["data"]>["outputFieldMappings"];
  formFieldMappings: NonNullable<IAccount["data"]>["formFieldMappings"];
  serviceConfig: NonNullable<IAccount["data"]>["serviceConfig"];
  onboarding: any;
  payment?: NonNullable<IAccount["data"]>["payment"];
  flags?: { [key: string]: any };
  peopleTableFields?: {
    name: string;
    enabled: boolean;
  }[];
  status: NonNullable<IAccount["data"]["payment"]>["status"];
  approved: IAccount["data"]["approved"];
  peopleCount: number;
  linkUTMs: IAccount["data"]["linkUTMs"];
  testAccount: IAccount["data"]["testAccount"];
  webhookSeed: string;
  mailingAddress: IAccount["data"]["mailingAddress"];
  logo: IAccount["data"]["logo"];
  emailFonts: IAccount["data"]["emailFonts"];
  version: IAccount["data"]["version"];
};

export class AccountStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;

    // Dont load flows on some routes
    if (shouldSkipResouces()) return;
    this.loadAccount();
  }

  @observable
  account?: FrontendAccount;

  @observable
  checkScope(scope: TeamMemberScopeTypes) {
    return hasScopes({
      neededScopes: [scope],
      isOwner: this.account?.teamMemberIsOwner,
      teamMemberScopes: this.account?.teamMemberScopes,
    });
  }

  isLoadingAccount() {
    return this.rootStore.uiStore.account.loading;
  }

  async loadAccount() {
    // don't retrieve account if we are in readonly mode
    // if (this.rootStore.permissionsStore.readOnly) {
    //   return;
    // }
    try {
      runInAction(() => {
        this.rootStore.uiStore.account.error = undefined;
        this.rootStore.uiStore.account.loading = true;
      });
      const account = await getAccount();
      this.populateAccountData(account);
    } catch (e) {
      // Don't show this error on login page
      toastError({
        message: "Error while loading your account.",
        extra: e,
      });
      runInAction(() => {
        this.rootStore.uiStore.account.error = e.message;
        this.rootStore.uiStore.account.loading = false;
      });
    }
  }

  @action
  populateAccountData(account: AccountInfoResponse) {
    runInAction(() => {
      this.account = {
        name: account?.teamMember?.name || "",
        email: account?.teamMember?.email || "",
        teamMemberId: account?.teamMember?.id,
        createdWithGoogleSignin: account?.teamMember?.createdWithGoogleSignin,
        allAccounts: account.teamMember.accounts,
        accountId: account.accountId,
        mainAccountId: account.mainAccountId,
        apiKeys: account.apiKeys,
        writeKey: account.writeKey,
        personFields: account.fields,
        activeServices: account.activeServices,
        accountName: account.name,
        phone: account.phone,
        site: account.site,
        timezone: account.timezone,
        outputFieldMappings: account.outputFieldMappings,
        formFieldMappings: account.formFieldMappings,
        peopleTableFields: account.peopleTableFields,
        onboarding: account.onboarding,
        flags: account.flags,
        payment: account.payment,
        status: account.status,
        approved: account.approved,
        serviceConfig: account.serviceConfig || {},
        peopleCount: account.peopleCount,
        linkUTMs: account.linkUTMs,
        testAccount: account.testAccount,
        webhookSeed: account.webhookSeed,
        mailingAddress: account.mailingAddress,
        teamMemberIsOwner: account.teamMember.isOwner,
        teamMemberScopes: account.teamMember.scopes,
        logo: account.logo,
        emailFonts: account.emailFonts,
        version: account.version,
      };

      this.rootStore.uiStore.account.error = undefined;
      this.rootStore.uiStore.account.loading = false;

      this.tagUsageRecording();
      this.identifyInExternalWidgets();
    });
  }

  /**
   * add account id to site recording software
   */
  tagUsageRecording() {
    if (!this.account) {
      return;
    }
    const accountId = this.account.accountId;
    // ignore test accounts and other uninteresting accounts
    if (this.account.testAccount) return;
    if (!this.account.teamMemberId) return;
    if (testTeamMemberIds.includes(this.account.teamMemberId)) return;
    if (process.env.NODE_ENV === "production" && !this.account?.testAccount) {
      // LogRocket?.init("fp45ok/encharge");
      // LogRocket?.identify(String(accountId), {
      //   accountId: this.account.accountId,
      //   teamMemberId: this.account.teamMemberId,
      //   name: this.account.accountName || "",
      //   username: this.account.name,
      //   // Add your own custom user variables here, ie:
      //   // subscriptionType: 'pro'
      // });
    }
  }

  // Identify the current person to Helpdocs and other services
  identifyInExternalWidgets() {
    if (!this.account) {
      return;
    }

    const email = this.account.email;
    const name = this.account.name;
    const win = window as any;

    // Dont identify us to avoid issues with merged accounts.
    if (
      email?.endsWith("encharge.io") ||
      email === "ikryveshko@supportyourapp.com"
    ) {
      return;
    }
    win?.Beacon?.("identify", {
      name: name,
      email: email,
      adminLink: `https://api.encharge.io/v1/admin/accounts/${this.account.accountId}`,
    });
  }

  getCredentials(serviceId: IService["id"]) {
    if (!this.account) {
      return undefined;
    }
    return _.find(
      this.account.activeServices,
      (service) => service === serviceId
    );
  }

  async saveCredentials(serviceId: IService["id"], credentials: any) {
    try {
      runInAction(() => {
        this.rootStore.uiStore.accountCredentials.error = undefined;
        this.rootStore.uiStore.accountCredentials.loading = true;
      });
      await saveServiceCredentials(serviceId, credentials);
      runInAction(() => {
        if (this.account) {
          this.account.activeServices.push(serviceId);
        }
        this.rootStore.uiStore.accountCredentials.error = undefined;
        this.rootStore.uiStore.accountCredentials.loading = false;
      });
    } catch (e) {
      toastError({
        message: "Error while saving your credentials.",
        extra: e,
      });

      runInAction(() => {
        this.rootStore.uiStore.accountCredentials.error = e.message;
        this.rootStore.uiStore.accountCredentials.loading = false;
      });
    }
  }

  async saveServiceConfig(
    serviceId: IService["id"],
    configName: IServiceConfigName,
    configValue: any,
    overwriteConfig: boolean = false
  ) {
    try {
      runInAction(() => {
        // start loading
        this.rootStore.uiStore.accountCredentials.error = undefined;
        this.rootStore.uiStore.accountCredentials.loading = true;
        // save config for this service
        if (this.account) {
          if (!this.account.serviceConfig) {
            this.account.serviceConfig = {};
          }
          if (!this.account.serviceConfig[serviceId]) {
            this.account.serviceConfig[serviceId] = {};
          }
          // overwrite config for this service as needed
          if (overwriteConfig) {
            this.account.serviceConfig[serviceId] = {
              [configName]: configValue,
            };
          } else {
            this.account.serviceConfig[serviceId][configName] = configValue;
          }
        }
      });
      // perist changes
      this.account?.serviceConfig?.[serviceId] &&
        (await updateServiceConfig(serviceId, configName, configValue));
      // stop loading
      runInAction(() => {
        this.rootStore.uiStore.accountCredentials.error = undefined;
        this.rootStore.uiStore.accountCredentials.loading = false;
      });
      toastSuccess("✅ Settings saved.");

      return true;
    } catch (e) {
      // notify and stop loading on error
      toastError({
        message: "Error while saving service configuration.",
        extra: e,
      });
      runInAction(() => {
        this.rootStore.uiStore.accountCredentials.error = e.message;
        this.rootStore.uiStore.accountCredentials.loading = false;
      });
      return false;
    }
  }

  async refreshServiceResponseSchema(serviceId: IService["id"]) {
    try {
      runInAction(() => {
        // start loading
        this.rootStore.uiStore.accountCredentials.error = undefined;
        this.rootStore.uiStore.accountCredentials.loading = true;
      });
      // perist changes
      const schema = await refreshServiceResponseSchema(serviceId);
      runInAction(() => {
        // save the mapping locally
        if (this.account) {
          if (!this.account.outputFieldMappings) {
            this.account.outputFieldMappings = {};
          }
          this.account.outputFieldMappings[serviceId] = schema;
        }
        this.rootStore.uiStore.accountCredentials.error = undefined;
        this.rootStore.uiStore.accountCredentials.loading = false;
      });

      return true;
    } catch (e) {
      // notify and stop loading on error
      toastError({
        message: "Error while retriving service fields.",
        extra: e,
      });
      runInAction(() => {
        this.rootStore.uiStore.accountCredentials.error = e.message;
        this.rootStore.uiStore.accountCredentials.loading = false;
      });
      return false;
    }
  }

  async saveServiceOutputFieldsMapping(
    serviceId: string,
    mapping: IFieldsSchema
  ) {
    try {
      runInAction(() => {
        this.rootStore.uiStore.accountCredentials.error = undefined;
        this.rootStore.uiStore.accountCredentials.loading = true;
      });
      await updateServiceConfig(serviceId, "outputFieldMappings", mapping);
      runInAction(() => {
        // save the mapping locally
        if (this.account) {
          if (!this.account.outputFieldMappings) {
            this.account.outputFieldMappings = {};
          }
          this.account.outputFieldMappings[serviceId] = mapping;
        }
        this.rootStore.uiStore.accountCredentials.error = undefined;
        this.rootStore.uiStore.accountCredentials.loading = false;
      });
      // create unexisting output fields
      this.rootStore.personFieldsStore.createUnexistingOutputFields(mapping);

      toastSuccess("✅ Settings saved.");
      return true;
    } catch (e) {
      toastError({
        message: "Error while saving the mapping.",
        extra: e,
      });

      runInAction(() => {
        this.rootStore.uiStore.accountCredentials.error = e.message;
        this.rootStore.uiStore.accountCredentials.loading = false;
      });
      return false;
    }
  }

  @action
  async onboardingActionComplete(stageName: string) {
    if (!this.account) return;
    if (!this.account.onboarding) {
      this.account.onboarding = {};
    }
    // send a request only if the person hasn't performed this action until now
    if (!this.account.onboarding[stageName]) {
      this.account.onboarding[stageName] = true;
      await updateAccount({ data: { onboarding: this.account.onboarding } });
    }
  }

  @action
  async saveFormTrackingFieldMapping(
    form: NonNullable<IAccount["data"]["formFieldMappings"]>[string]
  ) {
    if (!this.account) return;
    if (!this.account.formFieldMappings) {
      this.account.formFieldMappings = {};
    }
    const formIndex = form.id || form.formHash;
    this.account.formFieldMappings[formIndex] = form;
    this.rootStore.uiStore.formTrackingSave.startLoading();
    try {
      await updateAccount({
        data: {
          formFieldMappings: this.account.formFieldMappings,
        },
      });
      toastSuccess("✅ Form saved.");
    } catch (e) {
      toastError({
        message: "Error while saving your form.",
        extra: e,
      });
    } finally {
      this.rootStore.uiStore.formTrackingSave.finishLoading();
    }
  }

  @action
  async removeFormTrackingFieldMapping(
    // Hash or ID of the form
    formIndex: string
  ) {
    if (!this.account) return;
    if (!this.account.formFieldMappings) {
      return;
    }
    this.account.formFieldMappings = _.omit(
      this.account.formFieldMappings,
      formIndex
    );
    this.rootStore.uiStore.formTrackingDelete.startLoading();
    try {
      await updateAccount({
        data: {
          formFieldMappings: this.account.formFieldMappings,
        },
      });
      toastSuccess("✅ Form removed.");
    } catch (e) {
      toastError({
        message: "Error while removing your form.",
        extra: e,
      });
    } finally {
      this.rootStore.uiStore.formTrackingDelete.finishLoading();
    }
  }

  @action
  async setAccountFlag(flagName: string, value: any) {
    if (!this.account) return;
    if (!this.account.flags) {
      this.account.flags = {};
    }
    // send a request only if the person hasn't performed this action until now
    if (this.account.flags[flagName] !== value) {
      this.account.flags[flagName] = value;
      try {
        this.rootStore.uiStore.accountFlagsUpdating.startLoading();
        await updateAccount({ data: { flags: this.account.flags } });
      } finally {
        this.rootStore.uiStore.accountFlagsUpdating.finishLoading();
      }
    }
  }

  @action
  async setPeopleTableFields(
    fields: NonNullable<FrontendAccount["peopleTableFields"]>
  ) {
    if (!this.account) return;
    if (_.isEqual(this.account.peopleTableFields, fields)) {
      return;
    }
    try {
      this.rootStore.uiStore.accountPeopleTableFields.startLoading();
      // update account table fields in timeout to give time to the UI to render loading
      setTimeout(
        () =>
          runInAction(() => {
            this.account!.peopleTableFields = fields;
          }),
        10
      );

      await updateAccount({
        data: { peopleTableFields: fields },
      });
    } finally {
      this.rootStore.uiStore.accountPeopleTableFields.finishLoading();
    }
    // We could reset people to optimize amount of data loaded from backend
    // but with custom fields in single data
    // this.rootStore.peopleStore.resetPeople();
    // if (this.rootStore.segmentStore.currentSegmentId) {
    //   this.rootStore.segmentStore.resetSegment(
    //     this.rootStore.segmentStore.currentSegmentId
    //   );
    // }
  }

  @action
  async setAccountLinkUTMs(linkUTMs: IAccount["data"]["linkUTMs"]) {
    if (!this.account) return;
    if (_.isEqual(this.account.linkUTMs, linkUTMs)) return;
    this.account.linkUTMs = linkUTMs;
    try {
      await updateAccount({ data: { linkUTMs: this.account.linkUTMs } });
    } catch {
      toastError("Couldn't save Automatic UTM tagging settings.");
    }
  }

  @action
  async setMailingAddress(address?: string) {
    if (!this.account) return;
    if (this.account.mailingAddress === address || !address) return;
    this.account.mailingAddress = address;
    try {
      await updateAccount({
        data: { mailingAddress: this.account.mailingAddress },
      });
    } catch {
      toastError("Couldn't save your mailing address.");
    }
  }
  @action
  async setName(name?: string) {
    if (!this.account) return;
    if (this.account.accountName === name || !name) return;
    this.account.accountName = name;
    try {
      await updateAccount({
        data: { name: this.account.accountName },
      });
      toastSuccess("✅ Name saved.");
    } catch {
      toastError("Couldn't save your account name.");
    }
  }
  @action
  async setSite(site?: string) {
    if (!this.account) return;
    if (this.account.site === site || !site) return;
    this.account.site = site;
    try {
      await updateAccount({
        data: { site: this.account.site },
      });
      toastSuccess("✅ Site saved.");
    } catch {
      toastError("Couldn't save your account site.");
    }
  }
  @action
  async setPhone(phone?: string) {
    if (!this.account) return;
    if (this.account.phone === phone || !phone) return;
    this.account.phone = phone;
    try {
      await updateAccount({
        data: { phone },
      });
    } catch {
      toastError("Couldn't save your phone.");
    }
  }

  @action
  async setLogo(file: File) {
    if (!this.account) return;
    try {
      this.rootStore.uiStore.accountLogo.startLoading();

      const logoURL = await uploadLogo(file);
      if (logoURL) {
        await updateAccount({
          data: { logo: logoURL },
        });
        runInAction(() => {
          this.account!.logo = logoURL;
        });
      }
    } catch (e) {
      toastError(`Couldn't save your logo: ${e.message}`);
    } finally {
      this.rootStore.uiStore.accountLogo.finishLoading();
    }
  }

  @action
  async setTimezone(timezone: string) {
    if (!this.account) return;
    if (this.account.timezone === timezone || !timezone) return;
    this.account.timezone = timezone;
    try {
      await updateAccount({
        data: { timezone: this.account.timezone },
      });
    } catch {
      toastError("Couldn't save your account timezone.");
    }
  }

  @action
  async setTeamMemberName(name?: string) {
    if (!this.account) return;
    if (this.account.name === name || !name) return;
    if (!this.account.teamMemberId) return;
    this.account.name = name;
    try {
      await enchargeAPI.updateTeamMember(this.account.teamMemberId, { name });
    } catch {
      toastError("Couldn't save your name.");
    }
  }

  @action
  async switchAccount(accountId: IAccount["id"]) {
    try {
      if (!accountId) {
        throw new Error("No account to switch to.");
      }
      this.rootStore.uiStore.switchAccount.startLoading();
      await switchAccount(accountId);
      // redirect to the main page
      redirectWithReload("/");
      return true;
    } catch (e) {
      toastError({
        message: "Error while loading your account.",
        extra: e,
      });
    }
    this.rootStore.uiStore.switchAccount.finishLoading();
    return false;
  }

  @action
  async accountDelete(accountId: IAccount["id"]) {
    try {
      if (!accountId) {
        throw new Error("No account to delete to.");
      }

      this.rootStore.uiStore.deleteAccountLoading.startLoading();
      const result = await deleteAccount(accountId);

      if (result === "deleted") {
        toastSuccess("✅ Account deleted.");
        runInAction(() => {
          const accounts = this.allAccounts.current();
          const accountsWithoutDeleted = _.filter(
            accounts,
            (current) => current.id !== accountId
          );
          this.allAccounts.put(accountsWithoutDeleted);
        });
      } else {
        toastSuccess("✅ Account delete in progress.");
      }
    } catch (e) {
      toastError({
        message: "Error while deleting your account.",
        extra: e,
      });
    }
    this.rootStore.uiStore.deleteAccountLoading.finishLoading();
    return false;
  }

  @observable
  allAccounts = lazyObservable<PartialAccount[]>((sink, onError) => {
    enchargeAPI
      .getTeamMemberAccounts()
      .then((items) => {
        sink(observable(items.accounts as PartialAccount[]));
      })
      .catch((e) => {
        toastError({
          message: "Error while getting accounts.",
          extra: e,
        });
        onError(e);
      });
  });

  @action
  async createSubaccount(
    subaccountData: Pick<PartialAccount, "industry" | "name" | "site">
  ) {
    try {
      this.rootStore.uiStore.accountEdit.startLoading();
      const {
        subaccount: account,
      } = await enchargeAPI.createAccountForTeamMember({
        industry: subaccountData.industry || undefined,
        name: subaccountData.name || undefined,
        site: subaccountData.site || undefined,
        timezone: DateTime.local().toFormat("z") || "UTC",
      });
      if (account) {
        runInAction(() => {
          this.allAccounts.put([
            account as PartialAccount,
            ...this.allAccounts.current(),
          ]);
        });
        toastSuccess("✅ Account created.");
        trackAffiliateSignup(account.id);
      }
    } catch (e) {
      toastError({
        message: "Error while creating account.",
        extra: e,
      });
    } finally {
      this.rootStore.uiStore.accountEdit.finishLoading();
    }
    return;
  }

  accountIssues = lazyObservable<{ message: string; name: string }[]>(
    (sink, onError) => {
      getAccountIssues()
        .then((items) => {
          sink(observable(items));
        })
        .catch((e) => {
          onError(e);
        });
    }
  );
}

// Track affiliate signup
export const trackAffiliateSignup = (accountId: IAccount["id"]) => {
  console.log("tracking signup");
  const win = window as any;
  if (win.$FPROM) {
    win.$FPROM.trackSignup({ uid: accountId });
  } else {
    const _fprom = win._fprom || [];
    win._fprom = _fprom;
    _fprom.push(["event", "signup"]);
    _fprom.push(["uid", accountId]);
  }
};
