import _ from "lodash";
import AppNavMobile from "./AppNavMobile";
import AppNavItem from "./AppNavItem";
import AppNavManage from "./AppNavManage";

const navItems = [
  { label: "Dashboard", path: "/dashboard" },
  { label: "Flows", path: "/flows" },
  { label: "People", path: "/segments" },
  { label: "Broadcasts", path: "/broadcasts" },
  { label: "Emails", path: "/emails" },
  { label: "Metrics", path: "/metrics" },
];
const mobileExtraItems = [
  { label: "Tags", path: "/settings/tags" },
  { label: "Custom Fields", path: "/settings/custom-fields" },
];

const AppNav = () => {
  const items = _.map(navItems, (item) => (
    <AppNavItem label={item.label} path={item.path} key={item.label} />
  ));

  return (
    <>
      {/* Show full or mobile version */}
      <div className="d-none d-lg-flex">
        {items}
        <AppNavManage />
      </div>
      <div className="d-block d-lg-none">
        <AppNavMobile items={[...navItems, ...mobileExtraItems]} />
      </div>
    </>
  );
};

export default AppNav;
