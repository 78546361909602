import classNames from "classnames";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Label, Input } from "reactstrap";

interface Props {
  value: string;
  onChange: (val: string) => void;
  labelClassName?: string;
  inputClassName?: string;
}

const AccountIndustrySelect = (props: Props) => {
  const other = "other";
  const [options, setOptions] = useState([
    ..._.shuffle([
      { value: "SaaS", label: "Startup/SaaS" },
      { value: "Agency", label: "Agency/Consulting" },
      { value: "Content Creator", label: "Blogger/Influencer/Author" },
      { value: "Education", label: "Education/Online courses" },
      { value: "eCommerce", label: "eCommerce" },
    ]),
    { value: "other", label: "Other business" },
  ]);
  // Shuffle once per user to prevent bias towards the upper options
  useEffect(() => {
    setOptions(options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [selectValue, setSelectValue] = useState(props.value);
  return (
    <>
      <Label for="account-industry" className={props.labelClassName}>
        Business Type
      </Label>
      <Input
        type="select"
        name="account-industry"
        id="account-industry"
        className={props.inputClassName}
        value={selectValue}
        onChange={(e) => {
          setSelectValue(e.target.value);
          if (e.target.value === other) {
            // Manually entering industry
            props.onChange("");
          } else {
            props.onChange(e.currentTarget.value);
          }
        }}
      >
        <option value="">Select business type...</option>
        {_.map(options, (option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Input>
      {selectValue === other && (
        <Input
          className={classNames("mt-2", props.inputClassName)}
          type="text"
          name="account-industry-other"
          id="account-industry-other"
          placeholder="Please specify"
          // className={props.inputClassName}
          value={props.value}
          onChange={(e) => {
            props.onChange(e.currentTarget.value);
          }}
        />
      )}
    </>
  );
};
export default AccountIndustrySelect;
