import { DomainStore } from "./domainStore";
import { observable, runInAction, computed, action, when } from "mobx";
import {
  getEmails,
  updateEmail,
  createEmail,
  sendTestEmail,
  getEmail,
  copyEmailToAnotherAccount,
} from "./persistence/persistEmail";
import _ from "lodash";
import { EmailContentCreate } from "encharge-domain/lib/definitions/api/EmailContentCreate";
import { lazyObservable } from "../domain/helpers/lazyLoad";
import { toastSuccess, toastError } from "../domain/errorHandling/toaster";
import { getEmailsMetrics } from "./persistence/persistMetrics";
import { IFlow } from "components/Flows/FlowRoute";
import { integrationStateActive } from "encharge-domain/lib/helpers/constants";
import { StepBase } from "components/FlowEditor/Step/StepBase";
import { getEmailsForReadOnlyFlow } from "./persistence/persistFlow";
import { getUserTimezone } from "domain/helpers/asDateTime";

export interface CachedMetrics {
  metrics: ISingleEmailStats;
  periods: IEmailsStats["periods"];
  clicks: IEmailClicksMetrics;
}

type AllTimeMetricsByEmail = {
  [index: number]: { [index in IEmailStatsType]: ISingleEmailStats };
};

export class EmailsStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
  }
  initialized = false;

  @observable
  _emails: Map<IEmailContent["id"], IEmailContent> | undefined = undefined;

  @observable
  loadingEmails: boolean = false;

  @computed
  get emails() {
    if (this.initialized) {
      return this._emails;
    }

    // Get emails async
    runInAction(() => {
      this.loadingEmails = true;
    });
    this.initialized = true;
    let getEmailsFunc = () => getEmails();

    const readOnlyAuth = this.rootStore.permissionsStore.readOnlyAuthToken;
    const flowId = this.rootStore.flowsStore.currentFlowId;
    if (readOnlyAuth && flowId) {
      getEmailsFunc = () => getEmailsForReadOnlyFlow(flowId, readOnlyAuth);
    }

    getEmailsFunc()
      .then((emails) => {
        const emailsMap = _.map(emails, (email): [
          IEmailContent["id"],
          IEmailContent
        ] => [email.id, email]);
        runInAction(() => {
          this._emails = new Map(emailsMap);
        });
        runInAction(() => {
          this.loadingEmails = false;
        });
      })
      .catch((e) => {
        // Avoid showing errors to users who are logged out.
        toastError({
          message: "Error while loading your emails.",
          extra: e,
        });
        runInAction(() => {
          this.loadingEmails = false;
        });
      });
    // Until we retrieve them, emails are undefined
    return this._emails;
  }

  getEmailById(id: IEmailContent["id"]) {
    const emails = this.emails;
    if (!emails) {
      return undefined;
    }
    return emails.get(id);
  }

  @observable
  allTimeMetricsByEmail = lazyObservable<AllTimeMetricsByEmail>(
    (sink, onError) => {
      getEmailsMetrics({
        period: "allTime",
        startDate: new Date("2017-01-01T00:00:00.000Z"),
        groupByEmail: true,
        timezone: getUserTimezone(),
      })
        .then(({ stats: metrics }) => {
          const formatted = _.reduce(
            metrics,
            (acc, emailMetrics, emailId) => {
              acc[emailId] = emailMetrics;
              return acc;
            },
            {} as AllTimeMetricsByEmail
          );

          sink(observable(formatted));
        })
        .catch((e) => {
          // toastError({
          //   message: "Error while loading categories.",
          //   extra: e,
          // });
          onError(e);
        });
    }
  );

  @observable
  metricsCache: Dictionary<CachedMetrics> = {};

  @action
  async getFullEmail(id: IEmailContent["id"]) {
    // check if the email has been populated before
    const existingEmail = this.getEmailById(id);
    if (
      existingEmail?.html &&
      (existingEmail?.editor?.state || existingEmail?.editor?.html)
    ) {
      return existingEmail;
    }
    try {
      const fullEmail = await getEmail(id);
      if (!this.initialized) {
        await when(() => this.initialized);
      }
      runInAction(() => this.emails?.set(fullEmail.id, fullEmail));
      return fullEmail;
    } catch (e) {
      // toaster error
      toastError({
        message: "Error while loading your email.",
        extra: e,
      });
      return undefined;
    }
  }

  @action
  async updateEmail(email: Partial<IEmailContent>) {
    try {
      if (!email.subject) {
        throw new Error("Email subject is needed.");
      }
      if (!email.fromEmail) {
        throw new Error("From email is needed.");
      }
      if (!email.id) {
        throw new Error("Missing email ID.");
      }
      if (!email.editor || !email.html) {
        throw new Error("Missing email content.");
      }

      runInAction(() => {
        this.rootStore.uiStore.emailEditor.isSavingEmail = true;
      });
      const updatedEmail = await updateEmail(email);
      runInAction(() => {
        this.rootStore.uiStore.emailEditor.isSavingEmail = false;
        // Retain stats
        this.emails?.set(updatedEmail.id, updatedEmail);
      });
      return updatedEmail;
    } catch (e) {
      // Disable loading
      runInAction(() => {
        this.rootStore.uiStore.emailEditor.isSavingEmail = false;
      });
      // toaster error
      toastError({
        message: "Couldn't save your email.",
        extra: e,
      });
      return undefined;
    }
  }

  @action
  async createEmail(email: Partial<IEmailContent>) {
    try {
      if (!email.subject) {
        throw new Error("Email subject is needed.");
      }
      if (!email.fromEmail) {
        throw new Error("From email is needed.");
      }
      // Merge some defaults with the email we got from the editor
      const createableEmail: EmailContentCreate = _.merge(
        {
          type: "HTML",
          name: "Unnamed email",
        },
        email as Overwrite<
          Partial<IEmailContent>,
          {
            subject: IEmailContent["subject"];
            fromEmail: IEmailContent["fromEmail"];
          }
        >
      );

      runInAction(() => {
        this.rootStore.uiStore.emailEditor.isSavingEmail = true;
      });
      const currentFolderId = this.rootStore.foldersStore.getSelectedFolder(
        "emails"
      );
      const newEmail = await createEmail(createableEmail);
      if (!this.initialized) {
        await when(() => this.initialized);
      }
      runInAction(() => {
        this.emails?.set(newEmail.id, newEmail);
        this.rootStore.uiStore.emailEditor.isSavingEmail = false;

        // add newly created email to current folder
        if (currentFolderId) {
          this.rootStore.foldersStore.pushItemToFolder({
            folderId: currentFolderId,
            itemId: newEmail.id,
            type: "emails",
          });
        }
      });
      return newEmail;
    } catch (e) {
      // Disable loading
      runInAction(() => {
        this.rootStore.uiStore.emailEditor.isSavingEmail = false;
      });
      // rethrow for toaster errors
      toastError({
        message: `Error while creating your email.`,
        extra: e,
      });
      return undefined;
    }
  }

  @action
  async duplicateEmail(emailId: IEmailContent["id"]) {
    const emailToDuplicate = await this.getFullEmail(emailId);
    if (!emailToDuplicate) return;
    const newEmail = _.cloneDeep(_.omit(emailToDuplicate, "id", "stats"));
    newEmail.name = `Copy of ${newEmail.name}`;
    this.rootStore.uiStore.emailDuplication.startLoading();
    try {
      await this.createEmail(newEmail);
    } finally {
      this.rootStore.uiStore.emailDuplication.stopLoading();
    }
  }

  @action
  async copyEmailToAnotherAccount({
    emailId,
    targetAccountId,
  }: {
    emailId: IEmailContent["id"];
    targetAccountId: IAccount["id"];
  }) {
    this.rootStore.uiStore.emailDuplication.startLoading();
    try {
      await copyEmailToAnotherAccount({
        emailId,
        targetAccountId,
      });
      toastSuccess("✅ Email copied.");
    } catch (e) {
      toastError({
        message: `Error while copying email. ${e.message}`,
        extra: e,
      });
    } finally {
      this.rootStore.uiStore.emailDuplication.stopLoading();
    }
  }

  @action
  async archiveEmail(emailId: IEmailContent["id"]) {
    if (!this.emails) return;
    const emailToArchive = this.emails.get(emailId);
    if (!emailToArchive) {
      return;
    }
    try {
      // Delete from the local store
      this.emails.delete(emailId);
      emailToArchive.archived = true;
      return await updateEmail(emailToArchive);
    } catch (e) {
      // rethrow for toaster errors
      toastError({
        message: `Error while archiving email. ${e.message}`,
        extra: e,
      });
      return undefined;
    }
  }

  @action
  async sendTestEmail(email: Partial<IEmailContent>, sendTo?: string) {
    try {
      runInAction(() => {
        this.rootStore.uiStore.emailEditor.isSendingTestEmail = true;
      });
      const receiverEmail =
        sendTo || this.rootStore.accountStore?.account?.email;
      await sendTestEmail(email, receiverEmail!);
      toastSuccess(
        receiverEmail
          ? `Test email sent to ${receiverEmail} ✉️`
          : "Test email sent ️️️✉️"
      );
    } catch (e) {
      runInAction(() => {
        this.rootStore.uiStore.emailEditor.isSendingTestEmail = false;
      });
      // toaster msg
      toastError({
        message: "Error while sending test email.",
        extra: e,
      });
    }
    runInAction(() => {
      this.rootStore.uiStore.emailEditor.isSendingTestEmail = false;
    });
  }

  getCachedMetrics(props: {
    metricsPeriod: IStatsPeriod;
    startDate?: Date;
    endDate?: Date;
    flowIds?: IIntegration["id"][];
    emailIds?: IEmailContent["id"][];
    broadcastId?: Broadcast["id"];
  }) {
    return this.metricsCache[JSON.stringify(props)];
  }

  // Use this to keep track of which metrics are still loading
  // So that we don't trigger a new request on them
  retrivingMetricFor: Parameters<typeof getEmailsMetrics>[0] | undefined;

  @action
  async getEmailMetrics({
    metricsPeriod,
    startDate,
    endDate,
    flowIds,
    emailIds,
    broadcastId,
  }: {
    metricsPeriod: IStatsPeriod;
    startDate?: Date;
    endDate?: Date;
    flowIds?: IIntegration["id"][];
    emailIds?: IEmailContent["id"][];
    broadcastId?: Broadcast["id"];
  }) {
    // Make sure we are not refreshing the same metrics if they haven't loaded yet
    const metricOptions = {
      period: metricsPeriod,
      startDate,
      endDate,
      flowIds,
      emailIds,
      broadcastId,
      timezone: getUserTimezone(),
    };
    if (_.isEqual(this.retrivingMetricFor, metricOptions)) {
      // we are already loading these metris
      return;
    } else {
      // Loading new metrics, so store them to prevent reloading of the same ones
      this.retrivingMetricFor = metricOptions;
    }

    this.rootStore.uiStore.emailMetrics.startLoading();
    try {
      const { stats: metrics, clicks } = await getEmailsMetrics(metricOptions);

      // Lets sum the individual email metrics into single metric for the whole
      // account

      // Sum array of arrays by columns.
      // E.g [[1,2], [3,4]] to [4, 6]
      const sumColumns = (data: any[][]) => {
        return _.map(_.unzip(data), _.sum);
      };

      const summedMetrics = _.reduce(
        _.omit(metrics, "periods"),
        (acc, emailMetrics) => {
          _.each(emailMetrics, (metric, metricKey) => {
            if (!acc[metricKey]) {
              acc[metricKey] = metric;
            } else {
              acc[metricKey] = sumColumns([acc[metricKey], metric]);
            }
          });
          return acc;
        },
        {} as ISingleEmailStats
      );
      const periods = metrics.periods;

      const result = { metrics: summedMetrics, periods, clicks };
      // save metrics in cache
      runInAction(() => {
        this.metricsCache[
          JSON.stringify({
            metricsPeriod,
            startDate,
            endDate,
            flowIds,
            emailIds,
            broadcastId,
          })
        ] = result;
      });
      return result;
    } catch (e) {
      toastError({
        message: "Error while getting email stats.",
        extra: e,
      });
    } finally {
      this.retrivingMetricFor = undefined;
      this.rootStore.uiStore.emailMetrics.stopLoading();
    }
    return;
  }

  @action
  async getBroadcastABTestMetrics({
    metricsPeriod,
    startDate,
    endDate,
    flowIds,
    emailIds,
    broadcastId,
  }: {
    metricsPeriod: IStatsPeriod;
    startDate?: Date;
    endDate?: Date;
    flowIds?: IIntegration["id"][];
    emailIds?: IEmailContent["id"][];
    broadcastId?: Broadcast["id"];
  }) {
    this.rootStore.uiStore.emailMetrics.startLoading();
    try {
      const { stats: metrics } = await getEmailsMetrics({
        period: metricsPeriod,
        startDate,
        endDate,
        flowIds,
        emailIds,
        broadcastId,
        abTest: true,
        timezone: getUserTimezone(),
      });

      return metrics;
    } catch (e) {
      toastError({
        message: "Error while getting email stats.",
        extra: e,
      });
    } finally {
      this.rootStore.uiStore.emailMetrics.stopLoading();
    }
    return;
  }

  /**
   * Check if email is used in flows
   */
  async isEmailUsedInFlows(
    emailId: IEmailContent["id"],
    checkDeactivatedFlows?: boolean
  ) {
    let isUsed = false;
    const flowsThatUseEmail: Dictionary<IFlow> = {};
    // TODO - Flow paging: move this to backend
    // Go through each step in each flow and check if the email is used in it
    const flows = this.rootStore.flowsStore.flows;
    // trigger getting flows
    flows.current();
    // make sure flows are loaded
    await when(() => !flows.isLoading());
    // if there was a toast message, close it
    _.each(flows.current(), (flow) => {
      if (!checkDeactivatedFlows && flow.status !== integrationStateActive) {
        return;
      }
      for (const stepKey in flow.steps) {
        const step = flow.steps[stepKey];
        // We are only checking steps that are email related
        if (
          step.operationKey === "/send" ||
          step.operationKey === "/email-activity" ||
          step.operationKey === "/email-trigger"
        ) {
          // find the email used in this step
          const stepFieldsValues = StepBase.getInputFieldsValues(step);
          // Is the email id in used in the step same as current email id
          if (
            [
              String(stepFieldsValues?.emailId),
              String(stepFieldsValues?.email?.id),
            ].includes(String(emailId))
          ) {
            isUsed = true;
            flowsThatUseEmail[flow.id] = flow;
            // no need to iterate over other steps in this flow
            break;
          }
        }
      }
    });

    return { isUsed, flowsThatUseEmail };
  }
}
