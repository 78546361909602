import ChangePlan from "../Billing/ChangePlan";
import { useStores } from "../../store/useStore";
import { UncontrolledTooltip } from "reactstrap";
import { observer } from "mobx-react";

/**
 * Upgrade functionality for nav bar
 */
const AppNavUpgrade = () => {
  const { accountStore } = useStores();
  const currentPrice = accountStore.account?.payment?.plan?.price;
  if (currentPrice && currentPrice > 0) {
    return null;
  }
  const button = (
    <div className="mr-3 cursor-pointer" id="upgrade-button">
      <UncontrolledTooltip target="upgrade-button">
        Upgrade to Premium
      </UncontrolledTooltip>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="24px"
        height="24px"
        viewBox="0 0 48 48"
      >
        <g transform="translate(0, 0)">
          <path
            d="M46,18H30.19L24.952,1.694a1,1,0,0,0-1.9,0L17.81,18H2a1,1,0,0,0-.612,1.791L14,29.554,8.752,45.691a1,1,0,0,0,1.539,1.118L24,36.85l13.709,9.959a1,1,0,0,0,1.539-1.118L34,29.554l12.608-9.763A1,1,0,0,0,46,18Z"
            fill="#fdb53c"
          ></path>
        </g>
      </svg>
    </div>
  );

  return <ChangePlan button={button} />;
};

export default observer(AppNavUpgrade);
