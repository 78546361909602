import Loadable from "react-loadable";

// eslint-disable-next-line import/no-cycle
import DefaultLayout from "./containers/DefaultLayout/DefaultLayout";
import { Loading } from "./components/Loading";
import { Alert } from "reactstrap";
// import PersonRoute from "./components/Person/PersonRoute";

// Show loading.
// Also, do a full reload if there are missing chunks (new version was uploaded)
function loadingOrReload(props: Loadable.LoadingComponentProps) {
  if (props.error) {
    if (props.error.request && props.error.request.match(/chunk.js$/)) {
      // chunk is missing, so probably new version was uploaded
      // refreshing should fix this
      window.location.reload();
      return null;
    }
    return (
      <Alert color="danger">
        There was an error while loading part of the app. Please refresh and try
        again.
        <br /> Original error: {props.error.message}
      </Alert>
    );
  }
  // Show loading only if needed
  // https://github.com/jamiebuilds/react-loadable#avoiding-flash-of-loading-component
  if (props.pastDelay) {
    return <Loading />;
  }
  return null;
}

const Dashboard = Loadable({
  loader: () => import("./components/Dashboard/Dashboard"),
  loading: loadingOrReload,
});

const Onboarding = Loadable({
  loader: () => import("./components/Onboarding/OnboardingRoute"),
  loading: loadingOrReload,
});

const Flows = Loadable({
  loader: () => import("./components/Flows/FlowsRoute"),
  loading: loadingOrReload,
});

const Flow = Loadable({
  loader: () => import("./components/Flows/FlowRoute"),
  loading: loadingOrReload,
});

const Segments = Loadable({
  loader: () => import("./components/Segments/Segments"),
  loading: loadingOrReload,
});

const PersonRoute = Loadable({
  loader: () => import("./components/Person/PersonRoute"),
  loading: loadingOrReload,
});

const NewPeopleImportRoute = Loadable({
  loader: () => import("./components/ImportPeople/NewPeopleImportRoute"),
  loading: loadingOrReload,
});

const PeopleImports = Loadable({
  loader: () =>
    import("./components/ImportPeople/PeopleImports/PeopleImportsWithSidebar"),
  loading: loadingOrReload,
});

const SinglePeopleImportRoute = Loadable({
  loader: () =>
    import(
      "./components/ImportPeople/SinglePeopleImport/SinglePeopleImportRoute"
    ),
  loading: loadingOrReload,
});

const Emails = Loadable({
  loader: () => import("./components/EmailContent/Emails"),
  loading: loadingOrReload,
});
const Metrics = Loadable({
  loader: () => import("./components/Metrics/Metrics"),
  loading: loadingOrReload,
});

const Apps = Loadable({
  loader: () =>
    import("./components/AuthenticateApps/AuthenticateAppsWithSidebar"),
  loading: loadingOrReload,
});

const Login = Loadable({
  loader: () => import("./views/Pages/Login/Login"),
  loading: loadingOrReload,
});

const RequestResetPassword = Loadable({
  loader: () => import("./views/Pages/ResetPassword/RequestResetPassword"),
  loading: loadingOrReload,
});
const ResetPassword = Loadable({
  loader: () => import("./views/Pages/ResetPassword/ResetPassword"),
  loading: loadingOrReload,
});

const AccountInfo = Loadable({
  loader: () => import("./components/AccountInfo/AccountInfoWithSidebar"),
  loading: loadingOrReload,
});
const Profile = Loadable({
  loader: () => import("./components/Profile/ProfileWithSidebar"),
  loading: loadingOrReload,
});

const Register = Loadable({
  loader: () => import("./views/Pages/Register/Register"),
  loading: loadingOrReload,
});

const Registration = Loadable({
  loader: () => import("./components/Registration/Registration"),
  loading: loadingOrReload,
});

const Error = Loadable({
  loader: () => import("./components/Error/Error"),
  loading: loadingOrReload,
});

const Billing = Loadable({
  loader: () => import("./components/Billing/BillingWithSidebar"),
  loading: loadingOrReload,
});

const EmailSettingsAddresses = Loadable({
  loader: () =>
    import(
      "./components/EmailSettings/Addresses/EmailSettingsAddressesWithSidebar"
    ),
  loading: loadingOrReload,
});
const EmailSettingsMisc = Loadable({
  loader: () =>
    import("./components/EmailSettings/EmailSettingsMiscWithSidebar"),
  loading: loadingOrReload,
});
const EmailSettingsFonts = Loadable({
  loader: () =>
    import(
      "./components/EmailSettings/EmailFonts/EmailSettingsFontsWithSidebar"
    ),
  loading: loadingOrReload,
});
const CommunicationCategories = Loadable({
  loader: () =>
    import(
      "./components/EmailSettings/CommunicationCategories/CommunicationCategoriesWithSidebar"
    ),
  loading: loadingOrReload,
});
const CommunicationPreferences = Loadable({
  loader: () =>
    import("./components/CommunicationPreferences/CommunicationPreferences"),
  loading: loadingOrReload,
});
const EmailSettingsDomains = Loadable({
  loader: () =>
    import(
      "./components/EmailSettings/EmailDomains/EmailSettingsDomainsWithSidebar"
    ),
  loading: loadingOrReload,
});
const EmailDomain = Loadable({
  loader: () =>
    import(
      "./components/EmailSettings/EmailDomains/EmailSingleDomainWithSidebar"
    ),
  loading: loadingOrReload,
});
const SiteTrackingSetup = Loadable({
  loader: () => import("./components/SiteTracking/SiteTrackingWithSidebar"),
  loading: loadingOrReload,
});
const FormTracking = Loadable({
  loader: () =>
    import("./components/SiteTracking/FormTracking/FormTrackingWithSidebar"),
  loading: loadingOrReload,
});
const FormTrackingSingle = Loadable({
  loader: () =>
    import("./components/SiteTracking/FormTracking/FormTrackingSingle"),
  loading: loadingOrReload,
});

const PersonFields = Loadable({
  loader: () => import("./components/PersonFields/PersonFieldsRoute"),
  loading: loadingOrReload,
});

const TagsManagement = Loadable({
  loader: () => import("./components/TagsManagement/TagsManagementRoute"),
  loading: loadingOrReload,
});

const Broadcasts = Loadable({
  loader: () => import("./components/Broadcasts/BroadcastsRoute"),
  loading: loadingOrReload,
});

const Team = Loadable({
  loader: () => import("./components/Team/TeamWithSidebar"),
  loading: loadingOrReload,
});
const Accounts = Loadable({
  loader: () => import("./components/Accounts/AccountsWithSidebar"),
  loading: loadingOrReload,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: "/", exact: true, name: "Home", component: DefaultLayout },
  { path: "/login", exact: true, name: "Login", component: Login },
  {
    path: ["/register", "/register/:stepId"],
    exact: true,
    name: "Registration",
    component: Registration,
  },
  {
    path: "/public/register/appsumo",
    exact: true,
    name: "Register",
    component: Register,
  },
  {
    path: "/request-reset-password",
    exact: true,
    name: "Request Password Reset",
    component: RequestResetPassword,
  },
  {
    path: "/reset-password",
    exact: true,
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/public/subscription-preferences",
    exact: true,
    name: "Communication Preferences",
    component: CommunicationPreferences,
  },
  { path: "/error", exact: true, name: "Error", component: Error },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  {
    path: ["/start/:stageName", "/start"],
    name: "Quick Start",
    component: Onboarding,
  },
  {
    path: ["/segments", "/segments/:id", "/people"],
    exact: true,
    name: "Segments",
    component: Segments,
  },
  { path: "/flows", exact: true, name: "Flows", component: Flows },
  { path: "/flows/:id", exact: true, name: "Flows", component: Flow },
  {
    path: ["/people/imports/new", "/people/imports/new/:stageName"],
    exact: true,
    name: "Import people",
    component: NewPeopleImportRoute,
  },
  {
    path: ["/people/imports"],
    exact: true,
    name: "Import people",
    component: PeopleImports,
  },
  {
    path: ["/people/imports/:id"],
    exact: true,
    name: "Import people",
    component: SinglePeopleImportRoute,
  },
  // Single person view, must be below /people/import
  { path: "/people/:id", exact: true, name: "Person", component: PersonRoute },

  { path: "/emails", exact: true, name: "Emails", component: Emails },
  { path: "/emails/:id", exact: true, name: "Emails", component: Emails },

  {
    path: ["/broadcasts", "/broadcasts/:id"],
    exact: true,
    name: "Broadcasts",
    component: Broadcasts,
  },

  { path: "/metrics", exact: true, name: "Metrics", component: Metrics },
  {
    path: ["/account/info", "/account-info", "/settings/account"],
    exact: true,
    name: "Your Account",
    component: AccountInfo,
  },
  {
    path: ["/profile", "/settings/profile"],
    exact: true,
    name: "Your Profile",
    component: Profile,
  },
  {
    path: ["/apps", "/settings/apps"],
    exact: true,
    name: "Apps",
    component: Apps,
  },
  {
    path: ["/billing", "/settings/billing"],
    name: "Billing",
    component: Billing,
  },
  {
    path: "/settings/site-tracking/forms",
    name: "Form Tracking",
    component: FormTracking,
    exact: true,
  },
  {
    path: "/settings/site-tracking/forms/edit",
    name: "Form Tracking",
    component: FormTrackingSingle,
    exact: true,
  },
  {
    path: "/settings/site-tracking",
    name: "Site Tracking",
    component: SiteTrackingSetup,
    exact: true,
  },
  {
    path: ["/settings/email", "/settings/email/addresses"],
    exact: true,
    name: "Sender Addresses",
    component: EmailSettingsAddresses,
  },
  {
    path: "/settings/email/domains",
    exact: true,
    name: "Email Domains",
    component: EmailSettingsDomains,
  },
  {
    path: "/settings/communication-categories",
    exact: true,
    name: "Communication Categories",
    component: CommunicationCategories,
  },
  {
    path: "/settings/email/fonts",
    exact: true,
    name: "Email Fonts",
    component: EmailSettingsFonts,
  },
  {
    path: "/settings/email/general",
    exact: true,
    name: "Email Settings",
    component: EmailSettingsMisc,
  },
  {
    path: "/settings/email/domains/:id",
    exact: true,
    name: "Email Domains",
    component: EmailDomain,
  },
  {
    path: "/settings/person-fields",
    exact: true,
    name: "Person Fields",
    component: PersonFields,
  },
  {
    path: "/settings/tags",
    exact: true,
    name: "Tags",
    component: TagsManagement,
  },
  {
    path: "/settings/team",
    exact: true,
    name: "Team",
    component: Team,
  },
  {
    path: "/settings/accounts",
    exact: true,
    name: "Manage Accounts",
    component: Accounts,
  },
];

export default routes;
