import classNames from "classnames";
import { useMemo } from "react";
interface Props {
  stepName: string;
  onClose: () => void;
  stepOperationKey?: string;
  service?: string;
  stepIcon?: string;
  subtitle?: string;
}

const ConfigureStepHeader = (props: Props) => {
  const containerClassNames = useMemo(
    () =>
      classNames(
        "step-nav-tab-pane-header-icn-container mr-3 mt-2",
        props.stepOperationKey &&
          `step-nav-tab-pane-header-icn-${props.stepOperationKey}`,
        props.service && `step-nav-tab-pane-header-icn-${props.service}`
      ),
    [props.service, props.stepOperationKey]
  );
  return (
    <div className="step-nav-tab-pane-header py-4 px-2 px-md-3 px-lg-5">
      <button
        type="button"
        className="btn btn-no-focus position-absolute btn-step-nav-tab-pane-close"
        aria-label="Close"
        onClick={props.onClose}
      >
        <span aria-hidden="true">&times;</span>
      </button>
      <div className={containerClassNames}>
        {props.stepIcon && (
          <img
            className="step-nav-tab-pane-header-icn"
            src={props.stepIcon}
            alt="Step Icon"
          />
        )}
      </div>
      <div className="step-nav-tab-pane-header-content">
        <div className="step-nav-tab-pane-header-content-headline">
          {props.stepName}
        </div>
        <div className="step-nav-tab-pane-header-content-subheadline">
          {props.subtitle}
        </div>
      </div>
    </div>
  );
};

export default ConfigureStepHeader;
