import { observable, runInAction, computed, action, when } from "mobx";
import _ from "lodash";
import { DomainStore } from "./domainStore";
import { peoplePageSize } from "../constants";

import { lazyObservable, ILazyObservable } from "../domain/helpers/lazyLoad";
import {
  getSegments,
  getSegmentPreview,
  getSegmentPeople,
  updateSegment,
  createSegment,
  deleteSegment,
} from "./persistence/persistSegments";
import {
  toastError,
  toastInfo,
  toastSuccess,
} from "../domain/errorHandling/toaster";

import uuidv1 from "uuid/v1";
import pLimit from "p-limit";
import { getPeopleMetrics } from "./persistence/persistMetrics";
import { integrationStateActive } from "encharge-domain/lib/helpers/constants";
import { IFlow } from "../components/Flows/FlowRoute";
import {
  ISegmentV2,
  ISegment,
  SegmentCondition,
} from "encharge-domain/lib/definitions/ambient/segment";
import redirect from "domain/helpers/redirect";
import { segmentColors } from "domain/helpers/enchargeColors";
import { StepBase } from "components/FlowEditor/Step/StepBase";
import { archivedPeopleSegmentId } from "encharge-domain/lib/helpers/segment";
import { getSegmentsForReadOnlyFlow } from "./persistence/persistFlow";
import { getFolderTypeForQuery } from "store/foldersStore";

export interface ISegmentPreview extends ISegmentV2 {
  people: IEndUser[];
  totalCount: number;
}

export type ISegmentLazyLoad = ISegmentV2 & {
  people: {
    page: number;
    peopleIds: string[];
    hasMore: boolean;
    sort?: string;
    order?: "asc" | "desc";
    lastRequestId?: string;
    error?: string;
  };
  preview: ILazyObservable<ISegmentPreview>;
};

export class SegmentStore {
  rootStore: DomainStore;
  canEditSegmentStore: CanEditSegmentStore = new CanEditSegmentStore(this);
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
  }
  // A way to limit creates/updates to one request at a time.
  segmentCreateUpdateQueue = pLimit(1);

  @observable
  currentSegmentId?: ISegment["id"];

  @observable
  // Lazy observable with nested lazy observable
  segments = lazyObservable<ISegmentLazyLoad[]>((sink, onError) => {
    let getSegmentsFunc = () => getSegments();

    // If we are in readonly flow, get the segments for this flow only
    const readOnlyAuth = this.rootStore.permissionsStore.readOnlyAuthToken;
    const flowId = this.rootStore.flowsStore.currentFlowId;
    if (readOnlyAuth && flowId) {
      getSegmentsFunc = () => getSegmentsForReadOnlyFlow(flowId, readOnlyAuth);
    }

    getSegmentsFunc()
      .then((segments) => {
        // if we haven't set the current segment id, make the first one current
        if (!this.currentSegmentId && segments?.[0]?.id) {
          runInAction(() => (this.currentSegmentId = segments[0].id));
        }
        // add colors to auto generated segmetns
        const segmentsWithColors = _.map(segments, (segment) => {
          if (!segment.autoSegment) return segment;
          const segmentColor = segmentColors[segment.name];
          if (segmentColor) {
            segment.color = segmentColor;
          }
          return segment;
        });

        sink(observable(attachSegmentsExtraProperties(segmentsWithColors)));
      })
      .catch((e) => {
        toastError({
          message: "Error while loading your segments.",
          extra: e,
        });
        onError(e);
      });
  });

  @action
  changeCurrentSegment(id: ISegment["id"]) {
    const prevSegment = this.currentSegmentId;
    if (prevSegment !== id) {
      this.currentSegmentId = id;
      // close segment conditions
      this.rootStore.uiStore.editSegment.setConditionsOpen(false);

      // reset the currently selected people as it's based on segment
      this.rootStore.peopleActionStore.setSelectedPeople([]);

      // cancel any searches going on
      this.rootStore.uiStore.peopleSearch.close();

      // set the current segment as the selected folder
      this.rootStore.foldersStore.setSelectedFolder(id, "segments");
    }
  }

  @computed
  get currentSegment() {
    if (!this.currentSegmentId) return undefined;
    if (this.currentSegmentId === archivedPeopleSegmentId) {
      // get archived people segment
      return this.archivedPeopleSegment;
    }
    return this.getSegmentById(this.currentSegmentId);
  }

  private _archivedPeopleSegment?: ISegmentLazyLoad;

  private get archivedPeopleSegment() {
    // if exists return it
    if (this._archivedPeopleSegment) return this._archivedPeopleSegment;

    // Copy the all people segment, to create the archived people segment
    const allPeopleSegment = this.getAllPeopleSegment();
    if (!allPeopleSegment) return undefined;
    const [archived] = attachSegmentsExtraProperties([
      _.cloneDeep(allPeopleSegment),
    ]);
    archived.id = archivedPeopleSegmentId;
    archived.name = "Archived";
    archived.color = "#c4c4c3";
    this._archivedPeopleSegment = observable(archived);
    return this._archivedPeopleSegment;
  }

  getSegmentById(segmentId: ISegment["id"]) {
    if (segmentId === archivedPeopleSegmentId)
      return this.archivedPeopleSegment;
    return _.find(
      this.segments.current(),
      (segment) => segment.id === segmentId
    );
  }

  @action
  clearSegmentsPeopleCache() {
    attachSegmentsExtraProperties(this.segments.current());
  }

  @action
  async getSegmentPeople(segmentId: ISegment["id"]) {
    await when(() => this.segments.current() !== undefined);
    const segment = this.getSegmentById(segmentId);
    if (!segment) {
      return;
    }
    try {
      if (segment.people.error) {
        runInAction(() => (segment.people.error = undefined));
      }
      const limit = peoplePageSize;
      const offset = segment.people.page * peoplePageSize;
      const sort = segment.people.sort;
      const order = segment.people.order;
      const hideAnonymous = this.rootStore.accountStore.account?.flags
        ?.hideAnonymous;
      console.log("getting segment page", segment.people.page);
      // We should only take into account the last request for retrieving people
      // in segment. As the user can send off multiple requests (by clicking
      // to sort for example), we want to ignore previous ones
      const thisRequestId = uuidv1();
      runInAction(() => {
        segment.people.lastRequestId = thisRequestId;
      });
      const people = await getSegmentPeople(segmentId, {
        limit,
        offset,
        sort,
        order,
        ignoreAnonymous: hideAnonymous,
      });
      // Store the people
      this.rootStore.peopleStore.putPeople(people);
      // If there was a new request fired by the time we process this one,
      // ignore the results of this one
      if (segment.people.lastRequestId !== thisRequestId) {
        return;
      }
      runInAction("updating segment people action", () => {
        // If there are more pages of people to retrieve
        const hasMore = people && people.length > 0;
        segment.people.page += 1;
        const peopleIds = _.map(people, (person) => person["id"]!);
        segment.people!.peopleIds.push(...peopleIds);
        segment.people.hasMore = hasMore;
      });
    } catch (e) {
      if (
        e.message?.includes?.("query timed out") ||
        e.message?.includes?.("statement timeout")
      ) {
        e.message =
          "Segment is still building 🏗. Please try again in a few minutes.";
        toastInfo(e.message);
      } else {
        // toaster error
        toastError({
          message: "Error while getting people in segment.",
          extra: e,
        });
      }
      runInAction(() => (segment.people.error = e.message));
    }
  }

  @action
  // Clear the current page and people,
  // for example after sorting, etc
  resetSegment(segmentId: ISegment["id"]) {
    const segment = this.getSegmentById(segmentId);
    if (segment) {
      segment.people = defaultSegmentPeopleProp;
      segment.preview.refresh();
    }
  }

  @action
  async setSegmentSort(
    segmentId: ISegment["id"],
    sortField: string,
    sortOrder: "asc" | "desc"
  ) {
    try {
      const segment = this.getSegmentById(segmentId);
      if (!segment) {
        throw new Error("Unexisting segment");
      }
      // Get the field name as it is in the database (e.g. data.field)
      const sortFieldDBName = await this.rootStore.personFieldsStore.getFieldDBName(
        sortField
      );

      // make sure there are changes
      if (
        sortFieldDBName === segment.people.sort &&
        sortOrder === segment.people.order
      ) {
        return;
      }
      runInAction(() => {
        // We need to clear the current page and people,
        // because after the sort they will be in different order.
        this.resetSegment(segment.id);

        segment.people.sort = sortFieldDBName;
        segment.people.order = sortOrder;
      });
    } catch (e) {
      // toaster error
      toastError({
        message: "Error while sorting segment.",
        extra: e,
      });
    }
  }

  @action
  async segmentChanged({
    segment,
    skipResetSegment,
    skipSuccessMessage,
  }: {
    segment: ISegmentV2;
    skipResetSegment?: boolean;
    skipSuccessMessage?: boolean;
  }) {
    const existingSegmentIndex = _.findIndex(
      this.segments.current(),
      (segm) => segm.id === segment.id
    );
    const segments = this.segments.current();
    const newSegment = skipResetSegment
      ? (segment as any)
      : attachSegmentsExtraProperties([segment])[0];
    segments[existingSegmentIndex] = newSegment;
    console.log("updated segment", segment.id);
    await this.updateSegment(segment, skipResetSegment);
    if (!skipSuccessMessage) {
      toastSuccess("✅ Segment saved.");
    }
  }

  @action
  removeSegmentCondition(conditionIndex: number) {
    if (this.currentSegment?.conditions?.conditions?.[conditionIndex]) {
      this.currentSegment!.conditions!.conditions.splice(conditionIndex, 1);

      this.updateSegment(this.currentSegment!);
    }
  }

  @action
  segmentAddCondition(condition: SegmentCondition) {
    if (!this.currentSegment) return;

    // Check if this condition already exists
    if (
      _.find(this.currentSegment.conditions?.conditions, (currentCondition) =>
        _.isEqual(condition, currentCondition)
      )
    ) {
      toastError("This condition already exists.");
      return;
    }
    this.currentSegment.conditions?.conditions.push(condition);

    this.updateSegment(this.currentSegment!);
  }

  @action
  async createSegment({
    name,
    color,
    skipRedirect,
  }: {
    name: ISegment["name"];
    color: ISegment["color"];
    skipRedirect?: boolean;
  }) {
    try {
      if (!name) {
        throw new Error("Segment must have a name.");
      }

      const segment = await this.segmentCreateUpdateQueue(() => {
        runInAction(() => {
          this.rootStore.uiStore.segmentCreate.startLoading();
        });
        return createSegment({ name, color });
      });
      runInAction(() => {
        this.rootStore.uiStore.segmentCreate.finishLoading();
        this.segments
          .current()
          .push(...attachSegmentsExtraProperties([segment]));
        this.rootStore.foldersStore.addItemToRoot({
          type: "segments",
          itemId: segment.id,
        });
        if (!skipRedirect) {
          this.redirectToSegment(segment.id);
        }
      });
      return segment;
    } catch (e) {
      // Disable loading
      runInAction(() => {
        this.rootStore.uiStore.segmentCreate.finishLoading();
      });
      // toaster error
      toastError({
        message: "Error while creating your segment.",
        extra: e,
      });
      return undefined;
    }
  }

  redirectToSegment(segmentId: ISegmentV2["id"]) {
    redirect(`/segments/${segmentId}`);
  }

  @action
  async updateSegment(segment: ISegmentV2, skipResetSegment?: boolean) {
    try {
      if (!segment.id) {
        throw new Error("Can't update segment without ID.");
      }
      if (!segment.name) {
        throw new Error("Segment must have a name.");
      }
      const updateableSegment = _.omit(segment, [
        "people",
        "preview",
      ]) as ISegmentV2;

      const updatedSegment = await this.segmentCreateUpdateQueue(() => {
        // Wait a bit before showing the loader, because other queued
        // update requests might hide it
        setTimeout(
          () =>
            runInAction(() => {
              this.rootStore.uiStore.segmentUpdate.startUpdate();
            }),
          50
        );

        return updateSegment(updateableSegment);
      });
      runInAction(() => {
        !skipResetSegment && this.resetSegment(updatedSegment.id);
      });
      return updatedSegment;
    } catch (e) {
      // toaster error
      toastError({
        message: "Error while saving your segment.",
        extra: e,
      });
      return undefined;
    } finally {
      this.rootStore.uiStore.segmentUpdate.finishUpdate();
    }
  }

  @action
  async deleteSegment(
    segmentId: ISegment["id"],
    showToastMessage: boolean = true
  ) {
    try {
      if (!segmentId) {
        throw new Error("Can't delete segment without ID.");
      }
      this.rootStore.uiStore.segmentDelete.start(segmentId);
      runInAction(() => {
        // remove segment from store
        const segments = this.segments.current();
        const segmentIndex = _.findIndex(
          segments,
          (segment) => segment.id === segmentId
        );
        if (segmentIndex !== -1) {
          // remove segment inplace
          segments.splice(segmentIndex, 1);
          showToastMessage && toastSuccess("✅ Segment deleted.");

          // If this segment was selected,
          // select the default segment (the first one)
          if (
            this.currentSegmentId === segmentId &&
            this.segments.current().length > 0
          ) {
            this.openDefaultSegment();
          }
        }
      });

      this.segmentCreateUpdateQueue(() => {
        // Wait a for create/update segment to finish before deleting it
        return deleteSegment(segmentId);
      });
    } catch (e) {
      // toaster error
      showToastMessage &&
        toastError({
          message: "Error while deleting your segment.",
          extra: e,
        });
    } finally {
      this.rootStore.uiStore.segmentDelete.finish(segmentId);
    }
  }
  openDefaultSegment() {
    const allPeopleSegment = this.getAllPeopleSegment();
    if (allPeopleSegment?.id) {
      this.rootStore.foldersStore.setSelectedFolder(
        allPeopleSegment.id,
        "segments"
      );
      redirect(
        `/segments/${allPeopleSegment.id}?${getFolderTypeForQuery(
          "segments"
        )}=${allPeopleSegment.id}`
      );
    }
  }

  getAllPeopleSegment() {
    return _.find(this.segments.current(), (current) =>
      isAllPeopleSegment(current)
    ) as ISegmentLazyLoad | undefined;
  }

  @observable
  isCurrentSegmentArchivedPeople() {
    const currentSegment = this.currentSegment;
    if (!currentSegment) return false;
    return isArchivedPeopleSegment(currentSegment);
  }

  @observable
  peopleMetrics?: {
    [key in IStatsPeriod]: {
      periods: string[];
      count: number[];
    };
  };

  @action
  async getPeopleMetrics(period: IStatsPeriod) {
    const metrics = await getPeopleMetrics(period);
    if (metrics) {
      runInAction(() => {
        if (!this.peopleMetrics) {
          this.peopleMetrics = {} as any;
        }
        this.peopleMetrics![period] = metrics;
      });
    }
  }
}

const defaultSegmentPeopleProp = {
  page: 0,
  peopleIds: [],
  hasMore: true,
  sort: "createdAt",
  order: "desc" as "desc",
};

// Helper to attach lazy loading preview property to each segment
const attachSegmentsExtraProperties = (segments: ISegmentV2[]) => {
  return _.map<ISegmentV2, ISegmentLazyLoad>(segments, (segment) => {
    (segment as ISegmentLazyLoad).preview = prepareLazyPreview(segment);
    (segment as ISegmentLazyLoad).people = defaultSegmentPeopleProp;
    return segment as ISegmentLazyLoad;
  });
};

const prepareLazyPreview = (segment: ISegmentV2) => {
  return lazyObservable<ISegmentPreview>(async (sink, onError) => {
    try {
      if (segment.id === archivedPeopleSegmentId) return;
      sink(await getSegmentPreview(segment.id));
    } catch (e) {
      onError(e);
      // if (!e.message?.includes?.("query timed out") &&
      // !e.message?.includes?.("statement timeout")) {
      //   toastError({
      //     message: "Error while loading preview for segment.",
      //     extra: e,
      //   });
      // }
    }
  });
};

/**
 * Check if we can edit the current segment.
 * Asks the user (once) if the segment is active.
 *
 * @class CanEditSegmentStore
 */
class CanEditSegmentStore {
  segmentStore: SegmentStore;
  constructor(segmentStore: SegmentStore) {
    this.segmentStore = segmentStore;
  }

  get uiStore() {
    return this.segmentStore.rootStore.uiStore;
  }

  @observable
  userConfirmedEditActiveSegment: {
    [segmentId in ISegment["id"]]: boolean;
  } = {};

  @observable
  userCanceledEditActiveSegment: boolean = false;

  confirmModifySegmentId: ISegment["id"] | undefined;

  async canModifySegment(
    segmentId?: ISegment["id"],
    checkDeactivatedFlows?: boolean
  ): Promise<boolean> {
    const segment = segmentId
      ? this.segmentStore.getSegmentById(segmentId)
      : this.segmentStore.currentSegment;
    if (!segment) {
      return false;
    }
    if (!isEditableSegment(segment)) {
      return false;
    }
    // user has already confirmed editing for this segment
    if (this.userConfirmedEditActiveSegment[segment.id]) {
      return true;
    }
    const { isUsed, flowsThatUseSegment } = await this.isSegmentUsedInFlows(
      segment.id,
      checkDeactivatedFlows
    );
    if (!isUsed) {
      return true;
    }
    // Display the modal
    this.uiStore.activeSegmentWarning.showConfirmModal(flowsThatUseSegment);
    this.confirmModifySegmentId = segment.id;
    // If the user confirms, return true (to continue action)
    // If the user closes the popup, return false to cancel the action
    return new Promise((resolve) => {
      // We can edit, so return true
      when(
        () => this.userConfirmedEditActiveSegment[segment.id],
        () => resolve(true)
      );
      // Cancel editing
      when(
        () => this.userCanceledEditActiveSegment,
        () => {
          // reset the flag
          this.userCanceledEditActiveSegment = false;
          // return false to cancel the action waiting on this
          resolve(false);
        }
      );
    });
  }

  async isSegmentUsedInFlows(
    segmentId: ISegment["id"],
    checkDeactivatedFlows?: boolean
  ) {
    let isUsed = false;
    const flowsThatUseSegment: Dictionary<IFlow> = {};
    // Go through each step in each flow and check if the segment is used in it
    const flows = this.segmentStore.rootStore.flowsStore.flows;
    // trigger getting flows
    flows.current();
    // make sure flows are loaded
    await when(() => !flows.isLoading());
    // if there was a toast message, close it
    _.each(flows.current(), (flow) => {
      if (!checkDeactivatedFlows && flow.status !== integrationStateActive) {
        return;
      }
      for (const stepKey in flow.steps) {
        const step = flow.steps[stepKey];
        // We are only checking steps that are "entered/left segment"
        if (
          step.operationKey === "/entered-segment" ||
          step.operationKey === "/left-segment"
        ) {
          // find the segment used in this step
          const stepFieldsValues = StepBase.getInputFieldsValues(step);
          // Is the segment id in used in the step same as current segment id
          if (String(stepFieldsValues.segment) === String(segmentId)) {
            isUsed = true;
            flowsThatUseSegment[flow.id] = flow;
            // no need to iterate over other steps in this flow
            break;
          }
        }
      }
    });

    return { isUsed, flowsThatUseSegment };
  }

  @action
  continueWithEditActiveSegment() {
    if (!this.confirmModifySegmentId)
      return toastError("No segment ID to confirm changes on.");
    this.userConfirmedEditActiveSegment[this.confirmModifySegmentId] = true;
    this.confirmModifySegmentId = undefined;
    this.uiStore.activeSegmentWarning.closeConfirmModal();
    return;
  }
  @action
  cancelEditActiveSegment() {
    this.userCanceledEditActiveSegment = true;
    this.confirmModifySegmentId = undefined;
    this.uiStore.activeSegmentWarning.closeConfirmModal();
  }
}

export const isEditableSegment = (segment: ISegment) => {
  // can edit segment if All People segment and vice versa
  return !isAllPeopleSegment(segment) && !isArchivedPeopleSegment(segment);
};

export const isAllPeopleSegment = (segment: ISegment) => {
  return segment.autoSegment && segment.name === "All People";
};

export const isArchivedPeopleSegment = (segment: ISegment) => {
  return segment.autoSegment && segment.name === "Archived";
};

export const isUnsubscribedPeopleSegment = (segment: ISegment) => {
  return segment.autoSegment && segment.name === "Unsubscribed";
};
