import ButtonLoader from "components/ButtonLoader";
import {
  formatAsReactSelectOptions,
  reactSelectCommonProps,
} from "components/ReactSelect/reactSelectCommon";
import _ from "lodash";
import { observer } from "mobx-react";
import { useState } from "react";
import Select from "react-select";
import { Form, FormGroup, Input } from "reactstrap";
import { Label } from "reactstrap";
import { useStores } from "store/useStore";
import {
  AIContentSuggestionGenerateParameters,
  AIWriterUseCase,
} from "encharge-domain/definitions/AIContentSuggestion";

interface Props {
  selectedUseCase: AIWriterUseCase;
  setSelectedUseCase: (value: AIWriterUseCase) => void;
}

const aiWriterUseCases: {
  id: AIWriterUseCase;
  name: string;
  description: string;
}[] = [
  {
    id: "subjectLine",
    name: "Subject Lines",
    description: "Create engaging click-worthy subject line.",
  },
  {
    id: "writeEmail",
    name: "Write Email",
    description: "Write an email based on a few key points.",
  },
  {
    id: "emailOutline",
    name: "Email Outline",
    description: "Generate general outline for an email.",
  },
];

const AIWriterGenerateForm = (props: Props) => {
  const { aiWriterStore, uiStore, accountStore } = useStores();
  const {
    selectedUseCase: selectedGenerateOption,
    setSelectedUseCase: setSelectedGenerateOption,
  } = props;
  const { currentOption, options } = formatAsReactSelectOptions({
    items: aiWriterUseCases,
    currentValue: selectedGenerateOption,
    idField: "id",
    labelField: "name",
  });

  const currentOptionObject = _.find(aiWriterUseCases, {
    id: selectedGenerateOption,
  });

  const [emailAbout, setEmailAbout] = useState("");
  const [emailAboutSubject, setEmailAboutSubject] = useState("");
  const [emailAboutOutline, setEmailAboutOutline] = useState("");

  const disabled =
    (selectedGenerateOption === "writeEmail" && !emailAbout) ||
    (selectedGenerateOption === "subjectLine" && !emailAboutSubject) ||
    (selectedGenerateOption === "emailOutline" && !emailAboutOutline);

  return (
    <Form>
      <FormGroup>
        <Label for="useCaseSelect" className="mb-2">
          Use case:
        </Label>
        <Select
          className="ai-writer-select"
          inputId="useCaseSelect"
          {...reactSelectCommonProps}
          options={options}
          value={currentOption}
          onChange={(option) =>
            setSelectedGenerateOption((option as any)?.value)
          }
          formatOptionLabel={(option) => {
            const useCase = _.find(aiWriterUseCases, {
              id: option.value,
            });
            return (
              <div>
                {option.label}
                <div className="small">{(useCase as any)?.description}</div>
              </div>
            );
          }}
        />
        <div className="small text-muted">
          {currentOptionObject?.description}
        </div>
      </FormGroup>
      <FormGroup>
        {selectedGenerateOption === "writeEmail" && (
          <div>
            <Label for="useCaseSelect" className="mb-2">
              What is the email about?
            </Label>
            <Input
              type="textarea"
              name="emailAbout"
              id="emailAbout"
              rows="5"
              placeholder={`Big news!\nEncharge launched new AI Writing assistant\nWrite emails 10 times faster\nAny questions`}
              value={emailAbout}
              onChange={(e) => setEmailAbout(e.target.value)}
            />
          </div>
        )}
        {selectedGenerateOption === "subjectLine" && (
          <div>
            <Label for="useCaseSelect" className="mb-2">
              What is the email about?
            </Label>
            <Input
              type="text"
              name="emailAboutSubject"
              id="emailAboutSubject"
              rows="5"
              placeholder={`50% off offer for Mother's Day`}
              value={emailAboutSubject}
              onChange={(e) => setEmailAboutSubject(e.target.value)}
            />
          </div>
        )}
        {selectedGenerateOption === "emailOutline" && (
          <div>
            <Label for="useCaseSelect" className="mb-2">
              What is the email about?
            </Label>
            <Input
              type="text"
              name="emailAboutOutline"
              id="emailAboutOutline"
              rows="5"
              placeholder={`50% off offer for Mother's Day`}
              value={emailAboutOutline}
              onChange={(e) => setEmailAboutOutline(e.target.value)}
            />
          </div>
        )}
      </FormGroup>
      <FormGroup>
        <ButtonLoader
          disabled={disabled}
          color="primary"
          onClick={() => {
            const parameters = {
              useCase: selectedGenerateOption,
              parameters: {
                companyName: accountStore.account?.accountName || "n/a",
              },
            } as AIContentSuggestionGenerateParameters;
            if (selectedGenerateOption === "writeEmail") {
              parameters.parameters.emailAbout = emailAbout;
            } else if (selectedGenerateOption === "subjectLine") {
              parameters.parameters.emailAbout = emailAboutSubject;
            } else if (selectedGenerateOption === "emailOutline") {
              parameters.parameters.emailAbout = emailAboutOutline;
            } else {
              // We shouldn't get here, but just in case
              return;
            }
            aiWriterStore.generateContentSuggestions(parameters);
          }}
          loading={uiStore.aiWriterLoading.isLoading}
        >
          Generate
        </ButtonLoader>
      </FormGroup>
    </Form>
  );
};
export default observer(AIWriterGenerateForm);
