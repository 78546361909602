import { action, observable, runInAction } from "mobx";
import { DomainStore } from "./domainStore";
import { toastError } from "../domain/errorHandling/toaster";
import { generateContentSuggestions } from "./persistence/persistAIWriter";

import {
  AIContentSuggestion,
  AIContentSuggestionGenerateParameters,
} from "encharge-domain/definitions/AIContentSuggestion";

export class AIWriterStore {
  rootStore: DomainStore;
  constructor(rootStore: DomainStore) {
    this.rootStore = rootStore;
  }

  @observable
  contentSuggestions: AIContentSuggestion[] = [
    // { id: "1", content: "Loading...", useCase: "emailOutline" },
    // { id: "2", content: "Loading...", useCase: "emailOutline" },
    // { id: "3", content: "Loading...", useCase: "emailOutline" },
  ];

  @action
  generateContentSuggestions = async (
    parameters: AIContentSuggestionGenerateParameters
  ) => {
    try {
      this.rootStore.uiStore.aiWriterLoading.startLoading();
      const suggestions = await generateContentSuggestions(parameters);
      runInAction(() => {
        this.contentSuggestions.push(...suggestions);
      });
    } catch (e) {
      toastError(e.message);
    } finally {
      this.rootStore.uiStore.aiWriterLoading.finishLoading();
    }
  };

  @action
  updateContentSuggestion(
    id: AIContentSuggestion["id"],
    content: AIContentSuggestion["content"]
  ) {
    const suggestion = this.contentSuggestions.find((s) => s.id === id);
    if (suggestion) {
      suggestion.content = content;
    }
  }
}
