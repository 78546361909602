import request, { AxiosResponse } from "axios";
import { apiBase } from "../../constants";
import {
  AIContentSuggestion,
  AIContentSuggestionGenerateParameters,
} from "encharge-domain/definitions/AIContentSuggestion";

export const generateContentSuggestions = async (
  parameters: AIContentSuggestionGenerateParameters
) => {
  try {
    const res: AxiosResponse<{
      suggestions: AIContentSuggestion[];
    }> = await request({
      url: `https://staging-api.encharge.io/v1/ai-writer`,
      method: "POST",
      data: parameters,
      withCredentials: true,
      validateStatus: (status) => status < 300,
    });
    return res.data.suggestions;
  } catch (e) {
    // If we have a specific error message from server throw it.
    const msg =
      e?.response?.data?.error?.message ||
      e.message ||
      "We couldn't retrieve suggestions. ";
    throw new Error(msg);
  }
};
